import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import GlobalStateContext from '../../../Contexts/GlobalStateContext';

export default function BasicModal({ setOpen, open }) {
  const { setIsLogin, isLogin } =
    React.useContext(GlobalStateContext);

    const navigate = useNavigate()


  const handleClose = () => {
    setOpen(false)
  };

  return (

    <Modal
      open={open}
      onClose={handleClose}
      
      className="cad-modal-blur"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Paper
        className="d-flex justify-content-center align-items-center gap-5 flex-column"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 5,

        }}>

        <Typography className="text-center position-relative" id="modal-modal-title" variant="h5" component="h2">
          Are You sure you want to logout?

          <CloseIcon
            onClick={handleClose}
            className='text-primary'
            style={{
              position: "absolute",
              right: "-20px",
              top: "-26px",
              cursor: "pointer"

            }}
          />
        </Typography>

        <div className="row w-100">
          <div className="col-6">
            <Button
              onClick={handleClose}
              className="button-outlined"
              variant="outlined"
            >No</Button>
          </div>

          <div className="col-6">
            <Button
              onClick={() => {
                navigate('/login')
                localStorage.removeItem("user");
              }}

              className="button-contained"
              variant="contained"
            >Yes</Button>
          </div>
        </div>
      </Paper>
    </Modal>
  );
}