import React from 'react'
import LineCharts from '../Charts/LineCharts'
import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import { Paper } from '@mui/material'
import BarGraph from '../Charts/BarGraph'
import Maps from '../Charts/Maps'
import UserGraph from '../Charts/UserGraph'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AiOutlineLeft } from 'react-icons/ai'

const TheAplication = () => {
     
    const usersData = {
        labels: ['10 mins', '20 mins', '30 mins', '40 mins',],
        datasets: [
            {
                label: 'Bar Chart Example',
                data: [20, 30, 30, 40],
                backgroundColor: [
                    '#548CFF',
                    '#4CCD8D', 
                    '#548CFF',
                    '#4CCD8D',
                ],
                borderColor: [
                    '#548CFF',
                    '#4CCD8D',
                    '#548CFF',
                    '#4CCD8D',
                ],
                borderWidth: 1,
            },
        ],
    };
    const browsingData = {
        labels: ['10 mins', '20 mins', '30 mins', '40 mins',],
        datasets: [
            {
                label: 'Bar Chart Example',
                data: [20, 40, 50, 60],
                backgroundColor: [
                    '#548CFF',
                    '#4CCD8D', 
                    '#548CFF',
                    '#4CCD8D',
                ],
                borderColor: [
                    '#548CFF',
                    '#4CCD8D',
                    '#548CFF',
                    '#4CCD8D',
                ],
                borderWidth: 1,
            },
        ],
    };
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                beginAtZero: true,
            barPercentage: 0.5, // Adjust the value to decrease the width
            categoryPercentage: 0.7,
            }
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
        },
    };


  return (
    
     <Paper elevation={0}
        style={{
            backgroundColor: "#f1f2f6"
        }}
     className=" w-100 p-3 d-flex flex-column justify-content-start report-card-one"
    >
        <Box className="border-bottom rounded-top-3 cad-shadow" sx={{ borderBottom: 0, padding: 0, backgroundColor: "#fff", width: "100%" }}>
            <div className='bg-white rounded-2 p-4'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                    <Link to='/reports' className='mb-2 d-flex align-items-center gap-1 text-decoration-none'>
                        <AiOutlineLeft className='pointer' size={18} />
                        <label className='pointer'>Users clicking <strong> "Get Started"</strong></label>
                    </Link>
                    <div className='calendar'>
                        <CalendarMonthIcon />
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-sm-8 col-12 mt-4'>
                        <LineCharts
                           title='Subscription'
                        />
                    </div>
                    <div className='col-sm-4 col-12 mt-4'>
                        <UserGraph
                           title='Users'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-4 col-12'>
                        <BarGraph
                            title='Total no of users viewing the page'
                            data={usersData}
                            options={options}
                        />
                    </div>
                    <div className='col-sm-4 col-12'>
                    <BarGraph
                            title='Total no of users viewing the page'
                            data={browsingData}
                            options={options}
                        />
                    </div>
                    <div className='col-sm-4 col-12'>
                        <Maps
                            title='Users location'
                        />
                    </div>
                </div>
            </div>
        </Box>  
    </Paper>
  )
}

export default TheAplication
