import api from "./api.service";
import ApiHandler from "./apiHandler";

let header = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const authHandler = new ApiHandler("/auth", api);
const authUpdate = new ApiHandler("/agent", api);
const gdprHandler = new ApiHandler("/organization/admin/GDPR", api);
const guidanceHandler = new ApiHandler(
  "/organization/admin/helpAndGuidence",
  api
);
const privacyHandler = new ApiHandler("/organization/admin/privacyPolicy", api);
const termsHandler = new ApiHandler(
  "/organization/admin/termsAndConditions",
  api
);
const tagHandler = new ApiHandler("/tag/admin", api);
const ticketHandler = new ApiHandler("/supportTicket/admin", api);
const faqCategoryHandler = new ApiHandler("/faq/admin/category", api);
const faqHandler = new ApiHandler("/faq/admin/", api);
const userHandler = new ApiHandler("/agent/admin", api);
const subsHandler = new ApiHandler("/billing/admin/", api);
const payHandler = new ApiHandler("/billing/admin/checkout", api);
const contactHandler = new ApiHandler("/contactUs/admin", api);
const subAdminHandler = new ApiHandler("user/admin", api);
const globalHandler = new ApiHandler("global/", api);
const packageHandler = new ApiHandler("billing/admin/package", api);
const planHandler = new ApiHandler("billing/admin/plans", api);
const landingHandler = new ApiHandler("organization/admin/", api);
const featurehandler = new ApiHandler("reqFeature/admin", api);
// const featurehandler = new ApiHandler("reqFeature/admin", api)

export const authService = {
  login: async (data) => await authHandler.post("/admin/login", data),
  ReGenrateToken: async (data) => await authHandler.post("/admin/token", data),
  authMe: async () => await api.get("/auth/me"),
  update: async (data) =>
    await api.put("/auth/admin/updateProfile", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  changePassword: async (data) =>
    await api.post("/auth/admin/changePassword", data),
  forgotPassword: async (data) =>
    await authHandler.post("/public/forgetPassword", data),
  resetPassword: async (data, id, token) =>
    await authHandler.post("/public/resetPassword/" + id + "/" + token, data),
};

export const gdprService = {
  get: async () => await gdprHandler.get(""),
  getById: async (id) => await gdprHandler.get("/" + id),
  post: async (data) => await gdprHandler.post("/", data),
  update: async (id, data) => await gdprHandler.put("/" + id, data),
  delete: async (id) => await gdprHandler.delete("/delete/" + id),
};

export const guidanceService = {
  get: async () => await guidanceHandler.get(""),
  getById: async (id) => await guidanceHandler.get("/" + id),
  post: async (data) => await guidanceHandler.post("/", data),
  update: async (id, data) => await guidanceHandler.put("/" + id, data),
  delete: async (id) => await guidanceHandler.delete("/delete/" + id),
};

export const privacyService = {
  get: async () => await privacyHandler.get(""),
  getById: async (id) => await privacyHandler.get("/" + id),
  post: async (data) => await privacyHandler.post("/", data),
  update: async (id, data) => await privacyHandler.put("/" + id, data),
  delete: async (id) => await privacyHandler.delete("/delete/" + id),
};

export const termsService = {
  get: async (params) => await termsHandler.get("", params),
  getById: async (id) => await termsHandler.get("/" + id),
  post: async (data) => await termsHandler.post("/", data),
  update: async (id, data) => await termsHandler.put("/" + id, data),
  delete: async (id) => await termsHandler.delete("/delete/" + id),
};

export const tagService = {
  get: async (params) => await tagHandler.get("", params),
  getById: async (id) => await tagHandler.get("/" + id),
  post: async (data) => await tagHandler.post("/", data),
  update: async (id, data) => await tagHandler.put("/" + id, data),
  delete: async (id) => await tagHandler.delete("/delete/" + id),
};

export const supportTicketService = {
  get: async (params) => await ticketHandler.get("", params),
  getById: async (id) => await ticketHandler.get("/" + id),
  postReply: async (id, data) =>
    // await api.post("/reply/" + id, data, header),
    await api.post("/supportTicket/admin/reply/" + id, data, {
      headers: { "Content-Type": "multipart/form-data" },
    }),
  updateReply: async (id, data) =>
    await ticketHandler.put("/reply/" + id, data, header),
  softReply: async (id) => await ticketHandler.put("/reply/delete/" + id),
  hardReply: async (id) => await ticketHandler.delete("/reply/delete/" + id),
  changeStatus: async (id, data) =>
    await ticketHandler.put("/statusAndPriority/" + id, data),
};

export const faqCategoryService = {
  get: async (params) => await faqCategoryHandler.get("", params),
  getById: async (id) => await faqCategoryHandler.get(id),
  post: async (data) => await faqCategoryHandler.post("/", data),
  update: async (id, data) => await faqCategoryHandler.put("/" + id, data),
  delete: async (id) => await faqCategoryHandler.delete("/delete/" + id),
};

export const faqService = {
  get: async (params) => await faqHandler.get("", params),
  getById: async (id) => await faqHandler.get(id),
  post: async (data) => await faqHandler.post("", data),
  update: async (id, data) => await faqHandler.put(id, data),
  delete: async (id) => await faqHandler.delete("delete/" + id),
};

export const userService = {
  get: async (params) => await userHandler.get("", params),
  getById: async (id) => await api.get("/agent/admin/" + id),
  softdelete: async (id) => await userHandler.delete(`/delete/soft/${id}`),
  harddelete: async (id) => await userHandler.patch(`/delete/hard/${id}`),
  archiveList: async (params) => await userHandler.get("/archiveList", params),
  restore: async (id) => await userHandler.get(`/restore/${id}`),
};

export const subsService = {
  get: async (params) => await subsHandler.get("package", params),
  createProduct: async (data) => await subsHandler.post("", data),
  toggle: async (id) => await subsHandler.post(`/toggleStatus/product/${id}`),
};

export const paymentService = {
  get: async (params) => await api.get("/billing/admin/checkout/paid", { params }),
  deatils: async (id) => await payHandler.get(`/${id}`),
};

export const paymentFilterService = {

  get: async (params) => await api.get("/billing/admin/checkout/filter", { params }),
  deatils: async (id) => await payHandler.get(`/${id}`),
};


export const filterDate = {
  get: async (params) => await api.get("/billing/admin/checkout/date", {params}),
}

export const contactService = {
  get: async (params) => await contactHandler.get("/", params),
  deatils: async (id) => await contactHandler.get(`/${id}`),
  reply: async (id, data) => await contactHandler.post(`/reply/${id}`, data),
};

export const subAdmin = {
  get: async (params) => await api.get("/user/admin", { params }),
  create: async (data) => await subAdminHandler.post("", data),
  getById: async (id) => await subAdminHandler.get("/" + id),
  update: async (id, data) => await subAdminHandler.put("/" + id, data),
  delete: async (id) => await subAdminHandler.delete(`/delete/${id}`),
};

export const global = {
  getResource: async (params) => await globalHandler.get("/resource", params),
  getUsers: async () => await globalHandler.get("/user"),
  getStatus: async () => await globalHandler.get("/status"),
};

export const organization = {
  get: async () => await api.get("/organization/admin/"),
  getById: async (id) => await api.get("/organization/admin/" + id),
  put: async (id, data) => await api.put(`/organization/admin/${id}`, data),
};

export const form = {
  get: async () => await api.get("/form/admin/all"),
  toggleForm: async (id) =>
    await api.post("/form/admin/toggle/" + id + "/status"),
  delete: async (id) => await api.delete("/form/admin/delete/" + id + "/id"),
};

export const packageSubscription = {
  create: async (data) => await packageHandler.post("", data),
  update: async (id, data) => await packageHandler.put("/" + id, data),
  get: async (params) => await packageHandler.get("", params),
  getById: async (id) => await packageHandler.get(`/${id}`),
  togglestatus: async (id) =>
    await api.post(`billing/admin/toggleStatus/package/${id}`),
};
export const planSubscription = {
  create: async (data) => await planHandler.post("", data),
  getById: async (id) => await planHandler.get(`/${id}`),
};

export const featureReq = {
  get: async (params) => await featurehandler.get("/", params),
};

export const dashboardservice = {
  get: async () => await api.get("/dashboard/admin/"),
  getSubscriptionGraph: async () => await api.get("/dashboard/admin/subscription-graph"),
  getPrincipalGraph: async () => await api.get("/dashboard/admin/prinicpal-graph"),
  getTaskGraph: async () => await api.get("/dashboard/admin/task-graph"),
};

export const closedAccounts = {
  get: async (params) => await api.get('/agent/admin/closed-account', { params }),
  getById: async (id) => await api.get('/agent/admin/closed-account/id/' + id),
}


