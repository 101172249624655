import React, { useCallback, useState, useRef, useEffect } from "react";
import GlobalTable from '../../Components/GlobalTable/GlobalTable'
import { Box, Menu, MenuItem, Paper } from '@mui/material'
import UserProfile from "../../Components/GlobalTable/UserProfile";
import downloadCSV from "../../Components/GlobalTable/DownloadCSV";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineEye, AiOutlineUpload } from 'react-icons/ai'
import SecondaryButton from '../../Components/SecondaryButton'
import { BiFilterAlt } from 'react-icons/bi'
import { FormControlLabel, TextField } from '@mui/material';
import { Checkbox } from '@mui/material';
import './AdminUsers.css'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import UserView from "./UserView";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../../Redux/Slices/userSlice/userSlice";

const AdminUsers = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(true)

  const [selectedRows, setSelectedRows] = useState([]);
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const dispatch = useDispatch();
  const tabledata = useSelector((state) => state.userSlice.data);
  // (tabledata?.rows);

  useEffect(() => {
    dispatch(fetchUser());
  }, []);


  useEffect(() => {
    setIsLoading(false)
  }, [tabledata])

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const data = [
    {
      id: 1,
      email: "Ameliajones@gmail.com",
      name: "Amelia Jones",
      count: "10",
      phone: "1257845112",
      subscription: "Yearly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 2,
      email: "Ameliajones@gmail.com",
      name: "Emma Smith",
      count: "10",
      phone: "1257845112",
      subscription: "Monthly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 3,
      email: "diptimhabadi@gmail.com",
      name: "Dipti Mhabadi",
      count: "10",
      phone: "1257845112",
      subscription: "Yearly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 4,
      email: "dipalikharat@gmail.com",
      name: "Dipali Kharat",
      count: "10",
      phone: "1257845112",
      subscription: "Monthly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 5,
      email: "dipalikharat@gmail.com",
      name: "Dipali Kharat",
      count: "10",
      phone: "1257845112",
      subscription: "Yearly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 6,
      email: "dipalikharat@gmail.com",
      name: "Dipali Kharat",
      count: "10",
      phone: "1257845112",
      subscription: "Monthly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 7,
      email: "dipalikharat@gmail.com",
      name: "Dipali Kharat",
      count: "10",
      phone: "1257845112",
      subscription: "Yearly",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },
    {
      id: 8,
      email: "dipalikharat@gmail.com",
      name: "Dipali Kharat",
      count: "10",
      phone: "1257845112",
      subscription: "Free trial",
      profilePic:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
    },

  ];

  const columns = [
    {
      name: "Name",
      cell: (row) => <UserProfile data={row} />,
      sortable: true,
      width: "18%",
    },
    {
      name: "Email Address",
      selector: (row) => row.email_address,
      sortable: true,
      width: "20%",
    },
    {
      name: "Contact Count",
      selector: (row) => row.count,
      sortable: true,
      width: "15%",
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
      sortable: true,
      width: "15%",
    },
    {
      name: "Subscription Type",
      selector: (row) => row.subscription,
      sortable: true,
      width: "16%",
    },
    {
      name: 'Actions',
      width: '10%',
      cell: () => (
        <div className='ms-2'>
          <MoreVertIcon
            style={{
              fontSize: '22px', // Change the size as needed
              color: '#4ccd8d'
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            className='pointer'
          />
          <Menu
            className='menu-action'
            anchorEl={anchorEl}
            open={open}
            onClose={closeMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '#ccc 0px 2px 5px -1px,#ccc 0px 1px 3px -1px',
                padding: '0 5px'
              },
            }}
          >
            <MenuItem onClick={openModal} style={{ fontSize: '14px' }}>
              <AiOutlineEye style={{ fontSize: '16px', marginRight: '8px' }} /><span className='cad-text-small'>View</span>
            </MenuItem>
            <Link to="/chat" style={{ fontSize: '14px', color: '#000000b8', textDecoration: 'none' }}>
              <MenuItem  >
                <ChatBubbleOutlineIcon style={{ fontSize: '16px', marginRight: '8px' }} /><span className='cad-text-small'>Chat</span>
              </MenuItem>
            </Link>
          </Menu>
        </div>
      )
    }
  ];

  // toaster for no row selected
  const notify = () => {
    toast.error("No rows selected for export!", {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  // Handle click on export button
  const handleExportClick = () => {
    if (selectedRows.length === 0) {
      // alert('No rows selected for export');
      notify()
      return;
    }

    // Call downloadCSV function with selectedRows
    downloadCSV(selectedRows, "selected_data.csv");
  };

  // Callback function to handle changes in selected rows
  const handleChange = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const formControlLabelStyle = {
    "& .MuiFormControlLabel-label": {
      color: '#131313',
      fontFamily: 'Open Sans',
      fontSize: '11.397px',
      fontStyle: 'normal',
      fontEeight: 400,
      lineHeight: 'normal',
      textTransform: 'capitalize'
    }
  }

  // State and functions for filter by category dropdown
  const [isDivVisible, setIsDivVisible] = useState(false);
  const divRef = useRef(null);
  const buttonRef = useRef(null);

  const handleButtonClick = () => {
    setIsDivVisible(!isDivVisible);
  };

  const handleClickOutside = (e) => {
    if (
      divRef.current &&
      !divRef.current.contains(e.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(e.target)
    ) {
      setIsDivVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Paper
      elevation={2}
      style={{
        backgroundColor: "#f1f2f6",
      }}
      className="adminuser m-3 w-85 d-flex overflow-scroll flex-column justify-content-start align-items-start"
    >
      <Box
        className="custom-panel  border-bottom rounded-top-3 p-3"
        sx={{
          borderBottom: 0,
          padding: 0,
          backgroundColor: "#fff",
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <input
            className="form-control cad-text-medium w-25"
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search..."
          />
          <div className="d-flex align-items-center gap-2">
            <SecondaryButton
              title="Export"
              padding="0.2rem 1.2rem"
              icon={<AiOutlineUpload size={16} />}
              fontSize="13px"
              onClick={handleExportClick}
            />
            <div className='position-relative'>
              <button className='filter-category h-100' onClick={handleButtonClick} ref={buttonRef}>
                <BiFilterAlt />
              </button>
              {isDivVisible && (
                <div ref={divRef} className="category-filter-dropdown">
                  <FormControlLabel
                    value="ID"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="ID"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                  <FormControlLabel
                    value="First Name"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="First Name"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                  <FormControlLabel
                    value="Last Name"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="Last Name"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                  <FormControlLabel
                    value="Email"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="Email"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                  <FormControlLabel
                    value="Designation"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="Designation"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                  <FormControlLabel
                    value="Repoting Manager"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="Repoting Manager"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                  <FormControlLabel
                    value="Assign Client"
                    control={
                      <Checkbox
                        sx={{
                          color: '#4CCD8D',
                          "&.Mui-checked": {
                            color: '#4CCD8D',
                          },
                          "& .MuiSvgIcon-root": { fontSize: 18 },
                        }}
                      />}
                    label="Assign Client"
                    labelPlacement="end"
                    sx={{ ...formControlLabelStyle }}
                  // onChange={(e) => console.log(e.target.value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <GlobalTable
          data={tabledata?.rows}
          columns={columns}
          searchText={searchText}
          selectableRows={true}
          onSelectedRowsChange={handleChange}
        />
        <ToastContainer />
      </Box>

      {isModalOpen && (
        <UserView handleClose={closeModal} open={isModalOpen} />
      )}
    </Paper>
  )
}

export default AdminUsers
