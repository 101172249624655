import { Button, TextField } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { authService } from "../../service/endpoints";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { authMe } from "../../features/auth/authSlice";

const ChangePassword = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false)


  useEffect(() => {
    dispatch(authMe());
  }, [dispatch]);

  const { themeColorsObject } = useSelector((state) => state.theme);

  const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .matches(/[A-Z]/, "Password must contain atleast one uppercase")
      .matches(/[a-z]/, "Password must contain atleast one lowercase")
      .matches(/[@#&]/, "Password must contain special character @,#,&")
      .min(8, "password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .matches(/[A-Z]/, "Password must contain atleast one uppercase")
      .matches(/[a-z]/, "Password must contain atleast one lowercase")
      .matches(/[@#&]/, "Password must contain special character @,#,&")
      .min(8, "password must be at least 8 characters")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  // console.log(errors);

  const onSubmitPassword = async (data) => {
    setLoading(true)
    console.log(data);
    try {
      const res = await authService.changePassword(data);
      console.log(res);
      enqueueSnackbar(res.data?.message, {
        variant: "success",
      });
      setLoading(false)
      dispatch(authMe());
      reset();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response?.data?.error?.message, {
        variant: "error",
      });
      setLoading(false)
      dispatch(authMe());
      reset();
    }
  };

  return (
    <div
      style={{
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
      }}
      className="w-100 border-white justify-content-between  rounded-2 gap-4 d-flex p-4"
    >
      <form
        onSubmit={handleSubmit(onSubmitPassword)}
        className="d-flex p-4 flex-wrap pt-4 gap-4 justify-content-center w-100"
      >
        <div className="col-md-4 col-12">
          <TextField
            className="text-small"
            label="Password"
            {...register("password")}
            // value={newPassword}
            // onChange={(e) => setNewPassword(e.target.value)}
            id="outlined-size-small-new" // Unique id
            size="small"
            type="password"
            fullWidth
            error={errors && errors?.password}
            helperText={errors?.password?.message}
          />
        </div>

        <div className="col-md-4 col-12">
          <TextField
            className="text-small"
            label="Confirm Password"
            // value={confirmPassword}

            {...register("confirmPassword")}
            // onChange={(e) => setConfirmPassword(e.target.value)}
            id="outlined-size-small-confirm" // Unique id
            size="small"
            type="password"
            fullWidth
            error={errors && errors?.confirmPassword}
            helperText={errors?.confirmPassword?.message}
          />
        </div>
        <div className="w-100 d-flex justify-content-center">
          <div className="col-1 d-flex align-items-center">
            <Button
              className="button-contained col-1"
              variant="contained"
              size="small"
              type="submit"
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ChangePassword;
