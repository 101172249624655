import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from '../../utils/schema/login.schema';
import { authService } from '../../service/endpoints';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";

const ForgotPasswordEmail = ({ showForgotPassFiled }) => {

    const emailSchema = Yup.object().shape({
        email_address: Yup.string()
            .email("Please enter a valid email address")
            .required("Email is required").matches(
                // Regular expression pattern including top-level domains (TLDs)
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                "Email address is required"
            ),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ mode: "all", resolver: yupResolver(emailSchema) });

    const onSubmitForgotPass = async (value) => {
        // console.log(value);
        // const formData = new FormData();
        // formData.append('email_address', value.email_address)
        try {
            const result = await authService.forgotPassword({ email_address: value.email_address });
            console.log(result);
            if (result.status === 200) {
                enqueueSnackbar(result.data.message, {
                    variant: "success",
                });
            }
        } catch (error) {
            //   console.log(error?.response?.data?.error?.message);
            console.log(error);
            enqueueSnackbar(error.data.message, {
                variant: "error",
            });
        }
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmitForgotPass)}
            className="d-flex flex-column  justify-content-start h-100"
        >
            <div className="mb-3">
                <label
                    htmlFor="exampleInputEmail1"
                    className="form-label text-primary"
                >
                    Email address
                </label>
                <input
                    type="text"
                    className="form-control p-3 ps-3 border-primary"
                    id="exampleInputEmail1"
                    placeholder="Email address"
                    aria-describedby="emailHelp"
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    {...register("email_address")}
                />
                <div
                    htmlFor="exampleInputEmail1"
                    className="form-label text-primary form-text text-danger"
                >
                    {errors.email_address?.message}
                </div>
            </div>
            <LoadingButton
                // loading={isLoading}
                type="submit"
                className="btn mt-2 pt-2 fs-5 cad-fw-700 pb-2 button-contained"
            >
                <span>Log in</span>
            </LoadingButton>
            <p className="text-center pointer pt-2" onClick={() => showForgotPassFiled(true)} >
                <Link className="cad-itext-small text-primary cad-text-large text-decoration-none">
                    back to login
                </Link>
            </p>
        </form>
    )
}

export default ForgotPasswordEmail;