import React, { useEffect } from "react";
import { Box, Button, Modal, Paper } from "@mui/material";
import { CiWarning } from "react-icons/ci";
import { useDispatch } from "react-redux";
import { subsThunkAPI } from "../../../features/subscription/subscription.slice";
import { subsService } from "../../../service/endpoints";

const SubActiveModal = ({ handleClose, open, id }) => {
    const dispatch = useDispatch();

    const handleToggle = async (id) => {
        try {
            const res = await subsService.toggle(id);
            if (res.status === 202) {
                // console.log(res.data);
                dispatch(subsThunkAPI.getAll());
                handleClose();
                return res.data;
            }
        } catch (error) {
            console.log(error)
        }
    };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "#fff",
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={2}>
                    <Box sx={style}>
                        <div className="modalhead d-flex justify-content-center align-items-center mb-2">
                            <CiWarning size={120} color="yellow" />
                        </div>
                        <div>
                            <h2 className="fs-4 mb-0 text-center mb-4">
                                Are you sure you want to activate this plan?
                            </h2>
                        </div>
                        <div className="row w-100">
                            <div className="col-6 m-auto d-flex">
                                <Button
                                    onClick={handleClose}
                                    className="w-100"
                                    variant="outlined"
                                    color="error"
                                >
                                    No
                                </Button>
                            </div>

                            <div className="col-6 m-auto d-flex">
                                <Button
                                    onClick={() => handleToggle(id)}
                                    className="button-outlined w-75"
                                    variant="outlined"
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </Modal>
        </div>
    );
};

export default SubActiveModal;
