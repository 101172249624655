import React, { useState } from "react";
import "./Login.css";
import { Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { loginSchema } from "../../utils/schema/login.schema";
import { authLogin, authMe, } from "../../features/auth/authSlice";
import { enqueueSnackbar } from "notistack";
import { authService } from "../../service/endpoints";
import TokenService from "../../service/token.service";
import ForgotPasswordEmail from "./forgot-password-email";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Login = () => {

  const [forgotPassFiled, showForgotPassFiled] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(loginSchema) });

  const dispatch = useDispatch();
  const { message, isError, isLoading, isSuccess } = useSelector((state) => state?.auth);
  const navigate = useNavigate();

  const onSubmit = async (value) => {
    // console.log(value);
    // dispatch(authLogin(value));

    try {
      const result = await authService.login(value);
      // console.log(result);
      if (
        result.status === 200 &&
        result.data.group[0]?.title === "Administration"
      ) {
        const { accessToken, refreshToken } = result.data;
        TokenService.setUser({ accessToken, refreshToken });
        dispatch(authMe)

        enqueueSnackbar("Login Successfully", {
          variant: "success",
        });
        navigate("/")
        reset();
        return result.data;
      } else {
        // console.log()
        enqueueSnackbar("Invalid Credentails", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar('Invaild credentials', {
        variant: "error",
      });
    }



  };

  console.log(errors);


  return (
    <div className="login  container-fluid">
      <div className="container d-flex align-items-center w-100  h-100">
        <div className="d-flex w-100 justify-content-center align-items-center  h-75 p-0">
          <div className="col-left pe-3 pag-2 d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center">
            <h1 style={{ fontSize: "60px" }} className="cad-fw-800">
              Cloudify<span className="cad-fw-500">Docs</span>
            </h1>
            <p style={{ color: "#1c1e21" }} className="fs-4 cad-fw-600">
              Empowering Visions, Engineering Solutions: Your CLOUDIFYDocs Partner.
            </p>
          </div>
          <div className=" col-right col-4 h-auto  p-3">
            {forgotPassFiled ?
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column  justify-content-start h-100"
              >
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label text-primary"
                  >
                    Email address
                  </label>
                  <input
                    type="text"
                    className="form-control p-3 ps-3 border-primary"
                    id="exampleInputEmail1"
                    placeholder="Email address"
                    aria-describedby="emailHelp"
                    // value={email}
                    // onChange={(e) => setEmail(e.target.value)}
                    {...register("email_address")}
                  /></div>
                <div
                  htmlFor="exampleInputEmail1"
                  className="form-label text-primary form-text text-danger"
                >
                  {errors.email_address?.message}
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label text-primary"
                  >
                    Password
                  </label>
                  <div class="input-group mb-3">
                    <input
                      type={passwordVisible ? "text" : "password"}
                      className="form-control p-3 ps-3 border-primary"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      // value={password}
                      // onChange={(e) => setPassword(e.target.value)}
                      {...register("password")}
                    /><button
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      class="btn btn-outline-primary"
                      type="button">{passwordVisible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}</button></div></div>
                <label
                  htmlFor="exampleInputPassword1"
                  className="form-label text-primary form-text text-danger"
                >
                  {errors.password?.message}
                </label>

                <LoadingButton
                  // loading={isLoading}
                  type="submit"
                  className="btn mt-2 pt-2 fs-5 cad-fw-700 pb-2 button-contained"
                >
                  <span>Log in</span>
                </LoadingButton>
                <p className="text-center pointer pt-2" onClick={() => showForgotPassFiled(false)}>
                  <Link className="cad-itext-small text-primary cad-text-large text-decoration-none">
                    Forgot password?
                  </Link>
                </p>
              </form>
              :
              <ForgotPasswordEmail
                showForgotPassFiled={showForgotPassFiled}
              />

            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
