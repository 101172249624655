import React, { useEffect, useState } from "react";
import TableData from "../../Components/TableData";
import { useDispatch, useSelector } from "react-redux";
import * as themeColors from "../../utils/Theme/Colors";
import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { nextPage, payFilterThunkAPI, payThunkAPI } from "../../features/payments/payment.slice";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import PaymentViewModal from "../../Components/Modals/Payment/payment.view.modal";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";
import UserName from "../Users/UserName";
import { formatDate } from "../../utils/converter/date.converter";

const Payments = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(payThunkAPI.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(nextPage(1));
    dispatch(payThunkAPI.getAll(searchTerm));
    // dispatch(payFilterThunkAPI.getAll(searchTerm, ));
  }, [dispatch, searchTerm]);

  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");

  const { totalPages, rows, currentPage, isLoading } = useSelector(
    (state) => state?.payment
  );

  // trigger modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const openModal = (userId) => () => {
    setIsModalOpen(true);
    setSelectedUserId(userId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  // console.log(rows);

  const cellConfig = [
    // { dataKey: "sr_no", align: "left", width: 10 },
    { dataKey: "name", align: "left", width: 20 },
    { dataKey: "package", align: "left", width: 18 },
    { dataKey: "recurring", align: "left", width: 16 },
    { dataKey: "amount", align: "left", width: 16 },
    { dataKey: "status", align: "left", width: 16 },
    { dataKey: "createdAt", align: "left", width: 16 },
  ];

  const tableHeadRowCell = [
    // {
    //   title: "SR NO.",
    //   width: "16%",
    //   align: "left",
    // },
    {
      title: "Name",
      width: "20%",
      align: "left",
    },
    {
      title: "Package",
      width: "18%",
      align: "left",
    },
    {
      title: "Recurring",
      width: "16%",
      align: "left",
    },
    {
      title: "Amount",
      width: "16%",
      align: "left",
    },
    {
      title: "Status",
      width: "10%",
      align: "left",
    },
    {
      title: "Created At",
      width: "20%",
      align: "left",
    },
  ];

  const extractedDataArray = rows?.map((row, index) => {
    const extractedData = {
      id: row?.id,
      name: <UserName row={row} />,
      sr_no: (currentPage - 1) * 10 + index + 1,
      package: row?.package,
      amount: row?.amount,
      recurring: row?.recurring === "1" ? "Monthly" : "Yearly",
      status:
        row?.status === "paid" ? (
          <Chip label="Paid" color="success" variant="outlined" size="small" />
        ) : (
          <Chip label="Unpaid" color="error" variant="outlined" size="small" />
        ),
      createdAt: formatDate(row?.createdAt),
    };
    return extractedData;
  });

  const handlePagination = (value) => {
    dispatch(nextPage(value));
    dispatch(payThunkAPI.getAll());
  };


  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        // transition:"0.3s"
      }}
      className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start p-3"
    >
      <div className="w-100 my-1">
        <div className="d-flex justify-content-between">
          <div className="col-3 d-flex align-items-center gap-1">
            <SearchInput
              searchValue={searchTerm}
              searchFucntion={(e) => setSearchTerm(e.target.value)}
            />
            <Refresher
              isLoading={isLoading}
              handleRefresh={() => dispatch(payThunkAPI.getAll())}
            />
          </div>

        </div>
      </div>
      <TableData
        tableHeadRowCell={tableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={cellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        totalPages={totalPages}
        handlePagination={handlePagination}
        currentPage={currentPage}
        isLoading={isLoading}
        handleViewOpen={openModal}
      />

      {isModalOpen && (
        <PaymentViewModal
          handleClose={closeModal}
          open={isModalOpen}
          id={selectedUserId}
        />
      )}
    </div>
  );
};

export default Payments;
