import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { nextPage, userThunkAPI } from "../../features/users/user.slice";
import TableData from "../../Components/TableData";
import UserViewModal from "../../Components/Modals/users/user.view.modal";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";
import UserName from "./UserName";
import AdvanceFilter from "../../Components/AdvanceFilter/AdvanceFilter";
import { formatDate } from "../Dashboard/Dashboard";
import { Chip } from "@mui/material";

const AllUsers = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(userThunkAPI.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(nextPage(1));
    dispatch(userThunkAPI.getAll(searchTerm)); // Pass search term to getAll thunk
  }, [dispatch, searchTerm]);

  const navigate = useNavigate();

  const { totalPages, rows, currentPage, isLoading } = useSelector(
    (state) => state?.user
  );
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);

  // console.log(rows);

  // trigger modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const openModal = (userId) => {
    setIsModalOpen(true);
    setSelectedUserId(userId);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const [checkboxValues, setCheckboxValues] = useState({
    // "SR NO": true,
    sub_type: true,
    name: true,
    "Email address": true,
    "Verfied user": true,
    "Phone number": false,
    "Created At": true,
  });
  const cellConfig = [
    // { dataKey: "sr_no", align: "left", width: 10 },
    { dataKey: "sub_type", align: "left", width: 10 },
    { dataKey: "name", align: "left", width: 20 },
    { dataKey: "email_address", align: "left", width: 20 },
    { dataKey: "verfied_user", align: "left", width: 10 },
    { dataKey: "phone_number", align: "left", width: 10 },
    { dataKey: "createdAt", align: "left", width: 20 },
  ];
  const tableHeadRowCell = [
    // {
    //     title: "SR NO.",
    //     width: "10%",
    //     align: "left",
    // },
    {
      title: "Subscription",
      width: "10%",
      align: "left",
    },
    {
      title: "User",
      width: "20%",
      align: "left",
    },
    {
      title: "Email address",
      width: "20%",
      align: "left",
    },
    {
      title: "Verified user",
      width: "10%",
      align: "left",
    },
    {
      title: "Phone number",
      width: "10%",
      align: "left",
    },
    {
      title: "Created At",
      width: "20%",
      align: "left",
    },

  ];
  const extractedDataArray = rows?.map((row, index) => {
    const extractedData = {
      id: row?.id,
      // sr_no: (currentPage - 1) * 10 + index + 1,
      name: <UserName row={row} />,
      sub_type: row?.business && row?.business[0]?.subscriptions && row?.business[0]?.subscriptions[0]?.status
        ? <Chip label={row?.business[0]?.subscriptions[0]?.status === "paid"
          ? "subscriber"
          : row?.business[0]?.subscriptions[0]?.status
        } color={row?.business[0]?.subscriptions[0]?.status === "paid" ? "success" : "error"} variant="outlined" size="small" />
        : <Chip label={"free"} color="warning" variant="outlined" size="small" />,
      email_address: row?.email_address,
      verfied_user: row?.verfied_user
        ? <Chip label={"Verified"} color="success" variant="outlined" size="small" />
        : <Chip label={"Not-Verified"} color="error" variant="outlined" size="small" />,
      phone_number: row?.phone_number, // need values from backend
      createdAt: formatDate(row?.createdAt),

    };
    return extractedData;
  });

  const handlePagination = (value) => {
    dispatch(nextPage(value));
    dispatch(userThunkAPI.getAll());
  };

  const handleViewOpen = (id) => () => {
    navigate(`/user-details/${id}`);
  };
  return (
    <div className="ps-3 pe-3">
      <div className="row">
        <div className="col-3 d-flex align-items-center gap-1 mt-3">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(userThunkAPI.getAll())}
          />
        </div>
        <div className="col-9 d-flex align-items-end justify-content-end mt-3">
          <AdvanceFilter
            bottom="-169px"
            left="-73px"
            cellConfig={cellConfig}
            tableHeadRowCell={tableHeadRowCell}
            setFilteredCellConfig={setFilteredCellConfigg}
            setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />
        </div>
      </div>

      <TableData
        tableHeadRowCell={filteredTableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={filteredCellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        totalPages={totalPages}
        handlePagination={handlePagination}
        currentPage={currentPage}
        isLoading={isLoading}
        handleViewOpen={handleViewOpen}
      />

      {isModalOpen && (
        <UserViewModal
          handleClose={closeModal}
          open={isModalOpen}
          id={selectedUserId}
        />
      )}
    </div>
  );
};

export default AllUsers;
