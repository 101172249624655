import React, { useEffect, useState } from "react";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import { Avatar, Button, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import StarBorderPurple500Icon from "@mui/icons-material/StarBorderPurple500";
// import Footer from "../Footer/Footer";
import { useFormik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import VerifiedIcon from "@mui/icons-material/Verified";
// import { updateAgent } from "../../service/agent.service";
import { useDispatch } from "react-redux";
import { authService } from "../../service/endpoints";
import { authMe } from "../../features/auth/authSlice";
import { enqueueSnackbar } from "notistack";
import ChangePassword from "./ChangePassword";
import BasicTabs from "../../Components/Tabs/BasicTabs";
// import { fetchAuthMe } from "../../Redux/Slice/fetchAuthMe";

const formatDate = (dateString) => {
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = new Date(dateString).toLocaleDateString(
    undefined,
    options
  );
  return formattedDate;
};

const PersonalDetailsNew = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authMe());
  }, [dispatch]);

  const { theme, themeColorsObject } = useSelector((state) => state.theme);

  const [isEditing, setIsEditing] = useState(false);
  const [isPassword, setIsPassword] = useState(false);

  const [loading, setLoading ] = useState(false);


  const { userProfile } = useSelector((state) => state?.auth);
  const metadata = userProfile?.metadata;
  // const { profileData } = useSelector((state) => state?.authMe);
  const [profileDetails, setProfileDetails] = useState({
    profilePic:
      process.env.REACT_APP_BACKEND_BASEURL + "/" + userProfile?.profile_photo,
  });

  // console.log(authInfo);

  const profileDataArray = [
    {
      title: "First Name",
      value: userProfile?.first_name,
      name: "first_name",
      disable: false,
    },
    {
      title: "Last Name",
      value: userProfile?.last_name,
      name: "last_name",
      disable: false,
    },
    // {
    //   title: "Email Address",
    //   value: userProfile?.email_address,
    // },
    // {
    //   title: "Date Of Birth",
    //   value: userProfile?.date_of_birth,
    // },
    // {
    //   title: "Register Date",
    //   value: formatDate(userProfile?.createdAt),
    // },
    {
      title: "Phone Number",
      value: userProfile?.phone_number,
      name: "phone_number",
      disable: false,
    },
    {
      title: "Email Address",
      value: userProfile?.email_address,
      name: "email_address",
      disable: false,
    },
    // {
    //   title: "Department",
    //   value: metadata?.department,
    // },
    // {
    //   title: "Location",
    //   value: metadata?.location,
    // },
  ];
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .matches(/^[A-Za-z]*$/, "please enter a valid character")
      .required("First Name is required"),
    last_name: Yup.string()
      .matches(/^[A-Za-z]*$/, "please enter a valid character")
      .required("Last Name is required"),
    // date_of_birth: Yup.date().nullable(),
    // createdAt: Yup.date().nullable().required("Register Date is required"),
    phone_number: Yup.string()
      .min(10, "Phone number must be at least 10 or 11 characters")
      .max(11, "Phone number must be less than 11 characters")
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is required"),
    // designation: Yup.string().required("Designation is required"),
    // department: Yup.string().required("Department is required"),
    // location: Yup.string().required("Location is required"),
  });

  // // Initial form values from authInfo
  const initialValues = {
    first_name: userProfile?.first_name || "",
    last_name: userProfile?.last_name || "",
    // date_of_birth: userProfile?.date_of_birth || null,
    // createdAt: formatDate(userProfile?.createdAt) || null,
    phone_number: userProfile?.phone_number || "",
    // designation: metadata?.designation || "",
    // department: metadata?.department || "",
    // location: metadata?.location || "",
    email_address: userProfile?.email_address || "na",
  };

  const handleImageChange = async (e) => {
    // console.log("hit");
    const file = e.target.files[0];
    const formData = new FormData();
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileDetails({
          ...profileDetails,
          profilePic: event.target.result,
        });
      };
      reader.readAsDataURL(file);

      formData.append("profile_photo", file);
      try {
        const res = await authService.update(formData);
        console.log(res);
        if (res?.status === 200) {
          // console.log("successs notify here");
          enqueueSnackbar(res.data?.message, {
            variant: "success",
          });
          setIsEditing(false);
          dispatch(authMe());
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error.response?.data?.error?.message);
        setIsEditing(false);
        dispatch(authMe());
      }
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
    mode: "all",
  });

  console.log(errors);

  const onSubmit = async (data) => {
    setLoading(true)
    console.log("hit");
    console.log("Form submitted with values:", data);
    // Create FormData object
    const formData = new FormData();

    // formData.append("location", data?.location);
    // formData.append("department", data?.department);
    // formData.append("designation", data?.designation);
    formData.append("phone_number", data?.phone_number);
    // formData.append("createdAt", data?.createdAt);
    formData.append("email_address", data?.email_address);
    formData.append("last_name", data?.last_name);
    formData.append("first_name", data?.first_name);
    // formData.append("profile_photo");

    for (const pair of formData.entries()) {
      console.log(`========>${pair[0]}: ${pair[1]}`);
    }

    try {
      const res = await authService.update(formData);
      if (res?.status === 200) {
        console.log("successs notify here");
        setIsEditing(false);
        dispatch(authMe());
        // dispatch(fetchAuthMe());
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response?.data?.error?.message);
      setIsEditing(false);
      setLoading(false)
      dispatch(authMe());
    }

    // dispatch(fetchAuthMe());
    // dispatch(AuthMe())
    // setIsEditing(false);
  };

  const tabs = [
    {
      label: "Profile ",
      content: (
        <>
          <div className="w-100 d-flex gap-3 justify-content-end align-items-center pe-3">
            <div className="col-1 d-flex align-items-center">
              <Button
                className="button-contained col-1"
                variant="contained"
                size="small"
                onClick={() => setIsEditing(!isEditing)}
              >
                {isEditing ? "Close" : "Edit"}
              </Button>
            </div>
          </div>
          <div
            style={{
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.textColor,
            }}
            className="w-100 rounded-2 d-flex flex-wrap p-3"
          >
            {profileDataArray.map(({ title, value }, index) => (
              <div
                className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                key={index}
              >
                <span className="cad-text-small cad-fw-500 text-uppercase">
                  {title}
                </span>
                <span className="cad-text-medium cad-fw-600">{value}</span>
              </div>
            ))}
          </div>

          <div
            style={{
              overflow: "hidden",
              display: "block",
              height: isEditing ? "fit-content" : 0,
              opacity: isEditing ? 1 : 0,
              transition: "height 2s, opacity 1s",
              backgroundColor: themeColorsObject.megaCardBackgroundColor,
              color: themeColorsObject.textColor,
            }}
            className={`${isEditing ? "rounded-3 w-100" : ""}`}
          >
            <form
              style={{
                transform: isEditing ? "translateY(0)" : "translateY(-210px)",
                opacity: isEditing ? 1 : 0,
                zIndex: isEditing ? 1 : -2,
                transition: "transform 0.5s, opacity 1s",
                display: "block",
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="w-100 rounded-2 d-flex flex-wrap p-3">
                {profileDataArray.map(
                  ({ title, value, name, disable }, index) =>
                    title === "Email Address" ? (
                      <div
                        className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                        key={index}
                      >
                        <span className="cad-text-small cad-fw-500 text-uppercase">
                          {title}
                        </span>
                        <span className="cad-text-medium cad-fw-600">
                          {value}
                        </span>
                      </div>
                    ) : (
                      <div
                        className="col-3 mb-3 d-flex flex-column gap-1 p-2"
                        key={index}
                      >
                        <label
                          className="cad-text-small cad-fw-500 text-uppercase"
                          htmlFor={title}
                        >
                          {title}
                        </label>
                        <Controller
                          control={control}
                          name={name}
                          render={({ field }) => (
                            <input
                              className={`form-control ${themeColorsObject.themed_input_class}`}
                              {...field}
                            />
                          )}
                          disabled={disable}
                        />
                        {errors[name] && (
                          <div className="cad-text-small text-danger">
                            {errors[name].message}
                          </div>
                        )}
                      </div>
                    )
                )}
                <div className="w-100 d-flex justify-content-center">
                  <div className="col-1 d-flex align-items-center">
                    <Button
                      className="button-contained col-1"
                      variant="contained"
                      size="small"
                      type="submit"
                    >
                      {loading ? "Loading..." : "Update"}
                      
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      ),
    },
    {
      label: "Password change",
      content: <ChangePassword />,
    },
  ];

  return (
    <div
      style={{
        minHeight: "81vh",
      }}
      className="mt-5 mb-4 gap-3   flex-column  d-flex justify-content-start align-items-start"
    >
      <div
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        className="w-100 cad-shadow border-white justify-content-between  rounded-2 gap-4 d-flex p-4"
      >
        <div className="gap-4 d-flex">
          <div
            style={{
              position: "relative",
            }}
          >
            <Avatar
              className="m-1"
              style={{
                width: "100px",
                height: "100px",
                border: "4px solid #E9E9E9",
              }}
              alt={userProfile?.first_name}
              src={profileDetails?.profilePic}
            />

            <input
              type="file"
              id="profile-image-input"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={handleImageChange}
              className={`form-control cad-text-medium ${themeColorsObject.themed_input_class}`}
            />

            <label
              style={{
                position: "absolute",
                bottom: "0.8rem",
                right: "-0.5rem",
                height: "30px",
                width: "30px",
                backgroundColor: "#E9E9E9",
                padding: "4px",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              htmlFor="profile-image-input"
            >
              <CameraAltTwoToneIcon
                sx={{
                  position: "absolute",
                  bottom: "0rem",
                  right: "0rem",
                  height: "30px",
                  width: "30px",
                  backgroundColor: "#E9E9E9",
                  padding: "4px",
                  borderRadius: "50%",
                  cursor: "pointer",
                  color: "#000",
                }}
              />
            </label>
          </div>

          <div className="pro-details d-flex justify-content-center flex-column">
            <span className="cad-fw-600 d-flex gap-2 cad-text-large">
              {userProfile?.first_name} {userProfile?.last_name}
              <span
                style={{ color: "#4CCD8D" }}
                className="d-flex align-items-center  cad-text-large"
              >
                <VerifiedIcon />
              </span>
            </span>
            <span className="cad-fw-400 text-grey cad-text-medium">
              {userProfile?.phone_number}
            </span>
            <span className="cad-fw-400 text-grey cad-text-medium">
              {userProfile.email_address}
            </span>
          </div>
        </div>
        <div className="d-flex align-items-end  gap-3"></div>
      </div>
      <BasicTabs tabs={tabs} />
    </div>
  );
};

export default PersonalDetailsNew;
