import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import GroupIcon from '@mui/icons-material/Group';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import SourceIcon from '@mui/icons-material/Source';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import SettingsPhoneIcon from '@mui/icons-material/SettingsPhone';
import MessageIcon from '@mui/icons-material/Message';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';


export const nav = [
  {
    title: "Dashboard",
    path: "/",
    icon: <GridViewOutlinedIcon />,
  },
  {
    title: "Users",
    path: "/users",
    icon: <GroupIcon />,
  },
  {
    title: "Subscription",
    path: "/subscription",
    icon: <CurrencyExchangeIcon />,
  },
  {
    title: "Payments",
    path: "/payments",
    icon: <AttachMoneyIcon />,
  },
  {
    title: "CMS",
    path: "/cms",
    icon: <RoomPreferencesIcon />,
  },
  {
    title: "Forms",
    path: "/forms",
    icon: <SourceIcon />,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: <SummarizeIcon />,
  },
  {
    title: "Supports",
    path: "/supports",
    icon: <ManageHistoryIcon />,
  },
  {
    title: "Contact Us",
    path: "/contact",
    icon: <SettingsPhoneIcon />,
  },
  {
    title: "Chats",
    path: "/chat",
    icon: <MessageIcon />,
  },
  {
    title: "Sub Admin",
    path: "/subadmin",
    icon: <ManageAccountsIcon />,
  },
  {
    title: "Features Request",
    path: "/features-request",
    icon: <SettingsIcon />,
  },
];
