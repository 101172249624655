import React from "react";
import Login from "./Pages/Login/Login";
import DefaultLayout from "./layout/defaultLayout";
import { Route, Routes } from "react-router-dom";
import ForgotPasswordChange from "./Pages/Login/Forgot-password-change";
import { toast, ToastContainer, Zoom } from "react-toastify";

export const notify = (message, type) => {
  if (type === "error") {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else if (type === "warn") {
    toast.warn(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else if (type === "info") {
    toast.info(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  } else {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Zoom,
    });
  }
};

function App() {
  // const craftedMsg = "Crafted with ❤️ by WDI Team for a better web.";
  // const websiteMsg = "Website: www.wdipl.com";

  // const craftedStyles =
  //   "font-size: 16px; font-family: monospace; background: #000; color: #E5195E; padding: 12px 19px; border: 1.8px dashed; border-right: 0px #000 solid";
  // const websiteStyles =
  //   "font-size: 16px; font-family: monospace; background: #E5195E; color: #000; padding: 12px 19px; border: 1.8px dashed #000; border-left: 0px #000 solid";
  // console.log(
  //   `%c ${craftedMsg} %c ${websiteMsg}`,
  //   craftedStyles,
  //   websiteStyles
  // );

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<DefaultLayout />} />
        <Route path="/forget-password-change" element={<ForgotPasswordChange />} />
      </Routes>
      <ToastContainer
        style={{
          minWidth: 300,
        }}
        className="cad-text-medium w-auto  "
      />
    </>
  );
}

export default App;
