import React, { useState } from "react";
import PrimaryButton from "../../PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import RichTextQuill from "../../Quill/Quill";
import { privacyService } from "../../../service/endpoints";

const PrivacyAdd = () => {
  const [editorHtml, setEditorHtml] = useState(""); // Initial value is an empty string
  const navigate = useNavigate();
  const handleSubmit = async () => {
    const plainText = editorHtml.replace(/<\/?[^>]+(>|$)/g, "");
    if (!plainText) {
      alert("Please fill these content");
      return;
    }
    const result = await privacyService.post({ content: editorHtml });
    if (result.status === 200) {
      navigate("/privacypolicy");
    }
  };
  return (
    <div className=" m-3 editdetails bg-white w-85 h-100 rounded-2 p-3">
      <div className=" mb-4">
        <Link to="/privacypolicy" className="text-black text-decoration-none">
          <BsArrowLeft fontSize="20px" style={{ marginRight: "10px" }} />
          Add Details
        </Link>
      </div>
      <RichTextQuill
        editorHtml={editorHtml}
        handleChange={(content) => setEditorHtml(content)}
      />
      <div className="d-flex mt-4 justify-content-center">
        <PrimaryButton
          onClick={handleSubmit}
          padding="0.4rem 3rem"
          title="Save"
        />
      </div>
    </div>
  );
};

export default PrivacyAdd;
