import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllUser, getContacts, getMessage } from "../../service/chat.service";
import { handleFulfilled, handlePending, handleRejected } from "../../utils/handler/state.handler";

export const chatThnkApi = {
    getChatList: createAsyncThunk("chat/getChatList",
        async (params, { rejectWithValue, abort, getState }) => {
            try {
                const { data } = await getContacts()
                // console.log(data);
                return data
            } catch (error) {
                throw rejectWithValue(error)
            }
        }),
    getUsersList: createAsyncThunk("chat/getUsersList", // Changed action type to be unique
        async (params, { rejectWithValue, abort, getState }) => {
            try {
                const authId = getState().auth?.authInfo && getState().auth?.authInfo?.id
                const { data } = await getAllUser()
                return data.data?.filter(
                    (item) => item.id !== authId
                );
            } catch (error) {
                throw rejectWithValue(error)
            }
        }),
    getActiveChatMessages: createAsyncThunk("chat/getActiveChatMessage", async (id, { rejectWithValue, abort, getState }) => {
        try {
            const { data } = await getMessage(id)
            // console.log(data);
            return data.payload
        } catch (error) {
            throw rejectWithValue(error)
        }
    })
}


const chatSlice = createSlice({
    name: "chat",
    initialState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        socket: null,
        users: [],
        chats: [],
        activeChat: undefined,
        activeChatMessage: []
    },
    reducers: {
        setSocket: (state, action) => {
            console.log("hit");
            state.socket = action.payload;
        },
        setChat: (state, action) => {
            state.activeChat = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(chatThnkApi.getChatList.pending, handlePending)
            .addCase(chatThnkApi.getChatList.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.chats = filterChatList(action.payload.payload); // Adjust this line to match your payload structure
            })
            .addCase(chatThnkApi.getChatList.rejected, handleRejected)
            .addCase(chatThnkApi.getUsersList.pending, handlePending)
            .addCase(chatThnkApi.getUsersList.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.users = action.payload;
            })
            .addCase(chatThnkApi.getUsersList.rejected, handleRejected)
            .addCase(chatThnkApi.getActiveChatMessages.pending, handlePending)
            .addCase(chatThnkApi.getActiveChatMessages.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.activeChatMessage = action.payload;
            })
            .addCase(chatThnkApi.getActiveChatMessages.rejected, handleRejected);
    }
})

export default chatSlice.reducer
export const { setChat, setSocket } = chatSlice.actions

function filterChatList(data) {
    // console.log(data);
    return data.map((chats) => {
        let profileName;
        if (!chats?.isGroupChat) {
            const { first_name, last_name, profile_photo, } =
                chats?.participants[0];
            profileName = {
                name: first_name + " " + last_name,
                profile: profile_photo,
                chatId: chats?.id,
                first_name,
                last_name,
                last_message: chats?.messages
            };
        } else {
            profileName = {
                name: chats.name,
                profile: chats.profile,
                chatId: chats?.id,
                last_message: chats?.messages
            };
        }
        return profileName;
    })
}

const filterById = (chats, id) => {
    return chats.find(chat => chat.id === id);
};