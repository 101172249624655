import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email_address: Yup.string()
    .email("Email address must be a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
  // .matches(/[A-Z]/, "Password must contain atleast one uppercase")
  // .matches(/[a-z]/, "Password must contain atleast one lowercase")
  // .matches(/[@#&]/, "Password must contain special character @,#,&")
  // .min(8, "password must be at least 8 characters"),
});
export const forgetPassword = Yup.object().shape({
  password: Yup
    .string()
    .required("Password is required")
    .matches(/[A-Z]/, "Password must contain atleast one uppercase")
    .matches(/[a-z]/, "Password must contain atleast one lowercase")
    .matches(/[@#&]/, "Password must contain special character @,#,&")
    .min(8, "password must be at least 8 characters"),
  confirmPassword: Yup
    .string()
    .required("Confirm Password is required")
    .matches(/[A-Z]/, "Password must contain atleast one uppercase")
    .matches(/[a-z]/, "Password must contain atleast one lowercase")
    .matches(/[@#&]/, "Password must contain special character @,#,&")
    .min(8, "password must be at least 8 characters")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});
