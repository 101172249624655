import { Paper } from '@mui/material'
import { Box } from '@mui/system'
import React,{useState} from 'react'
import GlobalTable from '../../GlobalTable/GlobalTable'
import UserProfile from '../../GlobalTable/UserProfile'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from 'react-router-dom'
import { AiOutlineLeft } from 'react-icons/ai'

const OpretingSystem = () => {
    const [searchText, setSearchText] = useState("");

    const data = [
        {
          id: 1,
          email: "Ameliajones@gmail.com",
          name: "Amelia Jones",
          phone: "1257845112",
          subscription: "Windows",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 2,
          email: "Ameliajones@gmail.com",
          name: "Emma Smith",
          phone: "1257845112",
          subscription: "Mac",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 3,
          email: "diptimhabadi@gmail.com",
          name: "Dipti Mhabadi",
          phone: "1257845112",
          subscription: "ios",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 4,
          email: "dipalikharat@gmail.com",
          name: "Dipali Kharat",
          phone: "1257845112",
          subscription: "Windows",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 5,
          email: "Ameliajones@gmail.com",
          name: "Emma Smith",
          phone: "1257845112",
          subscription: "Mac",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 6,
          email: "diptimhabadi@gmail.com",
          name: "Dipti Mhabadi",
          phone: "1257845112",
          subscription: "Android",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 7,
          email: "dipalikharat@gmail.com",
          name: "Dipali Kharat",
          phone: "1257845112",
          subscription: "ios",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
        {
          id: 8,
          email: "dipalikharat@gmail.com",
          name: "Dipali Kharat",
          phone: "1257845112",
          subscription: "Mac",
          profilePic:
            "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHVzZXIlMjBsb2dvfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60",
        },
    ];
    
    const columns = [
      {
        name: "Name",
        cell: (row) => <UserProfile data={row} />,
        sortable: true,
        width: "25%",
      },
      {
        name: "Email Address",
        selector: (row) => row.email,
        sortable: true,
        width: "25%",
      },
      {
        name: "Phone",
        selector: (row) => row.phone,
        sortable: true,
        width: "25%",
      },
      {
        name: "Operating System",
        selector: (row) => row.subscription,
        sortable: true,
        width: "25%",
      },
    ];

  return (
    <div className='OpretingSystem p-3'>
      <Paper
          elevation={0}
        >
          <Box
            className=" custom-panel  border-bottom rounded-top-3 p-3"
            sx={{
              borderBottom: 0,
              padding: 0,
              backgroundColor: "#fff",
              width: "100%",
            }}
          >
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <Link to='/reports' className='mb-2 d-flex align-items-center gap-1 text-decoration-none'>
                <AiOutlineLeft className='pointer' size={18} />
                <label className='pointer'>Reports</label>
              </Link>
              <div className='calendar'>
                  <CalendarMonthIcon />
              </div>
            </div>
              <input
                className="form-control w-25 cad-text-medium"
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search..."
              />
              <GlobalTable
                  data={data}
                  columns={columns}
                  searchText={searchText}
                  selectableRows={false}
              />
          </Box>
      </Paper>
    </div>
  )
}

export default OpretingSystem
