import { Button, Chip, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supportGetAll } from "../../features/support/support.slice";
import { DeleteModel } from "../../Components/Modals/DeleteModel/DeleteModel";
// import StickyHeadTable from "../../Components/Table/StickyHeadTable";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/converter/date.converter";
import ViewModal from "./ViewModal";
import { logDOM } from "@testing-library/react";
import TableData from "../../Components/TableData";
import { AiOutlineDelete } from "react-icons/ai";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import * as themeColors from "../../utils/Theme/Colors";
import { contactThunkAPI, nextPage } from "../../features/contact/contact.slice";
import ReplyIcon from '@mui/icons-material/Reply';
import Mail from "./Mail";
import ContactReplyModal from "../../Components/Modals/contact/contact.reply.modal";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";

const Contact = () => {

    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dispatch(contactThunkAPI.getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(nextPage(1));
        dispatch(contactThunkAPI.getAll(searchTerm)); // Pass search term to getAll thunk
    }, [dispatch, searchTerm]);

    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

    const [open, setOpen] = useState(false);
    const [viewModalOpen, setviewModalOpen] = useState(false);
    const [contactId, setContactId] = useState()

    const { currentPage, totalPages, rows, isLoading } = useSelector((state) => state?.contact);


    const openModalview = (id) => {
        setviewModalOpen(true);
        setContactId(id)
    };

    const closeModalview = () => {
        setviewModalOpen(false);
    };

    const cellConfig = [
        { dataKey: "sr", align: "left", width: "5%" },
        { dataKey: "name", align: "left", width: "25%" },
        { dataKey: "email_address", align: "left", width: "25%" },
        { dataKey: "replied_status", align: "left", width: "20%" },
        { dataKey: "reply", align: "left", width: '5%' },
        { dataKey: "createdAt", align: "left", width: '25%' },
    ];

    const tableHeadRowCell = [
        {
            title: "#",
            width: "5%",
            align: "left",
        },
        {
            title: "Name",
            width: "25%",
            align: "left",
        },
        {
            title: "Email Address",
            width: "25%",
            align: "left",
        },
        {
            title: "Replied Status",
            width: "20%",
            align: "left",
        },
        {
            title: "Reply",
            width: "5%",
            align: "left",
        },
        {
            title: "Create At",
            width: "25%",
            align: "left",
        },
    ];

    const [mouseEntered, setMouseEntered] = useState(false);
    const [mouseEnteredId, setMouseEnteredId] = useState("");

    const extractedDataArray = rows?.map((user, index) => {
        const extractedData = {
            id: user.id,
            sr: (currentPage - 1) * 10 + index + 1,
            name: user.name,
            email_address: user.email,
            replied_status: user?.isReplied === true ? (
                <Chip label="Replied" color="success" variant="outlined" />
            ) : (
                <Chip label="Not Replied" sx={{ color: 'red', borderColor: 'red' }} variant="outlined" />
            ),
            reply:
                <div className="d-flex align-items-center justify-content-start">
                    <IconButton className="text-primary" onClick={() => openModalview(user?.id)}>
                        <ReplyIcon />
                    </IconButton>
                </div>,
            createdAt: formatDate(user?.createdAt)
        };
        return extractedData;
    });

    const handlePagination = (value) => {
        dispatch(nextPage(value));
        dispatch(contactThunkAPI.getAll());
    }


    const handleViewOpen = (id) => () => {
        // alert(id);
        openModalview(id)
    }

    return (
        <div
            style={{
                height: "87vh",
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
                // transition:"0.3s"
            }}
            className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start p-3"
        >
            <div className="w-100">
                <div className='row'>
                    <div className="col-3 d-flex align-items-center gap-1 mt-1 mb-2">
                        <SearchInput searchValue={searchTerm} searchFucntion={(e) => setSearchTerm(e.target.value)} />
                        <Refresher
                            isLoading={isLoading}
                            handleRefresh={() => dispatch(contactThunkAPI.getAll())}
                        />
                    </div>
                </div>
            </div>

            <TableData
                tableHeadRowCell={tableHeadRowCell}
                extractedDataArray={extractedDataArray}
                cellConfig={cellConfig}
                setMouseEnteredId={setMouseEnteredId}
                setMouseEntered={setMouseEntered}
                handlePagination={handlePagination}
                currentPage={currentPage}
                totalPages={totalPages}
                isLoading={isLoading}
                handleViewOpen={handleViewOpen}
            />
            {viewModalOpen &&
                <ContactReplyModal
                    id={contactId}
                    handleClose={closeModalview}
                    open={viewModalOpen}
                />
            }

        </div>
    );
}

export default Contact;