import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { supportTicketService } from "../../service/endpoints";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const supportGetAll = createAsyncThunk("support/getAll", async (searchTerm, { getState }) => {
  try {
    const { size, currentPage } = getState().support
    const params = getRequestParams(searchTerm, currentPage, size)
    // console.log(searchTerm, currentPage, size);
    const res = await supportTicketService.get(params);
    if (res.status === 200) return res.data;
    // rejectWithValue(res.data);
  } catch (error) {
    console.log(error);
  }
}
);

export const supportGetById = createAsyncThunk("support/getById", async (arge, { rejectWithValue }) => {
  try {
    const res = await supportTicketService.getById(arge);
    if (res.status === 200) return res.data;
    rejectWithValue(res.data);
  } catch (error) {
    console.log(error);
  }
}
);

const supportSlice = createSlice({
  name: "support",
  initialState: {
    isLoading: true,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    rows: [],
    dataById: {
      id: undefined,
      title: undefined,
      description: undefined,
      updatedAt: undefined,
      createdAt: undefined,
      status: undefined,
      priority: undefined,
      ticket_message: [],
      dataLoading: true
    },
    id: null,
    size: 10,
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    search: null,
    searchHistory: [],
  },
  reducers: {
    nextPage: (state, action) => {
      state.currentPage = action.payload
    },
    previousPage: (state, action) => {
      state.currentPage = state.currentPage - 1
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(supportGetAll.pending, handlePending)
      .addCase(supportGetAll.fulfilled, (state, action) => {
        // console.log(action.payload);
        handleFulfilled(state, action);
        state.data = action?.payload?.data;
        state.rows = action?.payload?.data?.rows;
        state.totalItems = action?.payload?.data?.totalItems;
        state.totalPages = action?.payload?.data?.totalPages;
        state.currentPage = action?.payload?.data?.currentPage;
        state.isLoading = false;
      })
      .addCase(supportGetAll.rejected, handleRejected);
    builder
      .addCase(supportGetById.pending, handlePending)
      .addCase(supportGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        Object.assign(state.dataById, action.payload);
        state.dataById.createdAt = action?.payload?.createdAt;
        state.dataById.ticket_message = action?.payload.data?.ticket_message.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt)
        });
        state.dataById.dataLoading = false;

      })
      .addCase(supportGetById.rejected, handleRejected);
  },
});

export default supportSlice.reducer;
export const { nextPage, previousPage } = supportSlice.actions;
