import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useSelector } from "react-redux";

const Refresher = ({ handleRefresh, isLoading}) => {
  const {theme, themeColorsObject} = useSelector((state) => state.theme);
    
  return (
    <span
      style={{ padding: "0.3rem 0.3rem", color: themeColorsObject.textColor }}
      className="pointer action-hover rounded-2 "
      onClick={handleRefresh}
    >
      <AutorenewIcon className={`cad-text-xlarge ${isLoading ? "refresher" : ""}`} />
    </span>
  );
};

export default Refresher;
