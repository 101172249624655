import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import { privacyService } from "../../service/endpoints";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import produce from "immer";

export const privacyGetAll = createAsyncThunk(
  "privacy/getAll",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await privacyService.get(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const privacyGetById = createAsyncThunk(
  "privacy/getById",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await privacyService.getById(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const privacyUpdate = createAsyncThunk(
  "privacy/update",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await privacyService.update(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const privacyDelete = createAsyncThunk(
  "privacy/delete",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await privacyService.delete(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);

const privacySlice = createSlice({
  name: "privacy",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    dataById: {},
    id: null,
    size: 10,
    page: 1,
    totalPage: 1,
    search: null,
    searchHistory: [],
  },
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    resetGetIdData: (state) => {
      state.dataById = {};
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(privacyGetAll.pending, handlePending)
      .addCase(privacyGetAll.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(privacyGetAll.rejected, handleRejected);
    builder
      .addCase(privacyGetById.pending, handlePending)
      .addCase(privacyGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.dataById = action.payload.data;
      })
      .addCase(privacyGetById.rejected, handleRejected);
    builder
      .addCase(privacyUpdate.pending, handlePending)
      .addCase(privacyUpdate.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(privacyUpdate.rejected, handleRejected);
    builder
      .addCase(privacyDelete.pending, handlePending)
      .addCase(privacyDelete.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(privacyDelete.rejected, handleRejected);
  },
});

export default privacySlice.reducer;
export const { closeError, resetGetIdData } = privacySlice.actions;
