import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../../PrimaryButton";
import { AiOutlineLeft } from "react-icons/ai";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteModel } from "../../Modals/DeleteModel/DeleteModel";
import EditIcon from "@mui/icons-material/Edit";
import { privacyDelete, privacyGetAll } from "../../../features/cms/privacySlice";
import * as themeColors from "../../../utils/Theme/Colors";
import { enqueueSnackbar } from "notistack";

const PrivacyPolicy = () => {
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const dispatch = useDispatch();
  const { data, isLoading, isError } = useSelector((state) => state.privacy);

  useEffect(() => {
    dispatch(privacyGetAll());
  }, [dispatch]);


  const handleDelete = () => {
    // Dispatch delete action
    dispatch(privacyDelete(deleteId))
      .then(() => {
        // Once delete action is complete, dispatch privacyGetAll to update the data
        dispatch(privacyGetAll());
        // Close the modal
        setOpen(false);
        enqueueSnackbar("Successfully deleted", { variant: "error" });
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error deleting privacy:', error);
      });
  };


  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        overflow: 'scroll'
        // transition:"0.3s"
      }}
      className="w-100  mb-4 rounded-3 mt-1 cad-shadow p-3 "
    >
      <div className="d-flex  justify-content-between mb-4">
        <Link
          to="/cms"
          className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
        >
          <AiOutlineLeft className="pointer" size={18} />
          <label className="pointer">Privacy Policy</label>
        </Link>
        {data?.length < 1 && (
          <PrimaryButton
            icon={<AddIcon color="white" />}
            path="/privacyadd"
            padding="0.2rem 1rem"
            title="Add Details"
          />
        )}
      </div>
      <div className="terms">
        {isLoading
          ? Array.from({ length: 4 }).map((_, index) => (
            <div className="mb-3" key={index}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width="50%" />
            </div>
          ))
          : data.map((eachTerms) => (
            <div className="content-data" key={eachTerms.id}>
              <div dangerouslySetInnerHTML={{ __html: eachTerms.content }} />
              <div className="d-flex">
                <Link to={`/privacyedit/${eachTerms.id}`}>
                  {" "}
                  <EditIcon />
                </Link>
                <div
                  onClick={() => [setDeleteId(eachTerms.id), setOpen(true)]}
                >
                  <DeleteIcon style={{ color: "#e30404" }} />
                </div>
              </div>
            </div>
          ))}
      </div>
      <DeleteModel
        open={open}
        handleClose={() => setOpen(false)}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default PrivacyPolicy;
