import React, { useEffect, useState } from "react";
import "../ChatBox/ChatBox.css";
import ChatBox from "../ChatBox/ChatBox";
import { Button, Typography } from "@mui/material";
import { Avatar } from "@mui/material";
import emptyChatBg from "../../Images/emptyChatBg.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { createMessage } from "../../service/chat.service";
import { useDispatch, useSelector } from "react-redux";
import { chatThnkApi } from "../../features/chat/chats.slice";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import { Socket } from "socket.io-client";
import AddIcon from "@mui/icons-material/Add";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const domain = process.env.REACT_APP_BACKEND_BASEURL

const ChatsRight = () => {
  const [sentMessage, setSentMessage] = useState("")
  const [isTyping, setIsTyping] = useState({
    id: null,
    typing: false,
  })
  const { themeColorsObject } = useSelector((state) => state.theme);
  const chat = useSelector((state) => state?.chat);
  const isActiveChatEmpty = chat?.activeChat && Object.keys(chat?.activeChat).length === 0;
  // console.log(chat);
  const dispatch = useDispatch()

  // const handleSend = async () => {
  //   const formData = new FormData();
  //   const addedKeys = new Set();

  //   if (sentMessage) {
  //     formData.append("content", sentMessage);
  //     addedKeys.add("content");
  //     setSentMessage("");
  //   }

  //   if (Array.isArray(selectedFile) && selectedFile.length > 0) {
  //     selectedFile.forEach((file, index) => {
  //       formData.append(`attachment[${index}]`, file);
  //       addedKeys.add(`attachment[${index}]`);
  //       setSelectedFile(null)
  //     });
  //   }

  //   // Print the FormData contents
  //   formData.forEach((value, key) => {
  //     console.log(`${key}:`, value);
  //   });

  //   // Check if there is any data to send before calling the API
  //   if (addedKeys.size > 0) {
  //     try {
  //       await createMessage(chat.activeChat?.chatId, formData);
  //       dispatch(chatThnkApi.getActiveChatMessages(chat.activeChat?.chatId));
  //       dispatch(chatThnkApi.getChatList());
  //     } catch (error) {
  //       // Handle errors here
  //       console.error("Error sending message:", error);
  //     }
  //   } else {
  //     console.log("No data to send.");
  //   }
  // };


  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleTyping = () => {
    if (chat?.socket instanceof Socket) {
      chat?.socket.emit("typing", chat.activeChat?.chatId);
    }
  };

  useEffect(() => {
    if (chat?.socket instanceof Socket && chat.activeChat && chat.activeChat.chatId) {
      chat?.socket.on("typing", (data) => {
        if (chat?.activeChat?.chatId === data) {
          setIsTyping({ id: data, typing: true });
          setTimeout(() => {
            setIsTyping({ id: null, typing: false });
          }, 3000); // Adjust the duration as needed
        }
      });
    }
  }, [chat.activeChat, chat?.socket]);


  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map((file, index) => ({
      file,
      id: `${index}-${new Date().getTime()}-${Math.random()}`,
    }));

    const fileDataArray = [];

    newFiles.forEach(({ file, id }) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        fileDataArray.push({ name: file.name, dataURL: reader.result, id });

        if (fileDataArray.length === newFiles.length) {
          setSelectedFile((prevFiles) => {
            if (!prevFiles) prevFiles = []; // Ensure prevFiles is not null
            // Filter out duplicates
            const filteredFileDataArray = fileDataArray.filter((newFile) =>
              !prevFiles.some((prevFile) => prevFile.name === newFile.name && prevFile.dataURL === newFile.dataURL)
            );
            return [...prevFiles, ...filteredFileDataArray];
          });
        }
      };
      reader.readAsDataURL(file);
    });
  };

  // Helper function to convert dataURL to File
  const dataURLtoFile = (dataurl, filename) => {
    const [mimePart, dataPart] = dataurl.split(',');
    const mime = mimePart.match(/:(.*?);/)[1];
    const bstr = atob(dataPart);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  // Optimized handleSend function using DSA
  const handleSend = async () => {
    const formData = new FormData();
    const addedFilesSet = new Set();
    let hasData = false;

    if (sentMessage) {
      formData.append("content", sentMessage);
      setSentMessage("");
      hasData = true;
    }

    if (Array.isArray(selectedFile) && selectedFile.length > 0) {
      selectedFile.forEach(({ dataURL, name }, index) => {
        // Use a combination of name and dataURL length as a unique identifier
        const fileKey = `${name}-${dataURL.length}`;
        if (!addedFilesSet.has(fileKey)) {
          const fileObj = dataURLtoFile(dataURL, name);
          formData.append(`attachment`, fileObj);
          addedFilesSet.add(fileKey);
          hasData = true;
        }
      });
    }

    if (hasData) {
      try {
        await createMessage(chat.activeChat?.chatId, formData);
        setSelectedFile(null)
        dispatch(chatThnkApi.getActiveChatMessages(chat.activeChat?.chatId));
        dispatch(chatThnkApi.getChatList());
      } catch (error) {
        console.error("Error sending message:", error);
      }
    } else {
      console.log("No data to send.");
    }
  };


  const handleRemoveFile = (idToRemove) => () => {
    // alert(idToRemove)
    setSelectedFile((prevFiles) => prevFiles.filter(({ id }) => id !== idToRemove));
  };



  return (
    <div className="row m-0  flex-lg-nowrap overflow-hidden h-100">
      {isActiveChatEmpty ? (
        <div className="w-100 h-100 d-flex justify-content-center gap-2 align-items-center flex-column">
          <img width={260} src={emptyChatBg} alt="" />
          <span className="cad-text-large cad-fw-600 mt-3">
            Your chats will show up here.
          </span>
          <span className="cad-text-large text-secondary cad-fw-500 w-50 text-center">
            Get started by tapping the new chat button here or on someone's
            profile
          </span>

          <Button
            // onClick={handleOpenNewChat}
            variant="contained"
            className="mt-2"
            style={{ width: "30%" }}
          >
            <AddCircleOutlineIcon className="me-2" /> Start Chatting
          </Button>
        </div>
      ) : (
        <div className="col-lg-12 h-100 col-12 p-0 pb-5 position-relative">
          <div
            className="profile  border-bottom p-2 ps-3 d-flex gap-2"
            style={{
              height: "12%"
            }}
          >
            <div
              style={{ position: "relative" }}
              className="d-flex align-items-start flex-column justify-content-center"
            >
              <Avatar alt={chat?.activeChat?.name} src={`${domain}/${chat?.activeChat?.profile}`} />
            </div>

            <div className="  d-flex align-items-start flex-column justify-content-center">
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                }}
                variant="h6"
              >
                {chat?.activeChat?.name}
              </Typography>
              <Typography
                variant="p"
                style={{ fontSize: "12px", color: "#4A5057" }}
              >
                {isTyping.id === chat?.activeChat?.chatId && isTyping?.typing &&
                  <div className={`bubble mb-3 ${isTyping ? "d-flex" : "d-none"}`}>
                    <div className="dot"></div>
                    <div className="dot"></div>
                    <div className="dot"></div>
                  </div>
                }
              </Typography>
            </div>
          </div>

          <ChatBox />

          {Array.isArray(selectedFile) && selectedFile.length > 0 && (
            <div className="file-attachment-cnt rounded-2 p-3 d-flex gap-3 align-items-center">
              {selectedFile
                ? selectedFile?.map((eachFile, i) => (
                  <div style={{ position: "relative" }} key={i}>
                    <img
                      className="file-attachment"
                      src={eachFile?.dataURL}
                      alt=""
                    />
                    <span className="pointer" onClick={handleRemoveFile(eachFile.id)}>
                      <HighlightOffIcon className="action-hover pointer bg-white text-danger" style={{
                        position: "absolute",
                        top: -10,
                        right: -10,
                        fontSize: 20,
                        borderRadius: 20,
                        padding: 1.5
                      }} /></span>
                  </div>
                ))
                : ""}
              {selectedFile ? (
                <span
                  style={{ backgroundColor: "#ecf0f1" }}
                  className="p-3 rounded-2 text-center bg-primary text-white pointer"
                >
                  <label htmlFor="file-upload" className=" cad-fw-700 pointer">
                    <AddIcon className="color-primary" />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple
                  />
                </span>
              ) : (
                ""
              )}
            </div>
          )}

          <div className="chat-input pt-2 ">
            <input
              type="text"
              className={`form-control cad-text-medium w-100 p-3 ps-3 pe-5 ${themeColorsObject.themed_input_class}`}
              placeholder="Message..."
              value={sentMessage}
              onChange={(e) => setSentMessage(e.target.value)}
              onKeyDown={handleKeyPress}
              maxLength={1000}
              onInput={handleTyping}
            />
            {/* <h6 onClick={handleSend} className="chat-sentBtn cad-fw-600">
              send
            </h6> */}



            <label htmlFor="file-upload" className="attchBtn cad-fw-700">
              <InsertLinkOutlinedIcon />
            </label>
            <input
              // onPaste={() => console.log("paste")}
              id="file-upload"
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              multiple
            />
            <SendOutlinedIcon onClick={handleSend} className="chat-sentBtn" />
          </div>
        </div>
      )
      }
    </div >
  );
};

export default ChatsRight;
