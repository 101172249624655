import api from "./api.service";


export const getContact = async () => {
    try {
        return await api.get(`/contactUs/admin/contactUs`);
      } catch (error) {
        return error.response;
      }
}

// export const getContactdetails = async (id) => {
//   try {
//       return await api.get(`/contactUs/agent/contactUs/${id}`);
//     } catch (error) {
//       return error.response;
//     }
// }

