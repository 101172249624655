import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faqDelete, faqGetAll } from "../../../features/faq/faqSlice";
import { Box, Stack } from "@mui/system";
import { Button, Chip, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import StickyHeadTable from "../../Table/StickyHeadTable";
import { formatDate } from "../../../utils/converter/date.converter";
import { DeleteModel } from "../../Modals/DeleteModel/DeleteModel";
import * as themeColors from "../../../utils/Theme/Colors";
import { enqueueSnackbar } from "notistack";
import { AiOutlineLeft } from "react-icons/ai";

const Faq = () => {
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { totalItems, rows } = useSelector((state) => state?.faq);

  useEffect(() => {
    dispatch(faqGetAll());
  }, [dispatch]);

  const columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "question", headerName: "Question", width: 130 },
    // { field: "answer", headerName: "Answer", width: 130 },
    {
      field: "category",
      headerName: "Category",
      width: 70,
    },
    { field: "createdAt", headerName: "Created At", width: 130 },
    { field: "action", headerName: "Actions", width: 130 },
  ];

  const _rows = rows.map((row, index) => ({
    id: row.id,
    question: row.question,
    // answer: row.answer,
    category: (
      <Stack
        spacing={{ xs: 1, sm: 2 }}
        direction="row"
        useFlexGap
        flexWrap="wrap"
      >
        {row.categories.map((item) => (
          <Chip
            key={item.id}
            label={item.title}
            variant="outlined"
            color="info"
            size="small"
          />
        ))}
      </Stack>
    ),
    createdAt: formatDate(row.createdAt),
    action: (
      <>
        <Button onClick={() => handleEditClick(row.id)} color="primary">
          Edit
        </Button>
        <Button onClick={() => handleDeleteClick(row.id)} color="secondary">
          Delete
        </Button>
      </>
    ),
  }));

  const handleEditClick = (id) => {
    navigate(`/editFaq/${id}`);
    // Handle edit action based on the row id
    // console.log(`Edit clicked for ID: ${id}`);
  };

  const handleDeleteClick = (id) => {
    // Handle delete action based on the row id
    // console.log(`Delete clicked for ID: ${id}`);
    setDeleteId(id);
    setOpen(true);
  };

  // const handleDelete = async () => {
  //   dispatch(faqDelete(deleteId));
  //   setOpen(false);
  //   // dispatch(faqGetAll());
  // };

  const handleDelete = () => {
    // Dispatch delete action
    dispatch(faqDelete(deleteId))
      .then(() => {
        // Once delete action is complete, dispatch termsGetAll to update the data
        dispatch(faqGetAll());
        // Close the modal
        setOpen(false);
        enqueueSnackbar("Successfully deleted", { variant: "error" });
      })
      .catch(error => {
        // Handle any errors here
        console.error('Error deleting term:', error);
      });
  };

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        // transition:"0.3s"
      }}
      className="w-100  mb-4 rounded-3 mt-1 cad-shadow p-3 "
    >
      <DeleteModel
        open={open}
        handleClose={() => setOpen(false)}
        handleDelete={handleDelete}
      />
      {/* <Paper sx={{ p: 2 }}> */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Link
          to="/cms"
          className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
        >
          <AiOutlineLeft className="pointer me-1" size={18} />
          <label className="pointer">FAQ</label>
        </Link>
        <Button variant="outlined" onClick={() => navigate("/addFaq")}>
          Add FAQ
        </Button>
      </Stack>
      <StickyHeadTable
        columns={columns}
        rows={_rows}
        defaultRowsPerPage={5}
        defaultPage={0}
        totalRows={totalItems}
      />
      {/* </Paper> */}
    </div>
  );
};

export default Faq;
