import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { planSchema } from "../../../utils/schema/plan.schema";
import { AiOutlineLeft } from "react-icons/ai";
import InfoIcon from "@mui/icons-material/Info";
import { enqueueSnackbar } from "notistack";
import {
  packageSubscription,
  planSubscription,
} from "../../../service/endpoints";

const AddPalnDrawer = ({ open, onClose, packageID }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(planSchema) });

  const onSubmit = async (value) => {
    console.log(value);
    try {
      value.package_xid = packageID;
      const response = await planSubscription.create(value);
      if (response?.status === 201) {
        enqueueSnackbar(response?.data?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
      if (error.data && error.data.error) {
        enqueueSnackbar(error?.data?.error?.message, {
          variant: "error",
        });
      }
    }
    reset();
    onClose(false);
  };
  const [isToggle, setIsToggle] = useState(true);

  // List of country currencies
  const currency = [
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
    { value: "CAD", label: "CAD" },
  ];

  const handleToggle = (event) => {
    setIsToggle(event.target.checked);
  };
  const handleChangeMonthlyPrice = (e) => {
    if (isToggle) {
      const { discount } = getValues();
      const discountedPrice = (1 - discount / 100) * (e.target.value * 12);
      console.log((1 - discount / 100) * (e.target.value * 12));
      setValue("annual_price", discountedPrice);
    }
  };
  const handleChangeDiscount = (e) => {
    if (isToggle) {
      const { monthly_price } = getValues();
      const discountedPrice = (1 - e.target.value / 100) * (monthly_price * 12);
      console.log((1 - e.target.value / 100) * (monthly_price * 12));
      setValue("annual_price", discountedPrice);
    }
  };
  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <React.Fragment>
              <Box p={2}>
                <Button
                  className="mb-4"
                  variant="text"
                  onClick={() => onClose(false)}
                >
                  <AiOutlineLeft className="pointer" size={18} />
                  <label className="pointer">Packages</label>
                </Button>
                <Box>
                  <Grid spacing={2}>
                    <Grid xs={5} sx={{ m: 2 }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="currency-label">Currency</InputLabel>
                        <Controller
                          name="currency"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              labelId="currency-label"
                              error={!!errors.currency}
                              fullWidth
                              label="currency"
                            >
                              {currency.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        />
                        {errors.currency && (
                          <span style={{ color: "red", fontSize: "0.75rem" }}>
                            {errors.currency.message}
                          </span>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid xs={5} sx={{ m: 2 }}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          error={errors.monthly_price ? true : false}
                          label="Monthly price"
                          helperText={
                            errors.monthly_price
                              ? errors.monthly_price.message
                              : ""
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                /month
                              </InputAdornment>
                            ),
                          }}
                          {...register("monthly_price")}
                          onChange={handleChangeMonthlyPrice}
                        />
                      </FormControl>
                    </Grid>
                    <Grid xs={5}>
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={isToggle}
                            onChange={handleToggle}
                          />
                        }
                        label="Auto-calculate an annual price"
                        labelPlacement="start"
                      />
                    </Grid>
                    {isToggle && (
                      <Grid xs={5} sx={{ m: 2 }}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            error={errors.discount ? true : false}
                            label="Discount"
                            helperText={
                              errors.discount ? errors.discount.message : ""
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                            }}
                            defaultValue={0}
                            {...register("discount")}
                            onChange={handleChangeDiscount}
                          />
                        </FormControl>
                      </Grid>
                    )}
                    {/* Annual */}
                    <Grid xs={5} sx={{ m: 2 }}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          error={errors.annual_price ? true : false}
                          label="Annual price"
                          helperText={
                            errors.annual_price
                              ? errors.annual_price.message
                              : ""
                          }
                          disabled={isToggle}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                /year
                              </InputAdornment>
                            ),
                            readOnly: isToggle,
                          }}
                          defaultValue={0}
                          {...register("annual_price")}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Box sx={{ mb: 3, mt: 1 }}>
                    <Stack spacing={2} direction="row">
                      <Button variant="text" onClick={() => onClose(false)}>
                        Back
                      </Button>
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Box>
            </React.Fragment>
          </Box>
        </form>
      </Drawer>
    </React.Fragment>
  );
};

export default AddPalnDrawer;
