import React, { useEffect, useState } from 'react'
import TableData from '../../Components/TableData'
import { useDispatch, useSelector, } from 'react-redux';
import { TbArchiveOff } from "react-icons/tb";
import UserArchiveModal from '../../Components/Modals/users/user.archive.modal';
import { nextPage, userArchiveThunkAPI } from '../../features/users/user.archive.slice';
import Refresher from '../../Components/Refresher';
import SearchInput from '../../Components/SearchInput/SearchInput';
import UserName from './UserName';
import AdvanceFilter from '../../Components/AdvanceFilter/AdvanceFilter';

const ArchiveUsers = () => {

    const { totalPages, rows, currentPage, isLoading } = useSelector((state) => state?.userArchive);

    const dispatch = useDispatch();
    const [mouseEntered, setMouseEntered] = useState(false);
    const [mouseEnteredId, setMouseEnteredId] = useState("")
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dispatch(userArchiveThunkAPI.getArchiveList());
    }, [dispatch]);

    useEffect(() => {
        dispatch(nextPage(1));
        dispatch(userArchiveThunkAPI.getArchiveList(searchTerm)); // Pass search term to getAll thunk
    }, [dispatch, searchTerm]);

    // trigger modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const openModal = (userId) => {
        setIsModalOpen(true);
        setSelectedUserId(userId);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUserId(null)
    };

    const [checkboxValues, setCheckboxValues] = useState({
        "sr_no": true,
        name: true,
        'Email address': true,
        "Verfied user": false
    });
    const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
    const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);

    const cellConfig = [
        { dataKey: "sr_no", align: "start", width: 15 },
        { dataKey: "name", align: "left", width: 25 },
        { dataKey: "email_address", align: "left", width: 20 },
        { dataKey: "verfied_user", align: "left", width: 15 },
        { dataKey: "phone_number", align: "left", width: 20 },
    ];

    const tableHeadRowCell = [
        {
            title: "SR NO.",
            width: "10%",
            align: "start",
        },
        {
            title: "Name",
            width: "25%",
            align: "left",
        },
        {
            title: "Email address",
            width: "20%",
            align: "left",
        },
        {
            title: "Verified user",
            width: "15%",
            align: "left",
        },
        {
            title: "Phone number",
            width: "20%",
            align: "left",
        }
    ];

    const extractedDataArray = rows?.map((row, index) => {
        const extractedData = {
            id: row?.id,
            sr_no: (currentPage - 1) * 10 + index + 1,
            name: <UserName row={row} />,
            email_address: row?.email_address,
            verfied_user: row?.verfied_user == false ? 'No' : 'Yes',
            phone_number: mouseEntered && mouseEnteredId === row?.id ? (
                <div className="d-flex align-items-center justify-content-start">
                    <span style={{ borderRadius: "18%" }} className="p-2 action-hover text-primary">
                        <TbArchiveOff
                            onClick={() => openModal(row?.id)}
                            style={{
                                fontSize: "20px",
                            }}
                        />
                    </span>
                </div>
            ) : (
                row?.phone_number // need values from backend
            ),

        }
        return extractedData;
    });

    const handlePagination = (value) => {
        // console.log(value);
        dispatch(nextPage(value));
        dispatch(userArchiveThunkAPI.getArchiveList());
    };


    return (
        <div className='ps-3 pe-3'>
            <div className='row'>
                <div className="col-3 d-flex align-items-center gap-1 mt-3">
                    <SearchInput
                        searchValue={searchTerm}
                        searchFucntion={(e) => setSearchTerm(e.target.value)}
                    />
                    <Refresher
                        isLoading={isLoading}
                        handleRefresh={() => dispatch(userArchiveThunkAPI.getArchiveList())}
                    />
                </div>
                <div className="col-9 d-flex align-items-end justify-content-end mt-3">
                    <AdvanceFilter
                        bottom="-169px"
                        left="-73px"
                        cellConfig={cellConfig}
                        tableHeadRowCell={tableHeadRowCell}
                        setFilteredCellConfig={setFilteredCellConfigg}
                        setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
                        checkboxValues={checkboxValues}
                        setCheckboxValues={setCheckboxValues}
                    />
                </div>
            </div>
            <TableData
                tableHeadRowCell={filteredTableHeadRowCell}
                extractedDataArray={extractedDataArray}
                cellConfig={filteredCellConfig}
                setMouseEnteredId={setMouseEnteredId}
                setMouseEntered={setMouseEntered}
                totalPages={totalPages}
                handlePagination={handlePagination}
                isLoading={isLoading}
            />

            {isModalOpen && (
                <UserArchiveModal
                    handleClose={closeModal}
                    open={isModalOpen}
                    id={selectedUserId}
                />
            )}
        </div>
    )
}

export default ArchiveUsers