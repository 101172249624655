import { Button, Chip, Paper, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { supportGetAll } from "../../features/support/support.slice";
import { DeleteModel } from "../../Components/Modals/DeleteModel/DeleteModel";
import StickyHeadTable from "../../Components/Table/StickyHeadTable";
import { useNavigate } from "react-router-dom";
import * as themeColors from "../../utils/Theme/Colors";
import { formatDate } from "../../utils/converter/date.converter";
import { userThunkAPI } from "../../features/users/user.slice";
import TableData from "../../Components/TableData";

import UserViewModal from "../../Components/Modals/users/user.view.modal";
import PropTypes from "prop-types";
import AllUsers from "./AllUsers";
import ArchiveUsers from "./ArchiveUsers";
import ClosedAccounts from "./ClosedAccounts";
 

// Custom Tab Panel Component
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <section
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box >
                    <div>{children}</div>
                </Box>
            )}
        </section>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Users = () => {
    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

    // tab logic

    // State for handling the active tab
    const [value, setValue] = useState(0);

    // Function to handle tab change
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (

        <div
            style={{
                height: "87vh",
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
                // transition:"0.3s"
            }}
            className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
        >

            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="tags"
                style={{
                    height: "14px",

                }}
            >
                <Tooltip title="Users" placement="top-start">
                    <Tab
                        style={{
                            textTransform: "none", display: "flex",
                            color: themeColorsObject.titleText,
                        }}
                        label="Users"
                        {...a11yProps(0)}
                    />
                </Tooltip>
                <Tooltip title="Archived users" placement="top-start">
                    <Tab
                        style={{ textTransform: "none", display: "flex", color: themeColorsObject.titleText, }}
                        label="Archived users"
                        {...a11yProps(1)}
                    />
                </Tooltip>
                <Tooltip title="Closed Accounts" placement="top-start">
                    <Tab
                        style={{ textTransform: "none", display: "flex", color: themeColorsObject.titleText, }}
                        label="Closed Accounts"
                        {...a11yProps(2)}
                    />
                </Tooltip>
            </Tabs>

            <CustomTabPanel
                className="w-100 h-100  bg-none rounded-bottom-3 border-top tab-scroll custom-panel  h-auto overflow-auto"
                value={value}
                index={0}
            >
                <AllUsers />
            </CustomTabPanel>
            <CustomTabPanel
                className="w-100  bg-none tab-scroll  rounded-bottom-3 border-top overflow-auto custom-panel  h-100"
                value={value}
                index={1}
            >
                <ArchiveUsers />
            </CustomTabPanel>
            <CustomTabPanel
                className="w-100  bg-none tab-scroll  rounded-bottom-3 border-top overflow-auto custom-panel  h-100"
                value={value}
                index={2}
            >
                <ClosedAccounts />
            </CustomTabPanel>

        </div>

    );
}

export default Users;