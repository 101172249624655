import React, { useEffect, useRef, useState, } from "react";
import { convertTimestampToTime } from "../ChatComponent/ChatsLeft";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Skeleton, Typography } from "@mui/material";
import { Socket } from "socket.io-client";
import { chatThnkApi } from "../../features/chat/chats.slice";
import { fileURL } from "../../features/SupportChat/SupportChat";
import PreviewImage from "../ChatComponent/previewImage";
const domain = process.env.REACT_APP_BACKEND_BASEURL

const ChatBox = () => {
  const [isLoading, setIsLoaging] = useState(false)
  const { authInfo } = useSelector((state) => state?.auth);
  const { activeChatMessage, activeChat, socket } = useSelector((state) => state?.chat);
  const [imagePrevopen, setImagePrevOpen] = useState(false);
  const [imagePrevArray, setImagePrevArray] = useState([]);
  const handleImagePrevOpen = () => setImagePrevOpen(true);

  const handleImageClick = (id) => {
    handleImagePrevOpen();

    const filteredObject = activeChatMessage.filter((item) => item.id === id);
    console.log(filteredObject);
    setImagePrevArray(Array.isArray(filteredObject) && filteredObject[0].attachments);
  };

  const chatWindowRef = useRef(null);
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };
  const dispatch = useDispatch()
  useEffect(() => {
    activeChat?.chatId && dispatch(chatThnkApi.getActiveChatMessages(activeChat?.chatId));
    if (socket instanceof Socket && activeChat?.chatId) {
      socket.on("messageReceived", (payload) => {
        console.log(activeChat?.chatId, payload?.chatId, activeChat?.chatId === payload?.chatId);
        if (activeChat?.chatId === payload?.chatId) {
          setIsLoaging(true)
          dispatch(chatThnkApi.getActiveChatMessages(payload?.chatId))
          setIsLoaging(false)
        } else {
          dispatch(chatThnkApi.getChatList())
        }
      })
    }
  }, [activeChat?.chatId, dispatch, socket]);

  useEffect(() => {
    scrollToBottom();
  }, [activeChatMessage]);


  // console.log(isLoading);
  return (
    <div
      ref={chatWindowRef}
      className="chat-screen mb-5 p-3 h-100 w-100 d-flex flex-column overflow-auto"
    >
      {activeChatMessage?.map(({ content, id, sender, createdAt, attachments }, index) => (
        <div
          key={id}
          className={
            sender?.id === authInfo.id
              ? // true
              "sending-msg-bubble-chat-box mb-3 d-flex flex-column"
              : "chat-receiving-msg-bubble mb-4 d-flex flex-column"
          }
        >
          <div
            style={{ position: "absolute", top: "-6px", left: "-49px" }}
          >
            {isLoading
              ? <Skeleton variant="circular">
                <Avatar />
              </Skeleton>
              : sender?.id !== authInfo.id && (
                <Avatar alt={sender?.first_name} src={`${domain}/${sender?.profile_photo}`} />
              )}
          </div>
          {isLoading
            ? <Skeleton Skeleton width="100%">
              <Typography>.....</Typography>
            </Skeleton>
            :
            <>
              <span
                className={`${sender?.id === authInfo.id ? "text-white" : "text-primary"
                  }    cad-text-small cad-fw-500`}
              >
                {sender?.first_name} {sender?.last_name}
              </span>
              {isLoading
                ? <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: '57%' }} />
                </Skeleton>
                : <>
                  {/* <span className="msg">{content}</span> */}
                  <span className="msg-time">
                    {convertTimestampToTime(createdAt)}
                  </span>
                </>
              }
              {isLoading
                ? <Skeleton variant="rectangular" width="100%">
                  <div style={{ paddingTop: '57%' }} />
                </Skeleton>
                : <>
                  <div>
                    <div className="d-flex gap-2 flex-wrap mb-2">
                      {attachments?.map((eachFile) => {
                        let imgSrc = fileURL(eachFile.path);
                        return (
                          <img
                            onClick={() => handleImageClick(id)}
                            key={eachFile.id}
                            className="rounded-3 pointer"
                            src={imgSrc}
                            width={120}
                            height={120}
                            alt="file-img"
                          />
                        );
                      })}
                    </div>

                    <span className="msg">{content}</span>
                    <span className="msg-time">
                      {convertTimestampToTime(createdAt)}
                    </span>
                  </div>
                </>
              }
            </>
          }
        </div>
      ))}
      {/* {isTyping ? (
        <div class="bubble mb-3">
          <div class="dot"></div>
          <div class="dot"></div>
          <div class="dot"></div>
        </div>
      ) : null} */}
      <PreviewImage imagePrevopen={imagePrevopen} setImagePrevOpen={setImagePrevOpen} attachments={imagePrevArray} />
    </div>

  );
};

export default ChatBox;
