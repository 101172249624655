import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUser } from "../../../service/user.service";
// import { getContact } from "../../../service/cms/service.contact";

export const fetchUser = createAsyncThunk("fetchUser", async () => {
  try {
    const response = await getUser();
    // console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    isLoading: true,
    data: null,
    isError: false,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.data = action?.payload?.data;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.isLoading = true;
        state.isError = true;
        state.data = action.payload;
      });
  },
});

export default userSlice.reducer;
