import { createSlice } from "@reduxjs/toolkit";
import * as themeColors from "../../utils/Theme/Colors.js";

const themeSlice = createSlice({
  name: "theme",
  initialState: {
    theme: "light", // Initial theme is light
    themeColorsObject: themeColors.lightTheme
  },
  reducers: {
    // toggleTheme: (state) => {
    //   state.theme = state.theme === "light" ? "dark" : "light";
    // },
    toggleTheme: (state, action) => {
      // console.log(action)
      return {
        ...state,
        theme:  action.payload ,
        themeColorsObject: action.payload === "light" ? themeColors.lightTheme :  themeColors.darkTheme
      };
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
