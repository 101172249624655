import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subAdmin } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const subAdminThunkAPI = {
    getAll: createAsyncThunk("sub-admin/getAll", async (searchTerm, { getState }) => {
        try {
            const { size, currentPage } = getState().subAdmin
            const params = getRequestParams(searchTerm, currentPage, size)
            const res = await subAdmin.get(params);
            // console.log(res);
            if (res.status === 200) return res.data;
            // rejectWithValue(res.data);
        } catch (error) {
            console.log(error);
        }
    }
    ),

    getById: createAsyncThunk("sub-admin/getById", async (id) => {
        try {
            const res = await subAdmin?.getById(id);
            if (res.status === 200) return res?.data;
        } catch (error) {
            console.log(error);
        }
    }),
}

const subAdminSlice = createSlice({
    name: "sub-admin",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: undefined,
        data: [],
        rows: [],
        dataById: undefined,
        id: undefined,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: []
    },
    reducers: {
        datByIdAdd: (state, action) => {
            state.dataById = action.payload
        },
        removeDatById: (state, action) => {
            state.dataById = undefined
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        },
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(subAdminThunkAPI.getAll.pending, handlePending)
            .addCase(subAdminThunkAPI.getAll.fulfilled, (state, action) => {
                // console.log(action?.payload?.data?.rows);
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data?.data;
                state.rows = action?.payload?.data?.rows;
                state.totalItems = action.payload.data.totalItems;
                state.totalPages = action.payload.data.totalPage;
                state.currentPage = action.payload.data.currentPage;
                state.isLoading = false;
            })
            .addCase(subAdminThunkAPI.getAll.rejected, handleRejected);

        builder
            .addCase(subAdminThunkAPI.getById.pending, handlePending)
            .addCase(subAdminThunkAPI.getById.fulfilled, (state, action) => {
                // console.log(action?.payload?.data);
                handleFulfilled(state, action);
                state.dataById = action?.payload?.data;
            })
            .addCase(subAdminThunkAPI.getById.rejected, handleRejected)

    }
});

export default subAdminSlice.reducer;
export const { datByIdAdd, removeDatById, nextPage, previousPage } = subAdminSlice.actions;
