import { authService } from "../../service/endpoints";

import produce from "immer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import TokenService from "../../service/token.service";

export const authMe = createAsyncThunk(
  "auth/me",
  async (args, { rejectWithValue }) => {
    try {
      // console.log(" hit");
      const result = await authService.authMe();
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
      return rejectWithValue(error);
    }
  }
);

export const authLogin = createAsyncThunk("auth/login", async (args, { dispatch }) => {
  try {
    const result = await authService.login(args);
    // console.log(result);
    if (
      result.status === 200 &&
      result.data.group[0]?.title === "Administration"
    ) {
      const { accessToken, refreshToken } = result.data;
      TokenService.setUser({ accessToken, refreshToken });
      dispatch(authMe)
      return result.data;
    } else {
      console.log('Invalid credentials')
    }
  } catch (error) {
    console.log(error)
  }
});

const authSlice = createSlice({
  name: `authSlice`,
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    twoFactorAuthentication: false,
    userProfile: undefined,
    group: null,
    role: null,
    resource: [],
    actions: [],
    authInfo: null,
  },
  reducers: {
    logoutUser: (state) => {
      return {
        isLoading: false,
        isError: false,
        message: null,
        isAuthenticate: false,
        twoFactorAuthentication: false,
        userProfile: undefined,
        accessToken: null,
        refreshToken: null,
        group: null,
        role: null,
        resource: [],
        actions: [],
      };
    },
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    isAuthenticateFailed: (state) => {
      state.isAuthenticate = false;
    },
    unauthorizedLogin: (state) => {
      state.isError = true;
      state.message = "Unauthorized user";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, handlePending)
      .addCase(authLogin.fulfilled, (state, action) => {
        console.log(action.payload);
        handleFulfilled(state, action);
        state.isAuthenticate = true;
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.group = action.payload.groups[0].title;
        state.role = action.payload.roles[0].title;
        state.resource = action.payload.resources;
        state.actions = action.payload.role[0].action;
      })
      .addCase(authLogin.rejected, handleRejected)
      .addCase(authMe.pending, handlePending)
      .addCase(authMe.fulfilled, (state, action) => {
        state.resource = action.payload?.resources;
        state.userProfile = action.payload?.data;
        state.authInfo = action.payload?.data;
      })
      .addCase(authMe.rejected, (state, action) => {
        handleRejected(state, action)
      });
  },
});

export default authSlice.reducer;
export const {
  logoutUser,
  closeError,
  isAuthenticateFailed,
  unauthorizedLogin,
} = authSlice.actions;
