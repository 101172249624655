import React from 'react'
import { Paper } from '@mui/material'
import './Cms.css'

const CmsCard = ({title, icon, themeColorsObject}) => {


  return (
    <div  className="parent ">
      <Paper 
          style={{
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
          }} elevation={2} className="cms-cards justify-content-around flex-column gap-1 d-flex p-4 align-items-center">
        <div className='icon d-flex align-items-center justify-content-between'>{icon}</div>
        <p className='fs-16 mt-2'>{title}</p>
      </Paper>
    </div>
  )
}

export default CmsCard
