import React from 'react'
import * as themeColors from "../../utils/Theme/Colors";
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionSchema } from '../../utils/schema/subscription.schema';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack } from '@mui/system';
import { Autocomplete, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { subsService } from '../../service/endpoints';
import { subsThunkAPI } from '../../features/subscription/subscription.slice';
import { AiOutlineLeft } from 'react-icons/ai';

const AddSubscription = () => {

    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        reset,
    } = useForm({ mode: "all", resolver: yupResolver(subscriptionSchema) });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async (formData) => {
        // console.log(formData);
        try {
            // Make API call to create product
            formData.amount = formData.amount.toString();
            formData.duration = formData.duration.toString();
            await subsService.createProduct(formData);
            navigate('/subscription')
            dispatch(subsThunkAPI.getAll());
            // reset();

        } catch (error) {
            console.log(error);
        }
    };

    // List of country currencies
    const currencies = [
        { value: 'INR', label: 'India (INR)' },
        { value: 'USD', label: 'United States Dollar (USD)' },
    ];

    const titleArray = [
        { value: 'Essential', label: 'Essential' },
        { value: 'Premium', label: 'Premium' },
        { value: 'Exclusive', label: 'Exclusive' },
    ]

    const durationArray = [
        { value: '1', label: '1 Month' },
        { value: '12', label: '12 Months' },
    ]

    return (
        <div
            style={{
                height: "87vh",
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
                // transition:"0.3s"
            }}
            className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box >
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            {/* <Paper  > */}
                            <React.Fragment>
                                <Box p={2}>
                                    <Box className="mb-4">
                                        {/* <Typography
                                            variant="h6"
                                            sx={{ mb: 2 }}
                                        >Add Subscription</Typography> */}
                                        <Link
                                            to="/subscription"
                                            className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
                                        >
                                            <AiOutlineLeft className="pointer" size={18} />
                                            <label className="pointer">Add subscription</label>
                                        </Link>
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="title-label">Title</InputLabel>
                                                    <Controller
                                                        name="title"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                labelId="currency-label"
                                                                error={!!errors.title}
                                                                fullWidth
                                                                label="title"
                                                            >
                                                                {titleArray.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                    {errors.title && <span style={{ color: 'red', fontSize: '0.75rem' }}>{errors.title.message}</span>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        error={errors.amount ? true : false}
                                                        label="Amount"
                                                        helperText={errors.amount ? 'Enter a valid number' : ''}
                                                        {...register("amount")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="duration-label">Duration</InputLabel>
                                                    <Controller
                                                        name="duration"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                labelId="duration-label"
                                                                error={!!errors.duration}
                                                                fullWidth
                                                                label="duration"
                                                            >
                                                                {durationArray.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                    {errors.duration && <span style={{ color: 'red', fontSize: '0.75rem' }}>{errors.duration.message}</span>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="currency-label">Currency</InputLabel>
                                                    <Controller
                                                        name="currency"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                labelId="currency-label"
                                                                error={!!errors.currency}
                                                                fullWidth
                                                                label="currency"
                                                            >
                                                                {currencies.map((option) => (
                                                                    <MenuItem key={option.value} value={option.value}>
                                                                        {option.label}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                    {errors.currency && <span style={{ color: 'red', fontSize: '0.75rem' }}>{errors.currency.message}</span>}
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box sx={{ mb: 3, mt: 1 }}>
                                            <Stack spacing={2} direction="row">
                                                {/* <Button variant="text" onClick={() => navigate("/subscription")}>
                                                    Back
                                                </Button> */}
                                                <Button type="submit" variant="contained">
                                                    Submit
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Box>
                            </React.Fragment>
                            {/* </Paper> */}
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Card variant="outlined"> */}
                            <React.Fragment>
                                <CardContent>
                                    <p>
                                        "Introducing our premium subscription plan, designed to offer you unparalleled benefits and exclusive access.
                                        With our subscription, you'll unlock a world of convenience, savings, and tailored experiences like never before."
                                    </p>
                                </CardContent>
                            </React.Fragment>
                            {/* </Card> */}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </div>
    )
}

export default AddSubscription