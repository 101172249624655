import { Chip, CircularProgress, Skeleton, Modal, FormControl, Select, MenuItem } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import AddIcon from "@mui/icons-material/Add";
import './SupportChatCss.css'
import { supportGetAll, supportGetById } from "../support/support.slice";
import { useParams } from "react-router";
import { supportTicketService } from "../../service/endpoints";
import { globalThunkAPI } from "../global/global.slice";
import { enqueueSnackbar } from "notistack";

export const fileURL = (path) => {
  return new URL(path, process.env.REACT_APP_BACKEND_BASEURL).toString();
};

const SupportChat = ({ data }) => {
  // console.log(data?.dataLoading);
  const { id } = useParams();
  const dispatch = useDispatch();

  const [selectedDbFile, setSelectedDbFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePrevopen, setImagePrevOpen] = useState(false);



  const handleImagePrevClose = () => setImagePrevOpen(false);



  const [imagePrevArray, setImagePrevArray] = useState([]);
  const handleImagePrevOpen = () => setImagePrevOpen(true);




  
  const [text, setText] = useState("");

  const chatWindowRef = useRef(null);
  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [dispatch, id, data]);


  const convertUTCToLocalTime = (utcTimestamp) => {
    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const localTime = new Date(utcTimestamp).toLocaleString(undefined, options);
    return localTime;
  };

  const { themeColorsObject } = useSelector((state) => state?.theme);
  // const dummy = useSelector((state) => state);

  useEffect(() => {
    dispatch(globalThunkAPI.getStatus())
  }, [dispatch, id, data]);

  const globalStatus = useSelector((state) => state?.global);

  const handleFileChange = (e) => {
    // console.log(e.target.files);
    const selectedFiles = Array.from(e.target.files);
    setSelectedDbFile(e.target.files);

    const fileDataArray = [];
    // Loop through the selected files and read their content using FileReader
    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        // When the file reading is done, store the result in fileDataArray
        fileDataArray.push({ name: file.name, dataURL: reader.result });
        // Check if all files are processed
        if (fileDataArray.length === selectedFiles.length) {
          // When all files are processed, you can use fileDataArray as needed
          setSelectedFile(fileDataArray);
          // Update state or perform further actions with fileDataArray
          // setSelectedFiles(fileDataArray);
        }
      };
      reader.readAsDataURL(file); // Read file as data URL
    });
  };

  const handlePrint = (imageUrl) => {
    const printWindow = window.open("", "", "width=600,height=400");
    const img = new Image();
    img.onload = function () {
      printWindow.document.write(
        '<img src="' + fileURL(imageUrl) + '" style="width:100%;"/>'
      );
      printWindow.print();
      printWindow.document.close();
    };

    img.onerror = function () {
      console.error("Error loading the image for printing.");
    };

    img.src = fileURL(imageUrl);
  };

  const handleDownload = async (imageUrl, imgName) => {
    try {
      const response = await fetch(fileURL(imageUrl));
      if (!response.ok) {
        console.log(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob({ type: "image/jpeg" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = imgName; // Desired name for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const handleImageClick = (id) => {
    handleImagePrevOpen();

    const filteredObject = data?.ticket_message.filter((item) => item.id === id);
    setImagePrevArray(filteredObject[0].attachments);
    // console.log(imagePrevArray[0]?.path);
  };

  const handleSend = async (attachedFile) => {
    // Prepare the form data for sending files
    const formData = new FormData();
    // console.log(text);
    formData.append("message", text);
    formData.append("type", 'reply');

    // Append the file to the form data if there's a selected file
    if (attachedFile) {
      formData.append("attachment", attachedFile);
      for (var i = 0; i < attachedFile.length; i++) {
        formData.append("attachment", attachedFile[i]);
      }
    }
    try {
      const response = await supportTicketService.postReply(id, formData);
      // console.log(response);
      setText("");
    } catch (error) {
      console.error("API request error", error);
    }

    setSelectedFile(null);
    dispatch(supportGetById(id));
    scrollToBottom()
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend(selectedDbFile);
    }
  };

  const [status, setStatus] = useState('');

  useEffect(() => {
    const defaultSelectedStatusId = data?.data?.status.title && globalStatus?.status.find(ele => ele.title === data.data.status.title)?.id;
    // console.log(defaultSelectedStatusId);
    setStatus(defaultSelectedStatusId)
  }, [data, globalStatus]);

  const handleChange = async (event) => {
    const selectedStatusId = event.target.value;
    setStatus(selectedStatusId);
    // console.log(selectedStatusId); // This will log the updated value of status

    try {
      const response = await supportTicketService.changeStatus(id, { 'status_xid': selectedStatusId });
      // console.log(response); // Log the response from the API call
      enqueueSnackbar("Status updated", {
        variant: "success"
      });
      if (response?.statusCode === 202) {
        dispatch(supportGetAll());
      }

    } catch (error) {
      // console.error('Error occurred:', error);
      console.log(error);
    }
  };

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject?.megaCardBackgroundColor,
        color: themeColorsObject?.textColor,
        //   transition:"0.3s"
      }}
      className="w-100 mt-1 cad-shadow rounded-3  mb-4 d-flex flex-column"
    >
      <div className="rounded-3 cad-shadow  d-flex justify-content-center p-3 flex-lg-nowrap gap-0 h-100">
        <div className="position-relative col-lg-8 col-12">
          <div className="w-100 h-100  p-3 pt-1 overflow-auto">
            <div className="generate-tickets-chat-screen  pb-5  w-100 d-flex flex-column ">
              {data.dataLoading ? (
                <div className=" d-flex h-100  align-items-center justify-content-center">
                  <CircularProgress className=" cad-fw-800" />
                </div>
              ) : (
                data?.ticket_message && data?.ticket_message?.map((chat) => {
                  return (
                    <div
                      ref={chatWindowRef}
                      style={{
                        paddingBottom:
                          chat?.attachments?.length === 0 ? "1.5rem" : "0.5rem",
                      }}
                      className={
                        chat?.type === "query"
                          ? "receiving-msg-bubble mb-3"
                          : "sending-msg-bubble mb-3"
                        // : "receiving-msg-bubble mb-3"
                      }
                      key={chat?.id}
                    >
                      {chat?.attachments?.length === 0 ? (
                        <div>
                          <span className="msg">{chat?.message}</span>
                          <span className="msg-time">
                            {convertUTCToLocalTime(chat.createdAt)}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex gap-2 flex-wrap mb-2">
                            {chat?.attachments?.map((eachFile) => {
                              let imgSrc = fileURL(eachFile.path);
                              return (
                                <img
                                  onClick={() => handleImageClick(chat.id)}
                                  key={eachFile.id}
                                  className="rounded-3 pointer"
                                  src={imgSrc}
                                  width={120}
                                  height={120}
                                  alt="file-img"
                                />
                              );
                            })}
                          </div>

                          <span className="msg">{chat?.message}</span>
                          <span className="msg-time">
                            {convertUTCToLocalTime(chat?.createdAt)}
                          </span>
                        </div>
                      )}
                    </div>
                  );
                })
              )}
            </div>

            <div className="file-attachment-cnt  d-flex gap-3 align-items-center">
              {selectedFile
                ? selectedFile?.map((eachFile, i) => (
                  <img
                    key={i}
                    className="file-attachment"
                    src={eachFile?.dataURL}
                    width={60}
                    alt=""
                  />
                ))
                : ""}
              {selectedFile ? (
                <span
                  style={{ backgroundColor: "#ecf0f1" }}
                  className=" p-3 rounded-2"
                >
                  <label htmlFor="file-upload" className=" cad-fw-700 pointer">
                    <AddIcon className="color-primary" />
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                    multiple
                  />
                </span>
              ) : (
                ""
              )}
            </div>

            <div className="chat-input mb-2  p-2 pt-2">
              <input
                type="text"
                className={`form-control cad-text-medium w-100 p-0 ps-3  ${themeColorsObject?.themed_input_class}`}
                placeholder="Message..."
                value={text}
                onChange={(e) => setText(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <label htmlFor="file-upload" className="attchBtn cad-fw-700">
                <InsertLinkOutlinedIcon />
              </label>
              <input
                // onPaste={() => console.log("paste")}
                id="file-upload"
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />

              <h6
                onClick={() => handleSend(selectedDbFile)}
                className="sentBtn cad-fw-700"
              >
                send
              </h6>
            </div>
          </div>
        </div>

        <div className="col-lg-4 p-3 border-start col-12">
          <table className=" table-borderless">
            <thead>
              <tr>
                <th className="col-4 pb-3 cad-text-small">Ticket Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="pb-3 cad-text-small">Issue / Query Type</td>
                <td className="pb-3 cad-text-small">- Claim</td>
              </tr>
              <tr>
                <td className="pb-3 cad-text-small">Subject</td>

                <td className="pb-3 cad-text-small">{data?.data?.title}</td>
              </tr>

              <tr>
                <td className="pb-3 cad-text-small">Ticket Id</td>
                <td className="pb-3 cad-text-small">{data?.data?.id}</td>
              </tr>
              <tr>
                <td className="pb-3 cad-text-small">Status</td>
                <td className="pb-3 cad-text-small">
                  {/* <Chip
                    style={{ fontWeight: "500" }}
                    size="small"
                    className={`${data?.data?.status.title === "Open"
                      ? "chip-high"
                      : data?.data?.status.title === "On Hold"
                        ? "chip-high"
                        : data?.data?.status.title === "In Progress"
                          ? "chip-med"
                          : "chip-low"
                      }`}
                    label={data?.data?.status.title}
                  /> */}
                  <FormControl sx={{ minWidth: 120 }}>
                    <Select
                      value={status}
                      onChange={handleChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ height: 35 }}
                    >
                      {globalStatus?.status && globalStatus?.status?.map(ele => (
                        <MenuItem key={ele.id} value={ele.id}>
                          {ele.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Modal
        className="cad-modal-blur"
        open={imagePrevopen}
        onClose={handleImagePrevClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          className="img-prev-modal"
          onClick={handleImagePrevClose}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // overflowX:"scroll",
            outline: "none",
          }}
        >
          <div className="img-prev-cnt h-100 d-flex overflow-auto">
            {imagePrevArray?.map((image) => (
              <div key={image.id} className="image-prev-control-panel ">
                <img
                  style={{ maxHeight: "400px" }}
                  className=" rounded-3 m-2"
                  src={fileURL(image?.path)}
                  alt=""
                />

                <div className="d-flex gap-4 align-items-center justify-content-between ps-3">
                  {/* <ArrowBackOutlinedIcon className="text-white " /> */}

                  <div className="d-flex gap-3 align-items-center">
                    <CollectionsOutlinedIcon className="text-danger cad-text-large" />
                    <span className="text-white cad-text-medium cad-fw-500 d-flex align-items-center">
                      {image?.originalname}
                    </span>
                  </div>

                  <div className="d-flex gap-3  align-items-center text-white fw-bold">
                    <span
                      onClick={() => handlePrint(image?.path)}
                      className="cad-text-small image-prev-control-panel-icon p-2"
                    >
                      <LocalPrintshopOutlinedIcon />
                    </span>

                    <span
                      onClick={() =>
                        handleDownload(image?.path, image?.originalname)
                      }
                      className="image-prev-control-panel-icon p-2 cad-text-small"
                    >
                      <VerticalAlignBottomOutlinedIcon />
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SupportChat;
