import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { useSelector } from "react-redux";


const FormViewModal = ({ open, handleClose, id }) => {
  const { formArray } = useSelector((state) => state?.getForms);

  // const { formArray } = [{
  //   "id": 1,
  //   "title": "WORK PERMIT",
  //   "formData": [
  //     {
  //       "backgroundColor": "#F3F3FE",
  //       "formColor": "#FFFFFF",
  //       "color": "#2C3345"
  //     },
  //     {
  //       "type": "header",
  //       "subtype": "h1",
  //       "label": "Questionnaire For Work Permit",
  //       "className": "h1",
  //       "subTitleClassName": "cad-text-large",
  //       "alignment": "align-items-start",
  //       "title": "Heading",
  //       "subTitle": "",
  //       "access": false
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Full Name",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Full Name",
  //       "subLabel": "Please enter valid name."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Last Name",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Last Name",
  //       "subLabel": "Please enter valid name."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Given Name",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Given Name",
  //       "subLabel": "Please enter valid name."
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you ever used any other name?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Family Name",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Family Name",
  //       "subLabel": "If you have used any other name."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Given Name",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Given Name",
  //       "subLabel": "If you have used any other name."
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Sex",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Male"
  //         },
  //         {
  //           "option": "Female"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Citizenship",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Citizenship",
  //       "subLabel": "Country"
  //     },
  //     {
  //       "type": "address",
  //       "required": false,
  //       "label": "Address",
  //       "className": "form-control rounded-0",
  //       "street": "Street",
  //       "streetSubLabel": "street",
  //       "city": "City",
  //       "citySubLabel": "city",
  //       "state": "State",
  //       "stateSubLabel": "state",
  //       "country": "Country",
  //       "countrySubLabel": "country",
  //       "zipCode": "Zip Code"
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "Date of birth",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "Please enter date as per documents properly."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Place of Birth",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Place of Birth",
  //       "subLabel": "City / Country"
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Type of work permit?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Open"
  //         },
  //         {
  //           "option": "Close"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Job offer",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Job offer",
  //       "subLabel": "For closed work permits."
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "dropdown",
  //       "required": false,
  //       "label": "Marital Status",
  //       "placeHolder": "email@.com",
  //       "className": "form-select rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "Please select current maritial status.",
  //       "options": [
  //         {
  //           "option": "Never Married"
  //         },
  //         {
  //           "option": "Married"
  //         },
  //         {
  //           "option": "Widowed"
  //         },
  //         {
  //           "option": "Legally separated"
  //         },
  //         {
  //           "option": "Annulled marriage"
  //         },
  //         {
  //           "option": "Divorced"
  //         },
  //         {
  //           "option": "Common Law"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "Date of marriage",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "If married."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Name of Spouse",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Name of Spouse",
  //       "subLabel": "If married."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Family name.",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Family name.",
  //       "subLabel": "If married."
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Given name",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Given name",
  //       "subLabel": "If married."
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you previously been married or in a common law relationship?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Name of Spouse / Partner",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Spouse / Partner",
  //       "subLabel": "If married."
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "Date of Birth",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "( DD/MM/YYYY )"
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Type of Relationship",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Type of Relationship",
  //       "subLabel": "Type of Relationship"
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "From",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "( DD/MM/YYYY )"
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "To",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "( DD/MM/YYYY )"
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Native Language",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Native Language",
  //       "subLabel": "Native Language"
  //     },
  //     {
  //       "type": "checkbox",
  //       "label": "Language Preferred for Correspondence?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "English"
  //         },
  //         {
  //           "option": "French"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "tel",
  //       "title": "Contact",
  //       "required": true,
  //       "label": "UCI No.",
  //       "placeHolder": "(00-0000-0000)",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "Please enter a valid UCI number."
  //     },
  //     {
  //       "type": "tel",
  //       "title": "Contact",
  //       "required": true,
  //       "label": "Telephone number",
  //       "placeHolder": "Telephone number",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "Please enter a valid phone number."
  //     },
  //     {
  //       "type": "address",
  //       "required": false,
  //       "label": "Residential Address",
  //       "className": "form-control rounded-0",
  //       "street": "Street",
  //       "streetSubLabel": "street",
  //       "city": "City",
  //       "citySubLabel": "city",
  //       "state": "State",
  //       "stateSubLabel": "state",
  //       "country": "Country",
  //       "countrySubLabel": "country",
  //       "zipCode": "Zip Code"
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Passport No",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Passport No",
  //       "subLabel": "Passport No"
  //     },
  //     {
  //       "type": "text",
  //       "title": "Input Text",
  //       "required": false,
  //       "label": "Country of Issue",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subtype": "text",
  //       "placeHolder": "Country of Issue",
  //       "subLabel": "Country of Issue"
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "Date of Issue",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "Date of Issue"
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "Date of Expiry",
  //       "className": "form-control rounded-0",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": "Date of Expiry"
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "details-heading",
  //       "required": false,
  //       "label": "EDUCATION DETAILS",
  //       "className": "form-control",
  //       "detailsTitle": "SSC",
  //       "subTitle": "If you have any post secondary education (including university, college or apprenticeship training)."
  //     },
  //     {
  //       "type": "details",
  //       "required": false,
  //       "label": "EMPLOYMENT DETAILS",
  //       "className": "form-control",
  //       "fieldOne": "Start date (YY/MM)",
  //       "fieldTwo": "End date (YY/MM)",
  //       "fieldThree": "Institue Name",
  //       "fieldFour": "Field of study",
  //       "fieldFive": "Certificate",
  //       "fieldSix": "University",
  //       "detailsTitle": "SSC",
  //       "subTitle": "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps"
  //     },
  //     {
  //       "type": "details",
  //       "required": false,
  //       "label": "EMPLOYMENT DETAILS",
  //       "className": "form-control",
  //       "fieldOne": "Start date (YY/MM)",
  //       "fieldTwo": "End date (YY/MM)",
  //       "fieldThree": "Institue Name",
  //       "fieldFour": "Field of study",
  //       "fieldFive": "Certificate",
  //       "fieldSix": "University",
  //       "detailsTitle": "HSC",
  //       "subTitle": "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps"
  //     },
  //     {
  //       "type": "details",
  //       "required": false,
  //       "label": "EMPLOYMENT DETAILS",
  //       "className": "form-control",
  //       "fieldOne": "Start date (YY/MM)",
  //       "fieldTwo": "End date (YY/MM)",
  //       "fieldThree": "Institue Name",
  //       "fieldFour": "Field of study",
  //       "fieldFive": "Certificate",
  //       "fieldSix": "University",
  //       "detailsTitle": "MCA",
  //       "subTitle": "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps"
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "details-heading",
  //       "required": false,
  //       "label": "EMPLOYMENT DETAILS",
  //       "className": "form-control",
  //       "detailsTitle": "SSC",
  //       "subTitle": "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps"
  //     },
  //     {
  //       "type": "details",
  //       "required": false,
  //       "label": "EMPLOYMENT DETAILS",
  //       "className": "form-control",
  //       "fieldOne": "Start date (YY/MM)",
  //       "fieldTwo": "End date (YY/MM)",
  //       "fieldThree": "Activity",
  //       "fieldFour": "City / Town",
  //       "fieldFive": "Country",
  //       "fieldSix": "Name of company",
  //       "detailsTitle": "Company details",
  //       "subTitle": "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps"
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "details-heading",
  //       "required": false,
  //       "label": "Previous/current residency in foreign countries",
  //       "className": "form-control",
  //       "detailsTitle": "SSC",
  //       "subTitle": "Previous countries of residence in past five years where you have lived more than six months, except from country of citizenship and country of current residence? Status in country"
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "From",
  //       "className": "form-control",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": ""
  //     },
  //     {
  //       "type": "date",
  //       "required": false,
  //       "label": "To",
  //       "className": "form-control",
  //       "name": "text-1703229830619-0",
  //       "access": false,
  //       "subLabel": ""
  //     },
  //     {
  //       "type": "hr"
  //     },
  //     {
  //       "type": "details-heading",
  //       "required": false,
  //       "label": "BACKGROUND INFORMATION",
  //       "className": "form-control",
  //       "detailsTitle": "SSC",
  //       "subTitle": ""
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Within the past two years, have you or a family member ever had tuberculosis of the lungs or been in close contact with a person with tuberculosis?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Do you have any physical or mental disorder that would require social and/or health services, other than medication, during a stay in Canada?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you ever remained beyond the validity of the status, attended school without authorization or worked without authorization in Canada?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you ever been refused a visa or permit, denied entry or ordered to leave Canada or any other country?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you previously applied to enter or remain in Canada?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "Yes"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you ever committed, been arrested for, been charged with or convicted of any criminal offence in any country?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Did you serve in any military or civil defence unit or serve in a security organization or police force (including non- obligatory national service, reserve or volunteer units)?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Are you, or have you ever been a member or associated with any political party, or other group or organization which has engaged in or advocated violence as a means to achieving a political or religious objective, or which has been associated with criminal activity at any time?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "radio",
  //       "label": "Have you ever witnessed or participated in the ill treatment of prisoners or civilians, looting or desecration of religious buildings?",
  //       "access": false,
  //       "options": [
  //         {
  //           "option": "Yes"
  //         },
  //         {
  //           "option": "No"
  //         }
  //       ]
  //     },
  //     {
  //       "type": "textarea",
  //       "title": "Text area",
  //       "required": false,
  //       "label": "If answer to any of the above questions is Yes. Give Details.",
  //       "className": "form-control",
  //       "placeHolder": "Leave a text here",
  //       "name": "textarea-1703229833169-0",
  //       "access": false,
  //       "subtype": "textarea",
  //       "subLabel": ""
  //     }
  //   ]
  // }]

  const filteredForm = formArray?.find((obj) => obj?.id === id);
  let themeObject, theme;

  try {
    const jsonArray = filteredForm?.formData;
    if (jsonArray instanceof Array) {
      themeObject = jsonArray;
      theme = jsonArray[0];
    }
  } catch (error) {
    console.log("Error parsing JSON:", error);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur p-3"
    >
      <Box
        sx={{
          height: "95%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <div className="d-flex w-100 cad-shadow justify-content-between  p-3   gap-1">
          <span
            style={{ fontSize: "30px" }}
            className=" cad-fw-700 cad-text-dark"
          >
            {filteredForm?.title}
          </span>

          <Link
            className="d-flex align-items-center"
            to={`/template-forms/${id}`}
            style={{ textDecoration: 'none' }}
          >
            <Button variant="outlined" className="button-contained">
              Use Template
            </Button>
          </Link>
        </div>

        <div
          style={{
            padding: "0px 26px 100px 26px",
            backgroundColor: theme?.formColor,
            color: theme?.color,
          }}
          className="d-flex overflow-auto h-100 w-100 flex-wrap"
        >
          {themeObject?.map((field, index) => {
            switch (field.type) {
              case "header":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="w-100 mb-3"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // element-edit
                      style={{
                        borderBottom: `1px solid ${themeObject.color}`,
                      }}
                      className={`${field.alignment} position-relative  p-4 pb-2   w-100 d-flex justify-content-center flex-column`}
                    >
                      <h3 className={`${field.className} cad-fw-700`}>
                        {field.label}
                      </h3>
                      <p className={field.subTitleClassName}>
                        {field.subTitle}
                      </p>
                    </div>
                  </div>
                );

              case "text":
                return (
                  <div
                    className="col-6"
                  // onDragOver={(e)=>handleElementDragOver(e, index)}
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className={` position-relative p-2 gap-2 d-flex flex-column`}
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "tel":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative  p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type="number"
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "date":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "email":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        required={field.required}
                        type={field.type}
                        className={field.className}
                        placeholder={field.placeHolder}
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "textarea":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <textarea
                        style={{
                          borderColor: themeObject.color,
                        }}
                        className="form-control"
                        placeholder={field.placeHolder}
                        id="floatingTextarea"
                      ></textarea>
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "submit":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative  p-4 pb-3 gap-2 d-flex justify-content-center"
                    >
                      <button
                        style={{
                          backgroundColor: themeObject.color,
                          color: themeObject.formColor,
                        }}
                        type={field.type}
                        className="btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                );

              case "dropdown":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className=" position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <select
                        style={{
                          borderColor: themeObject.color,
                        }}
                        className={field.className}
                        aria-label="Default select example"
                      >
                        <option selected>Open this select menu</option>
                        {field.options.map(({ option }, index) => (
                          <option key={index} value={index + 1}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "paragraph":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className={`${field.alignment} position-relative p-2 gap-2 d-flex flex-column`}
                    >
                      <p className={`${field.className}  cad-fw-500`}>
                        {field.label}
                      </p>
                    </div>
                  </div>
                );

              case "file":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label htmlFor="formFile" className="form-label">
                        {field.label}
                      </label>
                      <input
                        style={{
                          borderColor: themeObject.color,
                        }}
                        className={field.className}
                        type={field.type}
                        id="formFile"
                      />
                      <span className="cad-text-small ">{field?.subLabel}</span>
                    </div>
                  </div>
                );

              case "hr":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-12"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      {/* <hr color={themeObject.color} /> */}
                      <span
                        className="mt-2 mb-2"
                        style={{
                          borderTop: `0.5px solid ${themeObject.color}`,
                        }}
                      />
                    </div>
                  </div>
                );

              case "checkbox":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className=" position-relative p-2"
                    >
                      <label className="cad-text-medium cad-fw-500 mb-2">
                        {field.label}
                      </label>
                      {field.options.map(({ option }, index) => (
                        <div
                          key={index}
                          className="form-check d-flex justify-content-start w-100 gap-2"
                        >
                          <input
                            className="form-check-input cad-text-medium"
                            type={field.type}
                            value=""
                            id="flexCheckIndeterminate"
                          />
                          <label
                            className="form-check-label col-12 cad-text-small"
                            htmlFor="flexCheckIndeterminate"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                );

              case "radio":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="col-6"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className=" position-relative p-2"
                    >
                      <label className="cad-text-medium cad-fw-500 mb-2">
                        {field.label}
                      </label>
                      {field.options.map(({ option }, index) => (
                        <div
                          key={index}
                          className="form-check d-flex justify-content-start w-100 gap-2"
                        >
                          <input
                            name="exampleRadios"
                            className="form-check-input cad-text-medium"
                            type={field.type}
                            value=""
                            id="flexCheckIndeterminate"
                          />
                          <label
                            className="form-check-label col-12 cad-text-small"
                            htmlFor="flexCheckIndeterminate"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                );

              case "address":
                return (
                  <div className="col-12">
                    <div
                      key={field.id}
                      // // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}

                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-medium cad-fw-500">
                        {field.label}
                      </label>
                      <div className="d-flex flex-wrap w-100">
                        <div className="w-100 d-flex justify-content-start flex-column mb-2">
                          <textarea
                            style={{
                              borderColor: themeObject.color,
                            }}
                            type="text"
                            className="form-control w-100 cad-text-medium   m-1"
                            placeholder={field?.street}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.streetSubLabel}
                          </span>
                        </div>
                        <div className="col-6 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.city}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.citySubLabel}
                          </span>
                        </div>

                        <div className="col-6 flex-column d-flex justify-content-end mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.state}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.stateSubLabel}
                          </span>
                        </div>

                        <div className="col-6 flex-column d-flex justify-content-end mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.country}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.country}
                          </span>
                        </div>

                        <div className="col-6 flex-column d-flex justify-content-end mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-medium m-1 "
                            placeholder={field?.zipCode}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.zipCode}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "footer":
                return (
                  <div
                    key={field.id}
                    // dragable
                    // onDragStart={(e) => handleElementDragStart(e, index)}
                    // onDragOver={(e) => handleElementDragOver(e, index)}
                    // element-edit
                    // onDoubleClick={() => setEditableIndex(index)}
                    style={{
                      borderTop: `1px solid ${themeObject.color}`,
                    }}
                    className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between `}
                  >
                    <span className={`${field.className} cad-fw-700`}>
                      {field.label}
                    </span>
                    <span className={field.subTitleClassName}>
                      {field.subTitle}
                    </span>
                  </div>
                );

              case "table":
                return (
                  <div
                    // onDragOver={(e)=>handleElementDragOver(e, index)}
                    className="w-100 mt-3"
                  >
                    <div
                      key={field.id}
                      // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // element-edit
                      // onDoubleClick={() => setEditableIndex(index)}
                      className={`${field.alignment} position-relative ps-4 pt-2 pb-3 pe-4 pb-0 w-100 d-flex justify-content-between rouded-3`}
                    >
                      <table className="table ">
                        <thead>
                          <tr>
                            {field.th.map(({ head }, index) => (
                              <th
                                style={{
                                  borderColor: themeObject.color,
                                  color: themeObject.color,
                                }}
                                key={index}
                                scope="col"
                              >
                                {head}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {field?.td?.map((eachData, index) => (
                            <tr className="cad-text-medium" key={index}>
                              {eachData.tableData.map(({ data }, i) => (
                                <td
                                  style={{
                                    color: themeObject.color,
                                    borderColor: themeObject.color,
                                  }}
                                >
                                  {data}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                );

              case "details":
                return (
                  <div className="col-12">
                    <div
                      key={field.id}
                      // // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // onDoubleClick={() => setEditableIndex(index)}
                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <div className="d-flex flex-wrap w-100">
                        <label className="col-12  mb-1 mt-2 cad-fw-600">
                          {field.detailsTitle}
                        </label>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldOne}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldOne}
                          </span>
                        </div>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldTwo}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldTwo}
                          </span>
                        </div>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldThree}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldThree}
                          </span>
                        </div>

                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldFour}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldFour}
                          </span>
                        </div>
                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldFive}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldFive}
                          </span>
                        </div>
                        <div className="col-2 flex-column d-flex justify-content-start mb-2">
                          <input
                            type="text"
                            style={{
                              borderColor: themeObject.color,
                            }}
                            className="form-control cad-text-small m-1 rounded-0 "
                            placeholder={field?.fieldSix}
                          />
                          <span className="cad-text-small ps-1">
                            {field?.fieldSix}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );

              case "details-heading":
                return (
                  <div className="col-12">
                    <div
                      key={field.id}
                      // // dragable
                      // onDragStart={(e) => handleElementDragStart(e, index)}
                      // onDragOver={(e) => handleElementDragOver(e, index)}
                      // onDoubleClick={() => setEditableIndex(index)}
                      className="position-relative p-2 gap-2 d-flex flex-column"
                    >
                      <label className="cad-text-large cad-fw-700">
                        {field.label}
                      </label>
                      <label className="cad-text-medium cad-fw-500">
                        {field.subTitle}
                      </label>
                    </div>
                  </div>
                );

              case "signeture":
                return (
                  <div className="col-12 mt-5 pt-5">
                    <div
                      // onDoubleClick={() => setEditableIndex(index)}
                      className="col-12 d-flex flex-column justify-content-end align-items-end pe-2"
                    >
                      <SignaturePad
                        // ref={sigCanvas}
                        penColor={themeObject.color}
                        canvasProps={{
                          className: " bg-white",
                          style: {
                            borderBlockColor: themeObject.color,
                            width: "35%",
                            height: "120px",
                            border: "1px solid",
                            borderColor: themeObject.color,
                          },
                        }}
                      />
                      {/* Button to trigger save canvas image */}
                      <div className="w-25 d-flex justify-content-end cad-fw-600 cad-text-large pt-2 ">
                        <span>Signeture</span>
                      </div>
                    </div>
                  </div>
                );

              default:
                return null;
            }
          })}
        </div>
      </Box>
    </Modal>
  );
};

export default FormViewModal;
