import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { paymentFilterService } from "../../service/endpoints";
import { handleFulfilled, handleRejected } from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const payFilterThunkAPI = {
    getAll: createAsyncThunk(
      "paymentfilter/getAll",
      async ({ searchTerm, startDate, endDate }, { rejectWithValue, getState, abort }) => {
        try {
          const { size, currentPage } = getState().payment;
          const params = getRequestParams(searchTerm, currentPage, size, startDate, endDate);
          const res = await paymentFilterService.get(params);
  
          if (res?.status === 200) {
            return res.data;
          } else {
            abort('Something went wrong');
          }
        } catch (error) {
          return rejectWithValue(error.response);
        }
      }
    ),
};

const paymentFilterSlice = createSlice({
  name: "paymentFilter",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    rows: [],
    dataById: {},
    size: 10,
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    search: null,
    searchHistory: [],
    archive: [],
  },
  reducers: {
    nextPage: (state) => {
      state.currentPage = Math.min(state.totalPages, state.currentPage + 1);
    },
    previousPage: (state) => {
      state.currentPage = Math.max(1, state.currentPage - 1);
    },
    setSearchTerm: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payFilterThunkAPI.getAll.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(payFilterThunkAPI.getAll.fulfilled, (state, action) => {
        handleFulfilled(state, action); // Helper function to handle common success actions
        state.search = null;
        state.data = action.payload.data || [];
        state.rows = filterData(action.payload.data?.rows);
        state.totalItems = action.payload.data?.totalItems || 0;
        state.totalPages = action.payload.data?.totalPages || 0;
        state.currentPage = action.payload.data?.currentPage || 1;
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
      })
      .addCase(payFilterThunkAPI.getAll.rejected, (state, action) => {
        handleRejected(state, action); // Helper function to handle common error actions
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload
          ? action.payload.message
          : "An error occurred"; // Improved error message handling
      });
  },
});

// Helper function to transform fetched data
const filterData = (data) => {
  if (!data) return [];
  return data.map((ele) => ({
    id: ele.id,
    profile_photo: ele.principal[0]?.profile_photo,
    first_name: ele.principal[0]?.first_name,
    last_name: ele.principal[0]?.last_name,
    name: `${ele.principal[0]?.first_name} ${
      ele.principal[0]?.last_name || ""
    }`,
    package: ele?.checkout_plans?.package?.title,
    amount: `${ele?.checkout_plans?.unit_amount} ${
      ele?.checkout_plans?.currency
    }`,
    status: ele?.payment_status,
    recurring: ele?.checkout_plans?.recurring,
    ...ele,
  }));
};

export default paymentFilterSlice.reducer;
export const { nextPage, previousPage, setSearchTerm } = paymentFilterSlice.actions;
