import React from 'react'
import DataTable from 'react-data-table-component';
import CustomCheckbox from './CustomCheckbox';

const GlobalTable = ({ columns, data, searchText, onSelectedRowsChange, selectableRows }) => {
    // Custom styles for the data table
    const customStyles = {
        rows: {
            style: {
                minHeight: "50px", // override the row height
            },
        },
        headCells: {
            style: {
                padding: '12px',
            },
        },
        cells: {
            style: {
                padding: '12px',
            },
        },
    };

    // Filter data based on user input
    const filteredData = data?.filter((item) =>
        Object.values(item).some((value) => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchText.toLowerCase());
            } else if (typeof value === 'number') {
                // Convert the number to a string and then perform the search
                return value.toString().includes(searchText);
            }
            return false;
        })
    );

    return(
        <DataTable
            columns={columns}
            data={filteredData}
            selectableRows={selectableRows}
            customStyles={customStyles}
            selectableRowsComponent={CustomCheckbox}
            pagination
            onSelectedRowsChange={onSelectedRowsChange}
        />
    )
}

export default GlobalTable