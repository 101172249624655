import React, { useEffect, useState } from 'react'
import CmsCard from '../../Components/CmsCard/CmsCard';
import { TfiWrite } from 'react-icons/tfi'
import { BsFileText } from 'react-icons/bs'
import { BsLayoutTextWindow } from 'react-icons/bs'
import { TbMessageQuestion } from 'react-icons/tb'
import { Link } from 'react-router-dom';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import * as themeColors from "../../utils/Theme/Colors";
import { useSelector } from "react-redux";
import { organization } from '../../service/endpoints';

const Cms = () => {
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;
  // console.log(themeColorsObject.backgroundColor);

  const [orgId, setOrgId] = useState(null)

  useEffect(() => {
    async function getDetails() {
      try {
        // Make the API call using landingPage.get()
        const response = await organization.get();
        // Handle the response
        setOrgId(response?.data?.data[0]?.id)
      } catch (error) {
        // Handle errors
        console.error("Error occurred while making API call:", error);
      }
    }
    getDetails()
  }, [])

  return (
    <div
      style={{
        height: "75vh",
        // transition:"0.3s"
      }}
      className="w-100  mb-4 d-flex mt-1 flex-wrap justify-content-start align-items-start"
    >
      <Link to='/termscondition' className=' col-lg-4  col-12 p-2 text-decoration-none'>
        <CmsCard
          themeColorsObject={themeColorsObject}
          icon={<TfiWrite />}
          title="Terms & Conditions"
        />
      </Link >
      <Link to='/PrivacyPolicy' className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <CmsCard
          themeColorsObject={themeColorsObject}
          icon={<BsFileText />}
          title="Privacy Policy"
        />
      </Link>
      <Link to='/gdpr' className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <CmsCard
          themeColorsObject={themeColorsObject}
          icon={<BsLayoutTextWindow />}
          title="GDPR"
        />
      </Link>
      <Link to='/guidance' className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <CmsCard
          themeColorsObject={themeColorsObject}
          icon={<LiveHelpIcon />}
          title="Help And Guidance"
        />
      </Link>
      <Link to='/faq' className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <CmsCard
          themeColorsObject={themeColorsObject}
          icon={<TbMessageQuestion />}
          title="FAQ"
        />
      </Link>
      <Link to={`/landingpage/${orgId}`} className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <CmsCard
          themeColorsObject={themeColorsObject}
          icon={<BsLayoutTextWindow />}
          title="Manage Landing Page"
        />
      </Link>
    </div>
  )
}

export default Cms
