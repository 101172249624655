import React, { useEffect, useState } from "react";
import PrimaryButton from "../../PrimaryButton";
import { CiEdit } from "react-icons/ci";
import { Link, useParams } from "react-router-dom";
import { Skeleton, TextField } from "@mui/material";
import "../Cms.css";
import { AiOutlineLeft } from "react-icons/ai";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import newimage from "../../../Images/upload-img.png";
import * as themeColors from "../../../utils/Theme/Colors";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector } from "react-redux";
import RichTextQuill from "../../Quill/Quill";
import { landingSchema } from "../../../utils/schema/landing.schema";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { organization } from "../../../service/endpoints";
import { enqueueSnackbar } from "notistack";

const LandingPage = () => {
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const { id } = useParams()

  const [isDataFetched, setIsDataFetched] = useState(false)
  const [editorHtml, setEditorHtml] = useState(""); // Initial value is an empty string
  const [aboutError, setAboutError] = useState(false)

  useEffect(() => {
    async function getDetails() {
      try {
        // Make the API call using landingPage.get()
        const response = await organization.getById(id);
        // Handle the response
        // console.log("id details", response?.data?.data);
        const data = response?.data?.data
        setIsDataFetched(true)
        setValue('title', data?.title)
        setValue('subtitle', data?.subtitle)
        setValue('email', data?.email)
        setValue('address', data?.address)
        setValue('phone', data?.phone_number)
        setValue('mobile', data?.mobile_number)
        setValue('description', data?.description)
        setValue('Off_on_Non_Profits',data?.Off_on_Non_Profits)
        if (!editorHtml && data?.about) {
          setEditorHtml(data?.about);
          setValue("about", data?.about); // Set the value for "aboutUs" field in the form
        }
      } catch (error) {
        // Handle errors
        console.error("Error occurred while making API call:", error);
      }
    }
    getDetails()
  }, [id])


  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(landingSchema),
  });




  const submitFormData = async (formData) => {
    console.log(formData);
    try {
      const response = await organization.put(id, formData);
      // console.log(response);
      if (response.status === 200) {
        enqueueSnackbar("Successfully Updated", {
          variant: "success"
        });
      } else {
        // Handle other status codes
        console.error('Error:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  };

  const onSubmit = async (data) => {
    // console.log(data);

    // console.log(editorHtml.trim());
    const plainText = editorHtml.replace(/<\/?[^>]+(>|$)/g, "");
    // Check if the editorHtml is empty
    if (!plainText) {
      setAboutError(true); // Set the error state if the editor content is empty
      return;
    } else {
      setAboutError(false); // Reset the error state if the editor content is not empty
    }

    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('about', editorHtml); // Send the HTML content with tags
    formData.append('email', data.email);
    formData.append('phone_number', data.phone);
    formData.append('mobile_number', data.mobile);
    formData.append('address', data.address);
    formData.append('description', data.description);
    formData.append('subtitle', data.subtitle);
    formData.append('Off_on_Non_Profits', data.Off_on_Non_Profits);

    // console.log(formData);
    // Call the function to submit form data
    await submitFormData(formData);
  };



  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const handleChange = (content) => {
    setEditorHtml(content);
    setValue("about", content); // Set the value for "aboutUs" field in the form

    // Reset the error state if the content is not empty
    if (aboutError && content.trim() !== "") {
      setAboutError(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          height: "auto",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor
        }}
        className="w-100  mb-4 rounded-3 mt-1 cad-shadow p-3 "
      >
        {isDataFetched ? (
          <><div className="d-flex  justify-content-between mb-4">
            <Link
              to="/cms"
              className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
            >
              <AiOutlineLeft className="pointer" size={18} />
              <label className="pointer">Manage Landing Page</label>
            </Link>
          </div><div className="uploder mb-4">
              <h4 className="mb-3">Header</h4>
              <TextField
                size="small"
                label="Title"
                sx={{ width: "40%" }}
                {...register("title")}
                error={errors.title ? true : false}
                helperText={errors.title?.message} />
            </div><div className="uploder mb-4">
              <TextField
                size="small"
                label="Sub title"
                sx={{ width: "40%" }}
                {...register("subtitle")}
                error={errors.subtitle ? true : false}
                helperText={errors.subtitle?.message}
                multiline
                rows={4} />
            </div><div className="uploder   mb-4">
              <div className="d-flex justify-content-start align-items-start  mb-3 flex-column">
                <h4 className="mb-3">About Us</h4>
                <RichTextQuill
                  editorHtml={editorHtml}
                  handleChange={handleChange} />
                {aboutError && <span className="text-danger">This field is required</span>}
              </div>
            </div><div className="uploder mb-4 row">
              <h4 className="mb-3">Footer</h4>
              <div className="row">
                <div className="col-6 mb-4">
                  <TextField
                    size="small"
                    label="Description"
                    multiline
                    rows={4}
                    {...register("description")}
                    error={errors.description ? true : false}
                    helperText={errors.description?.message}
                    sx={{ width: "80%" }} />
                </div>
                <div className="col-6 mb-4">
                  <TextField
                    size="small"
                    label="Address"
                    multiline
                    rows={4}
                    {...register("address")}
                    error={errors.address ? true : false}
                    helperText={errors.address?.message}
                    sx={{ width: "80%" }} />
                </div>
                <div className="col-6 mb-4">
                  <TextField
                    size="small"
                    label="Email"
                    {...register("email")}
                    error={errors.email ? true : false}
                    helperText={errors.email?.message}
                    sx={{ width: "80%" }} />
                </div>
                <div className="col-6">
                  <TextField
                    size="small"
                    label="Phone No."
                    {...register("phone")}
                    error={errors.phone ? true : false}
                    helperText={errors.phone?.message}
                    sx={{ width: "80%" }} />
                </div>
                <div className="col-6">
                  <TextField
                    size="small"
                    label="Mobile No."
                    {...register("mobile")}
                    error={errors.mobile ? true : false}
                    helperText={errors.mobile?.message}
                    sx={{ width: "80%" }} />
                </div>
                <div className="col-6">
                  <TextField
                    size="small"
                    label="Off_on_Non_Profits"
                    {...register("Off_on_Non_Profits")}
                    error={errors.mobile ? true : false}
                    helperText={errors.mobile?.message}
                    sx={{ width: "80%" }} />
                </div>

              </div>
            </div><div className="comp-btn d-flex justify-content-center mb-5">
              <Button type="submit" variant="contained">
                Submit
              </Button>
            </div></>
        ) :
          (Array.from({ length: 4 }).map((_, index) => (
            <div className="mb-3" key={index}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" width="50%" />
            </div>
          )))
        }

      </div>


    </form>
  );
};

export default LandingPage;
