import React from "react";
import Footer from "../../Components/Footer/Footer";
import notFount from "../../Images/notFound.png"

const NotFound = () => {
  return (
    <div className=" overflow-auto position-relative h-100 ">
      <div className="rounded-3 h-75 ">
        <div className="d-flex h-100 mt-5  mb-5">
          <div className="col-6 h-100 d-flex flex-column justify-content-center align-items-center">
            <img src={notFount} width={350} alt="" />
            {/* <h3 style={{color:"#192a56"}} className="cad-fw-800">Awww... Don't Cry.</h3> */}
          </div>
          <div className="col-6 h-100 d-flex flex-column justify-content-center align-items-center">
            <h1 style={{color:"#192a56", fontSize:120}} className="cad-fw-800">404</h1>
            <span style={{color:"#192a56"}} className="cad-fw-400 fs-5">Sorry, but the page you are looking for dosen't exist.

</span>
          </div>
        </div>
      </div>
      <div style={{
        position:"absolute",
        bottom:"0px",
        left:"0px",
        width:"100%"
      }}>
     <Footer/>
     </div>
 
    </div>
  );
};

export default NotFound;
