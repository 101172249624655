import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import "react-quill/dist/quill.snow.css";
import PrimaryButton from "../../PrimaryButton";
import RichTextQuill from "../../Quill/Quill";
import { gdprService } from "../../../service/endpoints";
import { enqueueSnackbar } from "notistack";

const GdprEdit = () => {
  const { id } = useParams();

  const [editorHtml, setEditorHtml] = useState(""); // Initial value is an empty string
  const navigate = useNavigate();
  const fetchData = async (id) => {
    try {
      const result = await gdprService.getById(id);
      setEditorHtml(result.data.data?.content);
      return;
    } catch (err) {
      console.log(err)
    }
  };
  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleUpdate = async () => {
    const plainText = editorHtml.replace(/<\/?[^>]+(>|$)/g, "");
    if (!plainText) {
      alert("Please fill these content");
      return;
    }
    const result = await gdprService.update(id, { content: editorHtml });
    if (result.status === 200) {
      navigate("/gdpr");
      enqueueSnackbar("Successfully Created", { variant: "success" });
    }
  };

  return (
    <div className="m-3 editdetails bg-white w-85 h-100 rounded-2 p-3">
      <div className=" mb-4">
        <Link to="/gdpr" className="text-black text-decoration-none">
          <BsArrowLeft fontSize="20px" style={{ marginRight: "10px" }} />
          Edit Details
        </Link>
      </div>
      <RichTextQuill
        editorHtml={editorHtml}
        handleChange={(content) => setEditorHtml(content)}
      />
      <div className="d-flex mt-4 justify-content-center">
        <PrimaryButton
          onClick={handleUpdate}
          padding="0.4rem 3rem"
          title="Update"
        />
      </div>
    </div>
  );
};

export default GdprEdit;
