import React from 'react';

// ProfilePic component displays a user's profile picture and name
const UserProfile = ({ data }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={data?.profile_photo} // Replace with the actual URL of the profile picture
                alt={data?.name} // Alt text for the image
                style={{ width: '28px', height: '28px', borderRadius: '50%', marginRight: '10px' }} // Styling for the image
            />
            {data?.first_name} {/* Display the user's name */}
        </div>
    );
};

export default UserProfile;
