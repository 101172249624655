import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import { Paper } from '@mui/material'
import BarGraph from '../Charts/BarGraph'
import Maps from '../Charts/Maps'
import ClientGraph from '../Charts/ClientGraph'
import UserGraph from '../Charts/UserGraph'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { AiOutlineLeft } from 'react-icons/ai'

const AboutMetadata = () => {
     
  

  return (
    
     <Paper elevation={0}
     style={{
         backgroundColor: "#f1f2f6"
     }}
     className=" w-100 p-3 d-flex flex-column justify-content-start report-card-one"
    >
        <Box className="border-bottom rounded-top-3 cad-shadow" sx={{ borderBottom: 0, padding: 0, backgroundColor: "#fff", width: "100%" }}>
            <div className='bg-white rounded-2 p-4'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                    <Link to='/reports' className='mb-2 d-flex align-items-center gap-1 text-decoration-none'>
                        <AiOutlineLeft className='pointer' size={18} />
                        <label className='pointer'>Users clicking <strong> "Get Started"</strong></label>
                    </Link>
                    <div className='calendar'>
                        <CalendarMonthIcon />
                    </div>
                </div>
                <div className='row mb-4'>
                    <div className='col-sm-12 col-12 mt-4'>
                        <ClientGraph
                           title='Subscription'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-4 col-12'>
                        <UserGraph
                            title='Average file size'
                        />
                    </div>
                    <div className='col-sm-4 col-12'>
                        <UserGraph
                            title='Active subscription'
                        />
                    </div>
                    <div className='col-sm-4 col-12'>
                        <UserGraph
                            title='Archived subscription'
                        />
                    </div>
                </div>
            </div>
        </Box>  
    </Paper>
  )
}

export default AboutMetadata
