import * as yup from "yup";
export const subAdminSchema = yup.object().shape({
    first_name: yup.string()
        .required("First name is required")
        .matches(/^[A-Za-z]+$/, 'First name should only contain letters')
        .min(4, 'First name should be at least 4 characters')
        .max(200),
    last_name: yup.string()
        .required("Last name is required")
        .matches(/^[A-Za-z]+$/, 'Last name should only contain letters')
        .min(4, 'Last name should be atleast 4 characters')
        .max(200),
    email_address: yup.string()
        .required("Email is required")
        .matches(
            // Regular expression pattern including top-level domains (TLDs)
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            "Enter valid email address"
        )
        .email("Email address must be a valid email"),
    designation: yup.string()
        .required("Designation is required")
        .matches(/^[A-Za-z\s]+$/, 'Designation should only contain letters and spaces')
        .min(3, 'Designation should be at least 3 characters')
        .max(200),
    resources: yup
        .array()
        .required("At least one resource is required")
        .of(yup.string().uuid("Invalid UUID format"))
        .min(1, "At least one resource is required") // Validation for at least one checkbox selected
        .transform((value, originalValue) => {
            console.log(originalValue);
            // Remove any null or undefined values from the array
            return originalValue.filter(
                (categoryId) => categoryId !== null && categoryId !== undefined
            );
        }),
});
