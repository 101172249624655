import * as React from "react";
import {
  Avatar,
  Badge,
  Breadcrumbs,
  Button,
  Fade,
  Paper,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Route, Routes } from "react-router";
import { RouteLink } from "../_routes";
import NotFound from "../Pages/NotFound/NotFound";
import Footer from "./Footer/Footer";
import { nav } from "../_nav";
import SlideBtn from "./SubComponents/SlideBtn/SlideBtn";
import Accordion from "./SubComponents/Accordion";
import { useSelector } from "react-redux";
import logo from "../Images/logo.png";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import LogoutTwoToneIcon from "@mui/icons-material/LogoutTwoTone";
import LogoutModal from "./Modals/LogoutModal/LogoutModal";
import NotificationAll from "./NotificationAll/NotificationAll";
import Alerts from "./Alerts/Alerts";
import Message from "./Message/Message";
import { BiBell } from "react-icons/bi";
import upgradeLogo from "../Images/upgradeLogo.png";
import { getTitle } from "./Header";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import * as themeColors from "../utils/Theme/Colors.js";
import { useDispatch } from "react-redux";
// import { toggleTheme } from "../Redux/Slices/theme.Slice.js";
import UserProfile from "./GlobalTable/UserProfile.js";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import CustomizedBreadcrumbs from "./CustomizedBreadcrumbs.js";
import { toggleTheme } from "../Redux/Slices/theme.Slice.js";
import { authMe } from "../features/auth/authSlice.js";

const drawerWidth = 260;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function NewDrawerPersistace() {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;

  const [anchorEl, setAnchorEl] = useState(null);
  const [avatarPopoverOpen, setAvatarPopoverOpen] = useState(false);
  const [helloPopperOpen, setHelloPopperOpen] = useState(false);
  const [open, setOpen] = useState(true);
  const [openLogOut, setOpenLogOut] = useState(false);
  const [activeButton, setActiveButton] = useState("All");

  const rolbase = useSelector((state) => state?.auth?.userProfile);
  // console.log(rolbase);
  const newData = useSelector((state) => state?.auth);
  // console.log("------ auth me slice", newData);
  const au = useSelector((state) => state?.auth);
  // console.log(au);
  const { isLoading, userProfile } = useSelector((state) => state?.auth);
  const profileData = userProfile;
  // console.log(profileData);

  React.useEffect(() => {
    dispatch(authMe())
  }, [dispatch])

  // const states = useSelector((state) => state);
  // // console.log(states);

  const { theme, themeColorsObject } = useSelector((state) => state.theme);
  // console.log(themeColorsObject);

  // Function to get the title based on the route
  const getTitle = () => {
    switch (path) {
      case "/users":
        return "Users";
      case "/cms":
        return "CMS";
      case "/forms":
        return "Forms";
      case "/reports":
        return "Reports";
      case "/supports":
        return "Supports";
      case "/contact":
        return "Contact Us";
      case "/subscription":
        return "Subscription";
      case "/payments":
        return "Payments";
      case "/chat":
        return "Chat";
      case "/subadmin":
        return "Sub Admin";
      case "/add-subscription":
        return "Add Subscription";


      default:
        return "Dashboard";
    }
  };

  const title = getTitle();

  const handleToggleTheme = (thm) => {
    dispatch(toggleTheme(thm));
  };

  const handleOpen = () => {
    setOpenLogOut(true);
    setAvatarPopoverOpen(false); // Close the other Popper
  };

  const filteredNav = nav
    .filter((navItem) => {
      // if (navItem.title === "Chat" || navItem.title === "Dashboard") {
      if (navItem.title === "Chats") {
        return true;
      }
      return rolbase?.resources?.some(
        (filterItem) => navItem.title === filterItem.title
      );
    })
    .map((navItem) => {
      if (navItem.title === "Chats") {
        return {
          ...navItem,
        };
      }
      return navItem;
    });
  // console.log(rolbase);
  // console.log("filteredNav", filteredNav);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAvatarPopoverOpen(!avatarPopoverOpen);
    setHelloPopperOpen(false); // Close the other Popper
  };

  const handleCloseAvatarPopover = () => {
    setAvatarPopoverOpen(false);
  };

  const handleBellClick = (event) => {
    setAnchorEl(event.currentTarget);
    setHelloPopperOpen(!helloPopperOpen);
    setAvatarPopoverOpen(false); // Close the other Popper
  };

  const handleButtonClick = (buttonLabel) => {
    setActiveButton(buttonLabel);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}

      <AppBar
        className={`${themeColorsObject?.app_bar_class} appbar-shadow  `}
        style={{
          backgroundColor: themeColorsObject?.megaCardBackgroundColor,
        }}
        position="fixed"
        open={open}
      >
        <span className="d-flex justify-content-between ps-4 pe-2 p-2">
          <div className="d-flex align-items-center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpen(!open)}
              edge="start"
              className="rounded-2"
              sx={{ mr: 2, ...(open && { display: "" }) }}
            >
              {open ? (
                <MenuOpenIcon style={{ fontSize: 26 }} />
              ) : (
                <MenuIcon style={{ fontSize: 26 }} />
              )}
            </IconButton>

            <span
              style={{
                borderStartStartRadius: "40%",
                borderBottomLeftRadius: "40%",
              }}
              className="overflow-hidden d-flex"
            >
              <span
                className="cad-fw-700 d-flex align-items-end"
                style={{
                  color: "#4CCD8D",
                  fontSize: "28px",
                  // opacity: open ? 0 : 1,
                  transform: open ? "translateX(-140px)" : "translateX(0px)",
                  transition: "0.5s",
                }}
              >
                Cloudify
              </span>
              <span
                className="text-primary d-flex align-items-end cad-fw-500"
                style={{
                  fontSize: "26px",
                  // opacity: open ? 0 : 1,
                  transform: open ? "translateX(-200px)" : "translateX(0px)",
                  transition: "0.8s",
                }}
              >
                Docs
              </span>
            </span>

            {/* <Typography variant="h6" noWrap component="div">
              {title}
            </Typography> */}
          </div>

          <div className="header-right d-flex align-items-center gap-3">
            <div>
              <Tooltip title="Profile Pic">
                <div className="d-flex gap-3 align-items-center">
                  <Avatar
                    alt={profileData?.first_name}
                    src={
                      process.env.REACT_APP_BACKEND_BASEURL +
                      "/" +
                      profileData?.profile_photo
                    }
                    onClick={handleAvatarClick} // Open popper when avatar is clicked
                    className="pointer" // Make the avatar clickable
                  />

                  <div className="d-flex flex-column me-2">
                    <span className="cad-fw-600 text- cad-text-medium">
                      Hello, {profileData?.first_name} {profileData?.last_name}
                    </span>
                    <span className="cad-text-700 text-primary cad-text-small ">
                      {profileData?.email_address}
                    </span>
                  </div>
                </div>
              </Tooltip>
              <Popper
                open={avatarPopoverOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      className="m-2"
                      style={{
                        width: "220px",
                      }}
                    >
                      <Link
                        onClick={handleCloseAvatarPopover}
                        to="/profile"
                        className="d-flex text-decoration-none p-3 pointer align-items-center gap-3"
                      >
                        {/* <div className="d-flex text-decoration-none p-3 pointer align-items-center gap-3"> */}
                        <Avatar
                          alt={profileData?.first_name}
                          src={
                            process.env.REACT_APP_BACKEND_BASEURL +
                            "/" +
                            profileData?.profile_photo
                          }
                          style={{
                            width: "30px",
                            height: "30px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#4A5057",
                          }}
                          variant="subtitle2"
                        >
                          Profile
                        </Typography>
                        {/* </div> */}
                      </Link>

                      <Link onClick={handleOpen} className="d-flex text-decoration-none border-top  p-3 pointer align-items-center gap-3">
                        <LogoutTwoToneIcon
                          style={{
                            width: "26px",
                            height: "26px",
                          }}
                        />

                        <Typography
                          sx={{
                            fontWeight: "400",
                            color: "#4A5057",
                          }}
                          variant="subtitle2"
                        >
                          Logout
                        </Typography>
                      </Link>
                    </Paper>
                  </Fade>
                )}
              </Popper>
            </div>

            {/* notification code */}

            {/* <div>
              <Tooltip title="Notifcation">
                <Badge
                  onClick={handleBellClick}
                  className="pointer"
                  badgeContent={4}
                  color="error"
                >
                  <BiBell size={22} className="bell cad-fw-800" />
                </Badge>
              </Tooltip>
              <Popper
                open={helloPopperOpen}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                sx={{ zIndex: 1200 }}
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={0}>
                    <div
                      className="m-2"
                      style={{
                        width: "330px",
                      }}
                    >
                      <Paper
                        elevation={2}
                        style={{
                          backgroundColor: "#548CFF",
                        }}
                        className="row pt-3"
                      >
                        <div className="col-7 d-flex align-items-center">
                          <Typography
                            style={{
                              fontSize: "18px",
                              color: "#ffffff",
                            }}
                            variant="h6"
                          >
                            Notifications
                          </Typography>
                        </div>

                        <div className="col-5 d-flex align-items-center justify-content-end">
                          <Paper
                            style={{
                              fontSize: "12px",
                            }}
                            className="fw-bold p-1 ps-2 pe-2"
                          >
                            4 New
                          </Paper>
                        </div>

                        <div className="mt-3 d-flex justify-content-start">
                          <Button
                            onClick={() => handleButtonClick("All")}
                            variant="text"
                            style={{
                              backgroundColor:
                                activeButton === "All" ? "#FFFFFF" : "#548CFF",
                              color:
                                activeButton === "All" ? "#548CFF" : "#FFFFFF",
                              borderRadius: "4px 4px 0 0",
                              fontSize: "12px",
                            }}
                          >
                            All
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("Messages")}
                            variant="text"
                            style={{
                              backgroundColor:
                                activeButton === "Messages"
                                  ? "#FFFFFF"
                                  : "#548CFF",
                              color:
                                activeButton === "Messages"
                                  ? "#548CFF"
                                  : "#FFFFFF",
                              borderRadius: "4px 4px 0 0",
                              fontSize: "12px",
                            }}
                          >
                            Messages
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("Alerts")}
                            variant="text"
                            style={{
                              backgroundColor:
                                activeButton === "Alerts"
                                  ? "#FFFFFF"
                                  : "#548CFF",
                              color:
                                activeButton === "Alerts"
                                  ? "#548CFF"
                                  : "#FFFFFF",
                              borderRadius: "4px 4px 0 0",
                              fontSize: "12px",
                            }}
                          >
                            Alerts
                          </Button>
                        </div>
                        <Paper elevation={0} square>
                          {activeButton === "All" ? (
                            <NotificationAll />
                          ) : activeButton === "Alerts" ? (
                            <Alerts />
                          ) : (
                            <Message />
                          )}
                        </Paper>
                      </Paper>
                    </div>
                  </Fade>
                )}
              </Popper>
            </div> */}

            <div className="rounded-2 p-1 theme-button">
              <Tooltip title="Theme">
                {theme === "light" ? (
                  // <LightModeIcon className=" text-warning pointer" />
                  <WbSunnyIcon
                    onClick={() => handleToggleTheme("dark")}
                    className=" text-warning pointer sun"
                  />
                ) : (
                  <DarkModeIcon
                    onClick={() => handleToggleTheme("light")}
                    className=" text-white pointer"
                  />
                )}
              </Tooltip>
            </div>
          </div>
        </span>

        <LogoutModal open={openLogOut} setOpen={setOpenLogOut} />

        {/* {open ? <></>:  <MiniHorizontalNavbar  route={getTitle()} path={path} /> } */}
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: themeColorsObject?.megaCardBackgroundColor,
            color: themeColorsObject?.textColor,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader className="w-100 d-flex  justify-content-center gap-2">
          {/* <span
            className="cad-fw-700 d-flex align-items-end"
            style={{ color: "#4CCD8D", fontSize: "30px" }}
          >
            CAD
          </span>
          <span
            className="text-primary d-flex align-items-end cad-fw-500"
            style={{ fontSize: "28px" }}
          >
            ITSolution
          </span> */}

          <span
            style={{
              borderStartStartRadius: "40%",
              borderBottomLeftRadius: "40%",
            }}
            className="overflow-hidden d-flex"
          >
            <span
              className="cad-fw-700 d-flex align-items-end"
              style={{
                color: "#4CCD8D",
                fontSize: "28px",
                // opacity: open ? 0 : 1,
                transform: open ? "translateX(0px)" : "translateX(140px)",
                transition: "0.5s",
              }}
            >
              Cloudify
            </span>
            <span
              className="text-primary d-flex align-items-end cad-fw-500"
              style={{
                fontSize: "26px",
                // opacity: open ? 0 : 1,
                transform: open ? "translateX(0px)" : "translateX(200px)",
                transition: "0.8s",
              }}
            >
              Docs
            </span>
          </span>
        </DrawerHeader>

        {/* <Divider /> */}

        <div className="d-flex flex-column h-100 p-2 pt-0 justify-content-between">
          {rolbase?.resources?.length === 0 ? (
            <List>
              {nav.map(({ title, path, icon, items }, index) =>
                items ? (
                  <ListItem className=" mb-4" key={title} disablePadding>
                    <Accordion
                      themeColorsObject={themeColorsObject}
                      dropLinks={items}
                      title={title} // Corrected 'link.title'
                      size={23}
                      Icon={icon} // Corrected 'link.icon'
                    />
                  </ListItem>
                ) : (
                  <ListItem className="w-100 mb-1" key={title} disablePadding>
                    <SlideBtn
                      themeColorsObject={themeColorsObject}
                      title={title}
                      path={path}
                      size={22}
                      Icon={icon}
                    />
                  </ListItem>
                )
              )}
            </List>
          ) : (
            <List>
              {filteredNav.map(({ title, path, icon, items }, index) =>
                items ? (
                  <ListItem className=" mb-4" key={title}>
                    <Accordion
                      themeColorsObject={themeColorsObject}
                      dropLinks={items}
                      title={title} // Corrected 'link.title'
                      size={22}
                      Icon={icon} // Corrected 'link.icon'
                    />
                  </ListItem>
                ) : (
                  <ListItem key={title} disablePadding>
                    <SlideBtn
                      themeColorsObject={themeColorsObject}
                      title={title}
                      path={path}
                      size={22}
                      Icon={icon}
                    />
                  </ListItem>
                )
              )}
            </List>
          )}
        </div>

        {/* {open?  <></> : } */}
      </Drawer>

      <Main
        style={{
          backgroundColor: theme === "light" ? "#F1F2F6" : "#292F4C",
          padding:
            path === "/template-forms/new-form"
              ? 0
              : true
                ? "62px 20px 24px 20px"
                : "86px 20px 24px 20px",
        }}
        open={open}
      >
        {true ? (
          <CustomizedBreadcrumbs route={getTitle()} path={path} />
        ) : (
          <></>
        )}

        <AppContent />

        {/* {path === "/template-forms/new-form" ? "" : <Footer />} */}
        {path === "/template-forms/new-form" ? "" : ""}
      </Main>
    </Box>
  );
}

const AppContent = () => {
  return (
    <Routes>
      {RouteLink.map(({ path, component }, index) => (
        <Route key={index} path={path} element={component} />
      ))}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
