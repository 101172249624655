import { Typography } from "@mui/material";
import { Box } from "@mui/system";

const NoDataFound = ({ title, icon, subtitle }) => {
  return (
    <Box
      sx={{
        mx: "auto",
        justifyItems: "center",
        alignContent: "center",
        textAlign: "center",
      }}
    >
      <Box>{icon}</Box>
      <Typography variant="h6">{title}</Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
    </Box>
  );
};
export default NoDataFound;
