import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import produce from "immer";
import { faqCategoryService } from "../../service/endpoints";

export const faqCategoryGetAll = createAsyncThunk(
  "faqCategory/getAll",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await faqCategoryService.get(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const faqCategoryGetById = createAsyncThunk(
  "faqCategory/getById",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await faqCategoryService.getById(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const faqCategoryUpdate = createAsyncThunk(
  "faqCategory/update",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await faqCategoryService.update(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);

const faqCategoryCategorySlice = createSlice({
  name: "faqCategory",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    dataById: {},
    id: null,
    size: 10,
    page: 1,
    totalPage: 1,
    search: null,
    searchHistory: [],
  },
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    resetGetIdData: (state) => {
      state.dataById = {};
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(faqCategoryGetAll.pending, handlePending)
      .addCase(faqCategoryGetAll.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(faqCategoryGetAll.rejected, handleRejected);
    builder
      .addCase(faqCategoryGetById.pending, handlePending)
      .addCase(faqCategoryGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(faqCategoryGetById.rejected, handleRejected);
    builder
      .addCase(faqCategoryUpdate.pending, handlePending)
      .addCase(faqCategoryUpdate.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(faqCategoryUpdate.rejected, handleRejected);
  },
});

export default faqCategoryCategorySlice.reducer;
export const { closeError, resetGetIdData } = faqCategoryCategorySlice.actions;
