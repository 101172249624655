import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import produce from "immer";
import { gdprService } from "../../service/endpoints";

export const gdprGetAll = createAsyncThunk(
  "gdpr/getAll",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await gdprService.get(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const gdprGetById = createAsyncThunk(
  "gdpr/getById",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await gdprService.getById(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const gdprUpdate = createAsyncThunk(
  "gdpr/update",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await gdprService.update(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const gdprDelete = createAsyncThunk(
  "gdpr/delete",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await gdprService.delete(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);

const gdprSlice = createSlice({
  name: "gdpr",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    dataById: {},
    id: null,
    size: 10,
    page: 1,
    totalPage: 1,
    search: null,
    searchHistory: [],
  },
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    resetGetIdData: (state) => {
      state.dataById = {};
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(gdprGetAll.pending, handlePending)
      .addCase(gdprGetAll.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(gdprGetAll.rejected, handleRejected);
    builder
      .addCase(gdprGetById.pending, handlePending)
      .addCase(gdprGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.dataById;
      })
      .addCase(gdprGetById.rejected, handleRejected);
    builder
      .addCase(gdprUpdate.pending, handlePending)
      .addCase(gdprUpdate.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(gdprUpdate.rejected, handleRejected);
    builder
      .addCase(gdprDelete.pending, handlePending)
      .addCase(gdprDelete.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(gdprDelete.rejected, handleRejected);
  },
});

export default gdprSlice.reducer;
export const { closeError, resetGetIdData } = gdprSlice.actions;
