import { Avatar, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GroupList from './GroupList'
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CameraAltTwoToneIcon from "@mui/icons-material/CameraAltTwoTone";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addGroup } from "../../features/chat/group.slice";
import { Box } from "@mui/system";

export const GroupCreateSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  description: Yup.string().notRequired(),
  profile: Yup.mixed()
    .notRequired()
    .test("fileType", "Unsupported File Format", (value) => {
      const isValidType = (file) => ['image/jpeg', 'image/gif'].includes(file.type);
      if (value instanceof FileList || Array.isArray(value)) {
        return Array.from(value).every(isValidType);
      } else if (value) {
        return isValidType(value);
      }
      return true;
    })
    .test("fileSize", "File too large", (value) => {
      const maxSize = 5 * 1024 * 1024; // 5MB
      const isValidSize = (file) => file.size <= maxSize;
      if (value instanceof FileList || Array.isArray(value)) {
        return Array.from(value).every(isValidSize);
      } else if (value) {
        return isValidSize(value);
      }
      return true;
    }),
});

const NewGroupChatModal = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const [openUserList, setOpenUserList] = useState(false);
  const [profileSrc, setProfileSrc] = useState("");
  const { themeColorsObject } = useSelector((state) => state.theme);
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    register,
    reset,
  } = useForm({
    resolver: yupResolver(GroupCreateSchema),
    mode: 'all'
  })
  const onNextSubmit = (value) => {
    dispatch(addGroup(value))
    reset()
    onClose()
    setOpenUserList(true)
  }

  const onChangeImageHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileSrc(event.target.result);
      };
      reader.readAsDataURL(file);
    }
    setValue('profile', e.target.files[0])
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="cad-modal-blur"
      >
        <div
          className="rounded-2 "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 480,
            height: "75%",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="d-flex justify-content-center">
            <h4 className="p-3 cad-fw-700">New Group Chat</h4>
          </div>
          <form onSubmit={handleSubmit(onNextSubmit)}>
            <div className="d-flex justify-content-center position-relavtive flex-column mb-3 p-3 pt-0 align-items-center gap-4">

              <Box className="text-center" display={'block'} alignItems={'center'} justifyContent={'center'}>
                <Box justifyContent={'center'} display={'flex'}>
                  <div
                    style={{
                      width: "7rem",
                      position: "relative",
                    }}
                  >
                    <Avatar
                      className="m-1"
                      style={{
                        width: "100px",
                        height: "100px",
                        border: "4px solid #E9E9E9",
                      }}
                      alt="Remy Sharp"
                      src={profileSrc}
                    />

                    <input
                      type="file"
                      id="profile-image-input"
                      accept="image/*"
                      style={{
                        display: "none",
                      }}
                      {...register('profile')}
                      onChange={onChangeImageHandler}
                    />

                    <label
                      style={{
                        position: "absolute",
                        bottom: "1rem",
                        right: "0.2rem",
                        height: "30px",
                        width: "30px",
                        backgroundColor: "#E9E9E9",
                        padding: "4px",
                        borderRadius: "50%",
                        cursor: "pointer",
                      }}
                      htmlFor="profile-image-input"
                    >
                      <CameraAltTwoToneIcon
                        sx={{
                          position: "absolute",
                          bottom: "0rem",
                          right: "0rem",
                          height: "30px",
                          width: "30px",
                          backgroundColor: "#E9E9E9",
                          padding: "4px",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                      />
                    </label>
                  </div>
                </Box>
                {errors && errors?.profile &&
                  <Typography color={'red'} variant="caption" className="text-center" display="block" gutterBottom>
                    {errors?.profile?.message}
                  </Typography>
                }
              </Box>

              <Box width={"100%"}>
                <input
                  name="name"
                  type="text"
                  placeholder="Group name"
                  className={`form-control cad-text-xlarge px-2 cad-fw-700 cad-text-medium rounded-0 text-center  ${themeColorsObject.themed_input_class}`}
                  {...register('name')}
                />
                {errors && errors?.name &&
                  <Typography variant="caption" color={'red'} className="text-center" display="block" gutterBottom>
                    {errors?.name?.message}
                  </Typography>
                }
              </Box>

              <Box width={"100%"}>
                <textarea
                  type="text"
                  name="description"
                  placeholder="Description.."
                  className={`form-control cad-text-medium p-2 cad-fw-700 cad-text-medium rounded-0 text-center  ${themeColorsObject.themed_input_class}`}
                  rows={2}
                  {...register('description')}
                />
                {errors && errors?.description &&
                  <Typography variant="caption" color={'red'} className="text-center" display="block" gutterBottom>
                    {errors?.description?.message}
                  </Typography>
                }
              </Box>
            </div>

            <Button
              className=" d-flex align-items-center justify-content-center bg-primary"
              variant="contained"
              type="submit"
              style={{
                height: 60,
                width: 50,
                borderRadius: "50%",
                position: "absolute",
                right: 16,
                bottom: 16,
              }}
            >
              <ArrowForwardIcon className="fs-2" />
            </Button>
          </form>

        </div>
      </Modal>
      <GroupList
        open={openUserList}
        onClose={() => setOpenUserList(false)}
      />
    </>
  );
};

export default NewGroupChatModal;
