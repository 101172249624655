// UserSuspendModal.jsx
import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, Modal, Paper, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { userThunkAPI } from "../../../features/users/user.slice";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { contactService } from "../../../service/endpoints";
import { enqueueSnackbar } from "notistack";
import { contactThunkAPI } from "../../../features/contact/contact.slice";
import BackdropWithLoader from "../../BackdropWithLoader ";

const ContactReplyModal = ({ id, handleClose, open, }) => {
    const dispatch = useDispatch();

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const details = await contactService.deatils(id);
                // Handle response data here
                setData(details?.data?.data)
                setLoading(false)
                // console.log('Payment details:', details?.data?.data);
            } catch (error) {
                // Handle error here
                // console.error('Error fetching payment details:', error);
            }
        };

        if (open && id) {
            fetchData();
        }
    }, [open, id]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 550,
        height: 450,
        overflow: 'scroll',
        bgcolor: "#fff",
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
    };

    const subAdminSchema = yup.object().shape({
        reply: yup.string()
            .required("Please fill this field")
            .max(300),
    })

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({ mode: "all", resolver: yupResolver(subAdminSchema) });

    const onSubmit = async (value) => {
        // console.log(value);
        const formData = new FormData()
        formData.append("reply", value)

        try {
            // Make API call using reply function
            const response = await contactService.reply(id, value);
            // console.log(response); // Log the response if needed
            if (response.status === 200) {
                dispatch(contactThunkAPI.getAll());
                enqueueSnackbar("Message send Successfully", {
                    variant: "success"
                })
                handleClose()
            }
            // Handle success scenario
        } catch (error) {
            console.error('Error occurred while making API call:', error);
            // Handle error scenario
        }
    }

    return (
        <>
            {loading ? <BackdropWithLoader open={loading} /> : (
                <div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper elevation={2}>
                            <Box sx={style}>
                                <div className="modalhead d-flex justify-content-between align-items-center mb-2">
                                    <h2 className="fs-5 mb-0">Reply to: {data?.email}</h2>
                                    <h3 onClick={handleClose} className="text-end pointer">
                                        <CloseIcon />
                                    </h3>
                                </div>
                                <div>
                                    <p className="mb-2">Message:</p>
                                    <p style={{
                                        background: '#d3ebfc',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        color: 'blue'
                                    }}>{data?.contactType}</p>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="mb-3">
                                        <TextField
                                            fullWidth
                                            error={errors.reply ? true : false}
                                            label="Reply"
                                            helperText={errors.reply?.message}
                                            {...register("reply")}
                                            multiline
                                            rows={4}
                                        />
                                    </div>
                                    <Button type="submit" variant="contained">
                                        Submit
                                    </Button>
                                </form>
                            </Box>
                        </Paper>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default ContactReplyModal;
