import React, { useEffect } from "react";
import './Chat.css'
import ChatsLeft from "../Components/ChatComponent/ChatsLeft";
import ChatsRight from "../Components/ChatComponent/ChatsRight";
import io from "socket.io-client";
import TokenService from "../service/token.service";
import { useDispatch, useSelector } from "react-redux";
import { chatThnkApi, setSocket } from "../features/chat/chats.slice";

const Chat = () => {
  const { themeColorsObject } = useSelector((state) => state.theme);
  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      try {
        dispatch(chatThnkApi.getChatList())
        dispatch(chatThnkApi.getUsersList())
      } catch (error) {
        console.log(error);
      }
    })()
  }, [dispatch])


  useEffect(() => {
    const token = TokenService.getLocalAccessToken();
    const newSocket = io(process.env.REACT_APP_BACKEND_BASEURL, {
      withCredentials: false,
      auth: { token },
    });
    dispatch(setSocket(newSocket));
    return () => {
      newSocket.disconnect();
      dispatch(setSocket(null));
    };
  }, [dispatch]);

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
      }}
      className=" cad-shadow mt-1   rounded-3 mb-4 pt-2">
      <div className="d-flex w-100  h-100">
        <div className="col-4  h-100">
          <ChatsLeft />
        </div>

        <div
          style={{ borderLeft: "1px solid #DEE2E6", }}
          className="col-8 p-0 h-100"
        >
          <ChatsRight />
        </div>
      </div>
    </div>
  );
};
export default Chat;
