import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Paper } from "@mui/material";
import ChangePassword from "../../Components/Setting/ChangePassword";
import Notification from "../../Components/Setting/Notification";

const Settings = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="support w-100 p-3 h-100 d-flex flex-column ">
      <Paper
        style={{
          width: "100%",
          height:"100%",
        }}
        elevation={2}
        square={true}
        className="rounded-top-3 support-panel"
      >
        <Tabs value={value} onChange={handleChange} left="true">
          <Tab
            label="Notification"
            style={{ width: "12%", textTransform: "none", fontSize: "14px",fontWeight:'600' }}
          />
          <Tab
            label="Change Password"
            style={{ width: "20%", textTransform: "none", fontSize: "14px" ,fontWeight:'600'}}
          />
        </Tabs>
      
      {value === 0 && <Notification/>}
      {value === 1 && <ChangePassword/>}           
      </Paper>
    </div> 
  );
};

export default Settings;
