import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subsService } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";


export const subsThunkAPI = {
    getAll: createAsyncThunk("subs/getAll", async (searchTerm, { getState }) => {
        try {
            const { size, currentPage } = getState().subscription
            const params = getRequestParams(searchTerm, currentPage, size)
            // console.log(size, currentPage, searchTerm);
            const res = await subsService.get(params);
            // console.log(res);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }
    ),
}

const subsSlice = createSlice({
    name: "subscription",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 0,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: [],
        activeCount: null,
        graphData: []
    },

    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action?.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action?.payload;
        }
    }, 
    extraReducers: (builder) => {
        builder
            .addCase(subsThunkAPI.getAll.pending, handlePending)
            .addCase(subsThunkAPI.getAll.fulfilled, (state, action) => {
                // console.log(action.payload.data.rows);
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data;
                state.rows = action?.payload?.data?.rows;
                state.graphData = action?.payload?.data?.rows;
                state.totalItems = action?.payload?.data?.totalItems;
                state.totalPages = action?.payload?.data?.totalPages;
                state.currentPage = action?.payload?.data?.currentPage;
                state.activeCount = (action?.payload?.data?.rows)
                state.isLoading = false;
            })
            .addCase(subsThunkAPI.getAll.rejected, handleRejected);
    }
})

const filterdata = (data) => {
    return data.map((ele) => {
        return ({
            id: ele.id,
            title: ele.title,
            active: ele.active,
            amount: parseInt(ele.product_prices[0].unit_amount) / 100 + " " + ele.product_prices[0].currency,
            status: ele.active,
            subscription_type: ele?.product_prices[0]?.recurring
        })
    });
}

const subCount = (data) => {
    let count = 0;
    data.forEach((ele) => {
        if (ele.active === true) {
            count++;
        }
    });
    return count;
}


export default subsSlice.reducer;
export const { nextPage, previousPage } = subsSlice.actions;
