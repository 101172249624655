import { useSelector } from "react-redux";
import * as themeColors from "../../../utils/Theme/Colors";
import { Box, Stack } from "@mui/system";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { packageSubscription } from "../../../service/endpoints";
import {
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import StorageIcon from "@mui/icons-material/Storage";
import GroupIcon from "@mui/icons-material/Group";
import DomainIcon from "@mui/icons-material/Domain";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import {
  formatDate,
  formatDateShort,
} from "../../../utils/converter/date.converter";
import EditPackageDrawer from "../../../Components/Darwer/package/EditPackageDrawer";
import AddPalnDrawer from "../../../Components/Darwer/plan/AddPlanDrawer";
import StickyHeadTable from "../../../Components/Table/StickyHeadTable";
import { enqueueSnackbar } from "notistack";

const PackageDetails = () => {
  const { id } = useParams();
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const [open, setOpen] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const [details, setDetails] = useState({
    id: undefined,
    title: undefined,
    users: undefined,
    storage: Number,
    domain: Boolean,
    stripe_product_id: undefined,
    status: Boolean,
    metadata: "",
    created_by: "",
    modified_by: "",
    createdAt: Date,
    updatedAt: Date,
    deletedAt: Date,
    plans: [],
  });

  const columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "amount", headerName: "Amount", width: 130 },
    { field: "stripe_id", headerName: "Stripe id", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
  ];

  const _rows = details?.plans.map((row, index) => ({
    id: row.id,
    amount: row.unit_amount + " " + row.currency,
    stripe_id: row.stripe_price_id,
    status: row.status ? (
      <Chip label={"Active"} variant="outlined" color="success" size="small" />
    ) : (
      <Chip label={"In-Active"} variant="outlined" color="error" size="small" />
    ),
    createdAt: formatDate(row.createdAt),
  }));

  const getById = async (id) => {
    try {
      const { data } = await packageSubscription.getById(id);
      setDetails(data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getById(id);
  }, [id]);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleArchive = (id) => async () => {
    // alert(id);
    try {
      const response = await packageSubscription.togglestatus(id);
      if (response?.status === 200) {
        getById(id);
        enqueueSnackbar(response?.data?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
      if (error.data && error.data.error) {
        enqueueSnackbar(error?.data?.error?.message, {
          variant: "error",
        });
      }
    }
  };
  return (
    <Box
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        // transition:"0.3s"
      }}
      className="w-100 overflow-auto mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
    >
      <EditPackageDrawer
        open={open}
        onClose={toggleDrawer(false)}
        details={details}
      />
      <AddPalnDrawer
        open={openPlan}
        onClose={() => setOpenPlan(false)}
        packageID={id}
      />
      <Box sx={{ p: 3, width: "100%" }}>
        <Box sx={{ pt: 0, flexGrow: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{ mb: 2 }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  compone="section"
                  sx={{
                    p: 0,
                    // border: "1px solid #777676",
                    borderRadius: "20%",
                    mr: 2,
                  }}
                >
                  <SpaceDashboardIcon sx={{ color: "#078DEE " }} />
                </Box>
                <label className="cad-fw-500 cad-text-xlarge">
                  {details.title}
                </label>
              </Stack>
            </Box>
            <Button
              color="error"
              variant="outlined"
              onClick={handleArchive(id)}
            >
              Toggle status
            </Button>
          </Stack>
          <Divider
            sx={{
              borderBottom: "1px solid #777676",
              fontWeight: 800,
              color: "#e8eaf6",
            }}
          />
        </Box>
        <Box sx={{ py: 2, flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid className="w-25" item sx={{ mr: 2 }}>
              <Typography gutterBottom variant="subtitle2" color={"#757575"}>
                Updated
              </Typography>
              <Typography gutterBottom variant="subtitle2" fontWeight={600}>
                {formatDateShort(details.updatedAt)}
              </Typography>
            </Grid>
            {/* <Divider
              sx={{ borderRight: "1px solid #777676", fontWeight: 800 }}
              orientation="vertical"
              variant="middle"
              flexItem
            /> */}
            <Grid item>
              <Typography gutterBottom variant="subtitle2" color={"#757575"}>
                Created by
              </Typography>
              <Typography gutterBottom variant="subtitle2" fontWeight={600}>
                Admin
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Details Page */}
        <Divider
          sx={{
            borderBottom: "1px solid #777676",
            fontWeight: 800,
            color: "#e8eaf6",
          }}
        />
        <Box sx={{ Py: 2, flexGrow: 1, marginTop: "15px" }}>
          <Grid container spacing={2} direction={"column"}>
            {/* Header */}
            <Grid item>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <label className="cad-text-large cad-fw-600">Details</label>
                <Button
                  variant="outlined"
                  startIcon={<EditIcon />}
                  sx={{ mb: 1 }}
                  onClick={toggleDrawer(true)}
                >
                  Edit
                </Button>
              </Stack>
            </Grid>
            {/* Product Information */}
            <Grid item>
              <Grid container>
                <Grid xs={6}>
                  {/* <Box sx={{ w: "100%" }}>
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" color={"#757575"}>
                          Title
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" fontWeight={600}>
                          {details.title}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box> */}
                  <Box sx={{ w: "100%", marginBottom: "10px" }}>
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" color={"#757575"}>
                          Status
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Chip
                          label={details.status ? "Active" : "Not Active"}
                          size="small"
                          color={details.status ? "success" : "error"}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ w: "100%", marginBottom: "10px" }}>
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" color={"#757575"}>
                          Stripe Product Id
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="subtitle2" fontWeight={400}>
                          {details.stripe_product_id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid xs={6}>
                  <Box sx={{ w: "100%", marginBottom: "10px" }}>
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" color={"#757575"}>
                          <StorageIcon fontSize="small" color="primary" />{" "}
                          Storage
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" fontWeight={400}>
                          {details.storage} GB
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ w: "100%", marginBottom: "10px" }}>
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" color={"#757575"}>
                          <GroupIcon fontSize="small" color="primary" /> User
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" fontWeight={400}>
                          {details.users} /per subscripton
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ w: "100%", marginBottom: "10px" }}>
                    <Grid container>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" color={"#757575"}>
                          <DomainIcon fontSize="small" color="primary" /> Domain
                        </Typography>
                      </Grid>
                      <Grid xs={6}>
                        <Typography variant="subtitle1" fontWeight={400}>
                          {details.domain ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Pricing table */}
        <Divider
          sx={{
            borderBottom: "1px solid #777676",
            fontWeight: 800,
            color: "#e8eaf6",
          }}
        />
        <Box sx={{ Py: 2, flexGrow: 1, marginTop: "15px" }}>
          <Grid container spacing={2} direction={"column"}>
            {/* Header */}
            <Grid item>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems="center"
              >
                <label className="cad-text-large cad-fw-600">Plans</label>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  sx={{ mb: 1 }}
                  onClick={() => setOpenPlan(true)}
                >
                  Add Plan
                </Button>
              </Stack>
            </Grid>
            <Grid item>
              {!details.plans.length ? (
                <Box
                  sx={{
                    mx: "auto",
                    justifyItems: "center",
                    alignContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Box>
                    <LocalOfferIcon color="success" />
                  </Box>
                  <Typography variant="h6">Add a price</Typography>
                  <Typography variant="subtitle1">
                    Adding a price lets you sell this product to customers.
                  </Typography>
                </Box>
              ) : (
                <StickyHeadTable
                  columns={columns}
                  rows={_rows}
                  defaultRowsPerPage={5}
                  defaultPage={0}
                  totalRows={details?.plans.length}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default PackageDetails;
