import * as yup from "yup";
export const packageSchema = yup.object().shape({
    title: yup
        .string().required('Title is required')
        .required('Title is required'),

    users: yup.number()
        .typeError('Users is required')
        .integer('Users must be an integer')
        .positive('Users must be greater than zero')
        .required('Users must be a number')
        .transform((value, originalValue) => {
            return isNaN(originalValue) ? undefined : value;
        }),

    storage: yup.number()
        .typeError('Storage is required')
        .integer('Storage must be an integer')
        .positive('Storage must be greater than zero')
        .required('Storage must be a number')
        .transform((value, originalValue) => {
            return isNaN(originalValue) ? undefined : value;
        }),

    domain: yup
        .string().required('Domain is required')
        .oneOf(["true", "false"], "Please select domain"),
})