const getRequestParams = (inputValue, page, pageSize, startDate, endDate) => {
    let params = {};
  
    if (inputValue) {
      params['search'] = inputValue;
    }
  
    if (page) {
      params['page'] = page;
    }
  
    if (pageSize) {
      params['size'] = pageSize;
    }
  
    if (startDate) {
      params['startDate'] = startDate;
    }
  
    if (endDate) {
      params['endDate'] = endDate;
    }
  
    return params;
  };
  
  export default getRequestParams;
  