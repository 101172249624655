import React from 'react'

const CheckBox = ({ isChecked, handleHandleChange }) => {
  return (
    <label className="containerr">
      <input onChange={handleHandleChange} checked={isChecked} type="checkbox" />
      <div className="checkmark"></div>
    </label>
  )
}

export default CheckBox