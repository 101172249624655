// UserSuspendModal.jsx
import React, { useEffect } from "react";
import { Box, Button, Modal, Paper } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { userThunkAPI } from "../../../features/users/user.slice";
import { refreshData, userArchiveThunkAPI } from "../../../features/users/user.archive.slice";
import { enqueueSnackbar } from "notistack";

const UserSuspendModal = ({ handleClose, open, id }) => {
    const dispatch = useDispatch();

    const handleSoftDelete = (id) => {
        // Dispatch soft delete action
        dispatch(userThunkAPI.softDelete(id));
        dispatch(userArchiveThunkAPI.getArchiveList());
        dispatch(userThunkAPI.getAll());
        // dispatch(refreshData())
        // console.log(id);
        handleClose()
        enqueueSnackbar("User account suspended", {
            variant: "success"
        });
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "#fff",
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={2}>
                    <Box sx={style}>
                        <div className="modalhead d-flex justify-content-center align-items-center mb-2">
                            <IoCloseCircleOutline size={120} color="red" />
                        </div>
                        <div>
                            <h2 className="fs-4 mb-0 text-center mb-4">
                                Are you sure you want to suspend this account?
                            </h2>
                        </div>
                        <div className="row w-100">
                            <div className="col-6 m-auto d-flex">
                                <Button
                                    onClick={handleClose}

                                    className="w-100"
                                    variant="outlined"
                                    color="error"
                                >
                                    No
                                </Button>
                            </div>

                            <div className="col-6 m-auto d-flex">
                                <Button
                                    onClick={() => handleSoftDelete(id)}
                                    className="button-outlined w-75"
                                    variant="outlined"
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </Modal>
        </div>
    );
};

export default UserSuspendModal;
