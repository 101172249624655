import React, { useEffect, useState } from 'react';
import TableData from '../../../Components/TableData';
import { useDispatch, useSelector } from 'react-redux';
import * as themeColors from '../../../utils/Theme/Colors'
import { Button, Chip, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { nextPage, subsThunkAPI } from '../../../features/subscription/subscription.slice';
import SubInactiveModal from '../../../Components/Modals/subscription/subscription.Inactive.modal';
import SubActiveModal from '../../../Components/Modals/subscription/subscription.active.modal';
import SearchInput from '../../../Components/SearchInput/SearchInput';
import Refresher from '../../../Components/Refresher';
import AddIcon from "@mui/icons-material/Add";
import PrimaryButton from '../../../Components/PrimaryButton';
import { packageThunkAPI } from '../../../features/subscription/package.slice';
import { Domain } from '@mui/icons-material';
import DateFormatter from '../../../Components/DateFormatter';
import { formatDate } from '../../../utils/converter/date.converter';
import AddPackageDrawer from '../../../Components/Darwer/package/AddPackageDrawer';

const PackageTable = () => {

  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");

  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(subsThunkAPI.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(nextPage(1));
    dispatch(subsThunkAPI.getAll(searchTerm));
  }, [dispatch, searchTerm]);

  const { totalPages, rows, currentPage, isLoading } = useSelector((state) => state.subscription);
  // console.log(rows);

  // trigger modal
  const [activeStatus, setActiveStatus] = useState(false);
  const [inactiveStatus, setInactiveStatus] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);


  const closeModal = () => {
    setActiveStatus(false);
    setInactiveStatus(false)
    setSelectedUserId(null)
  };

  const cellConfig = [
    // { dataKey: "sr_no", align: "left", width: 15 },
    { dataKey: "status", align: "left", width: 10 },
    { dataKey: "title", align: "left", width: 20 },
    { dataKey: "users", align: "left", width: 20 },
    { dataKey: "domain", align: "left", width: 20 },
    { dataKey: "date_created", align: "left", width: 20 },
  ];

  const tableHeadRowCell = [
    // {
    //   title: "SR NO.",
    //   width: "15%",
    //   align: "left",
    // },
    {
      title: "Status",
      width: "10%",
      align: "left",
    },
    {
      title: "Package title",
      width: "20%",
      align: "left",
    },
    {
      title: "Number of users",
      width: "20%",
      align: "left",
    },
    {
      title: "Domain",
      width: "20%",
      align: "left",
    },
    {
      title: "Date created",
      width: "20%",
      align: "left",
    },
  ];

  const extractedDataArray = rows?.map((row, index) => {
    const extractedData = {
      id: row?.id,
      title: row?.title,
      status: row?.status
        ? <Chip label="Active" size='small' color="success" />
        : <Chip label="Archive" size='small' />,
      users: row?.users,
      domain: row?.domain
        ? <Chip label="Yes" size='small' color="success" variant="outlined" />
        : <Chip label="No" size='small' variant="outlined" color='error' />,
      date_created: formatDate(row.createdAt)

    }
    return extractedData;
  });

  const handlePagination = (value) => {
    dispatch(nextPage(value));
    dispatch(subsThunkAPI.getAll());
  };

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleViewOpen = (id) => () => {
    // console.log(id);
    navigate(`/details-package/${id}`)
  }

  return (
    <>
      <AddPackageDrawer open={open} onClose={toggleDrawer(!open)} />
      <div
        className='ps-3 pe-3 mt-3'
      // style={{
      //   height: "87vh",
      //   backgroundColor: themeColorsObject.megaCardBackgroundColor,
      //   color: themeColorsObject.textColor,
      //   // transition:"0.3s"
      // }}
      // className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start p-3"
      >
        <div className='w-100 my-1'>
          <div className='d-flex justify-content-between row'>
            <div className="col-3 d-flex align-items-center gap-1">
              <SearchInput searchValue={searchTerm} searchFucntion={(e) => setSearchTerm(e.target.value)} />
              <Refresher
                isLoading={isLoading}
                handleRefresh={() => dispatch(subsThunkAPI.getAll())}
              />
            </div>
            <div className="col-9 d-flex justify-content-end">
              <Button variant="outlined" onClick={toggleDrawer(!open)}>
                Add
              </Button>
              {/* <PrimaryButton
              variant="contained"
              title="Add Package"
              padding="0.3rem 0.6rem"
              icon={<AddIcon size={13} />}
              fontSize="14px"
              width="50px"
              path={"/add-package"}
            // onClick={() => console.log('hi')}
            /> */}
            </div>
          </div>
        </div>

        <TableData
          tableHeadRowCell={tableHeadRowCell}
          extractedDataArray={extractedDataArray}
          cellConfig={cellConfig}
          setMouseEnteredId={setMouseEnteredId}
          setMouseEntered={setMouseEntered}
          totalPages={totalPages}
          handlePagination={handlePagination}
          currentPage={currentPage}
          isLoading={isLoading}
          handleViewOpen={handleViewOpen}
        />

        {activeStatus && (
          <SubInactiveModal
            handleClose={closeModal}
            open={activeStatus}
            id={selectedUserId}
          />
        )}

        {inactiveStatus && (
          <SubActiveModal
            handleClose={closeModal}
            open={inactiveStatus}
            id={selectedUserId}
          />
        )}
      </div>
    </>
  );
};

export default PackageTable;
