export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Use 12-hour format
  };

  // Create a custom formatted date string
  const formattedDate = `${dateObject.toLocaleDateString("en-US", options)}`;

  return formattedDate;
};

export const formatDateShort = (dateString) => {
  const dateObject = new Date(dateString);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
  };

  // Create a custom formatted date string
  const formattedDate = `${dateObject.toLocaleDateString("en-US", options)}`;

  return formattedDate;
};