import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getContact } from "../../../service/contact.service";
// import { getContact } from "../../../service/cms/service.contact";

export const fetchContact = createAsyncThunk("fetchContact", async () => {
  try {
    const response = await getContact();
    // console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err);
  }
});

const { v4: uuidv4 } = require('uuid');

const contactSlice = createSlice({
  name: "contactSlice",
  initialState: {
    isLoading: true,
    data: null,
    isError: false,
    rows: [
      {
        id: uuidv4(),
        name: 'email',
        email_address: 'abhishek@gmail.com',
        subject: 'lorem',
        contact_type: 'lorem',
      },
      {
        id: uuidv4(),
        name: 'john',
        email_address: 'john@example.com',
        subject: 'ipsum',
        contact_type: 'ipsum',
      },
      {
        id: uuidv4(),
        name: 'emma',
        email_address: 'emma@example.com',
        subject: 'dolor',
        contact_type: 'dolor',
      },
    ]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContact.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(fetchContact.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        // state.data = action.payload.data;
      })
      .addCase(fetchContact.rejected, (state, action) => {
        state.isLoading = true;
        state.isError = true;
        // state.data = action.payload;
      });
  },
});

export default contactSlice.reducer;
