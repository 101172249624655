import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/system';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../../service/endpoints';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { forgetPassword, } from '../../utils/schema/login.schema';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ForgotPasswordChange = () => {
    const location = useLocation();
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const token = searchParams.get('token');
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({ mode: "all", resolver: yupResolver(forgetPassword) });

    const navigate = useNavigate();

    const onSubmit = async (value) => {

        try {
            const result = await authService.resetPassword(value, id, token);
            enqueueSnackbar("Login Successfull", {
                variant: "success",
            });
            navigate("/")
            reset();
            return result.data;
        } catch (error) {
            enqueueSnackbar('Invaild credentials', {
                variant: "error",
            });
        }
    };

    return (
        <div className="login  container-fluid">
            <div className="container d-flex align-items-center w-100  h-100">
                <div className="d-flex w-100 justify-content-center align-items-center  h-75 p-0">
                    <div className="col-left pe-3 pag-2 d-flex flex-column  col-7 h-50 d-flex align-items-start justify-content-center">
                        <h1 style={{ fontSize: "60px" }} className="cad-fw-800">
                            Cloudify<span className="cad-fw-500">Docs</span>
                        </h1>
                        <p style={{ color: "#1c1e21" }} className="fs-4 cad-fw-600">
                            Empowering Visions, Engineering Solutions: Your CLOUDIFYDocs Partner.
                        </p>
                    </div>
                    <div className=" col-right col-4 h-auto  p-3">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="d-flex flex-column  justify-content-start h-100"
                        >
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label text-primary"
                                >
                                    Password
                                </label>

                                <div class="input-group mb-3">
                                    <input
                                        type={passwordVisible ? "text" : "password"}
                                        className="form-control p-3 ps-3 border-primary"
                                        id="exampleInputEmail1"
                                        placeholder="Password"
                                        aria-describedby="emailHelp"
                                        // value={email}
                                        // onChange={(e) => setEmail(e.target.value)}
                                        {...register("password")}
                                    /><button
                                        onClick={() => setPasswordVisible(!passwordVisible)}
                                        class="btn btn-outline-primary"
                                        type="button">{passwordVisible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}</button></div>
                                <div
                                    htmlFor="exampleInputEmail1"
                                    className="form-label text-primary form-text text-danger"
                                >
                                    {errors.password?.message}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label text-primary"
                                >
                                    Confirm Password
                                </label>


                                <div class="input-group mb-3">
                                    <input
                                        type={confirmPasswordVisible ? "text" : "password"}
                                        className="form-control p-3 ps-3 border-primary"
                                        id="exampleInputPassword1"
                                        placeholder="Confirm Password"
                                        // value={password}
                                        // onChange={(e) => setPassword(e.target.value)}
                                        {...register("confirmPassword")}
                                    />
                                    <button onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)} class="btn btn-outline-primary" type="button">{confirmPasswordVisible ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}</button>
                                </div>
                                <label
                                    htmlFor="exampleInputPassword1"
                                    className="form-label text-primary form-text text-danger"
                                >
                                    {errors.confirmPassword?.message}
                                </label>
                            </div>
                            <LoadingButton
                                // loading={isLoading}
                                type="submit"
                                className="btn mt-2 pt-2 fs-5 cad-fw-700 pb-2 button-contained"
                            >
                                <span>Submit</span>
                            </LoadingButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPasswordChange;