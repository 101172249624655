import React from 'react'
import { Paper } from '@mui/material'
import PrimaryButton from '../PrimaryButton'
import './Report.css'

const ReportCard = ({title,icon,path }) => {
  return (
    <div  className="report-card ">
      <Paper elevation={2} className="cms-cards justify-content-around flex-column gap-1 d-flex p-4 align-items-center">
        <img src={icon}/>
        <p className='fs-16 mt-2 text-center'>{title}</p>
            <PrimaryButton
                padding="0.2rem 2rem"
                title="View"
                path={path}
            />
      </Paper>
    </div>
  )
}

export default ReportCard
