import api from './api.service'
let headers = {
    "Content-Type": "multipart/form-data",
}
export const getAllUser = async () => {
    try {
        return await api.get(`/global/user`)
    } catch (error) {
        throw error.response
    }
}

export const createOrGetOneOnOneChat = async (reciverId) => {
    try {
        return await api.post(`/chat/c/${reciverId}`)
    } catch (error) {
        return error.response
    }
}

export const getContacts = async () => {
    try {
        return await api.get(`/chat/`)
    } catch (error) {
        throw error.response
    }
}

export const createMessage = async (chatId, data) => {
    // console.log(chatId);
    try {
        return await api.post(`/chat/message/${chatId}`, data, { headers })
    } catch (error) {
        return error.response
    }
}

export const getMessage = async (chatId) => {
    // console.log(chatId);
    try {
        return await api.get(`/chat/message/${chatId}`)
    } catch (error) {
        return error.response
    }
}

export const createNewGroup = async (groupDetails) => {
    try {
        const res = await api.post(`/chat/group`, groupDetails, { headers })
        return res
    } catch (error) {
        return error.response
    }
}