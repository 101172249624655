import api from '../api.service'

export const getGdpr = async () => {
    try {
        return await api.get(`/organization/admin/GDPR/`);
      } catch (error) {
        return error.response;
      }
}

export const postGdpr = async (data) => {
    try {
    const gdprContent = await api.post(`/organization/admin/GDPR/`,data);
    // console.log(gdprContent);

     return gdprContent
    } catch (error) {
        return error.response
    }
}

export const updatedGdpr = async (id) => {
    try {
        return await api.put(`/organization/admin/GDPR/${id}`);
      } catch (error) {
        return error.response;
      }
}


export const deleteGdpr = async (id) => {
  try {
    return await api.delete(`/organization/admin/GDPR/delete/${id}`);
  } catch (error) {
    return error.response;
  }
};
