import React from 'react'
import { Bar } from 'react-chartjs-2';
import { Paper } from '@mui/material'
import { Box } from '@mui/system'

const BarGraph = ({title,data,options}) => {
  

  return (
    <Paper
    className='bar-graph p-2'
    elevation={2}>
        <Box className="border-none rounded-top-3 " sx={{ border: 0, padding: 0, backgroundColor: "#fff", width: "100%" }}>
            <h2 className='mb-3' style={{fontSize:'15px',fontWeight:'500'}}>{title}</h2>
            <div>
                <Bar style={{height:'400px'}} data={data} options={options} />
            </div>
        </Box>
    </Paper>
  )
}

export default BarGraph
