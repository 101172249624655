import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { formArray } from "../formArray";
import CloseIcon from "@mui/icons-material/Close";
import Person2Icon from "@mui/icons-material/Person2";
import Crop169Icon from "@mui/icons-material/Crop169";
import ContactsIcon from "@mui/icons-material/Contacts";
import TitleIcon from "@mui/icons-material/Title";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import BackupIcon from "@mui/icons-material/Backup";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import FormCanvas from "./FormCanvas";
import EditBar from "./EditBar";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import TableRowsOutlinedIcon from "@mui/icons-material/TableRowsOutlined";
import { Button, CircularProgress } from "@mui/material";
import ViewDayOutlinedIcon from "@mui/icons-material/ViewDayOutlined";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import getFormsSlice, {
  fetchForms,
  saveFormData,
} from "../../../Redux/Slices/getFormsSlice";
import SaveFormModal from "../../../Components/SaveFormModal";
import { createForm, getFormsById, putFormsById } from "../../../service/form.service";
import Footer from "../../../Components/Footer/Footer";
import { notify } from "../../../App";

export const formElement = [
  {
    icon: <TitleIcon className="text-white" />,
    title: "Heading",
    element: {
      type: "header",
      section: "header",
      subtype: "h1",
      label: "Header",
      className: "h3",
      subTitleClassName: "cad-text-medium",
      alignment: "align-items-center",
      title: "Heading",
      subTitle: "Mention your subtitle here.",
      access: false,
    },
  },
  {
    icon: <Person2Icon className="text-white" />,
    title: "Input Text",
    element: {
      type: "text",
      section: "text",
      title: "Input Text",
      required: false,
      label: "Text input",
      className: "form-control rounded-0",
      access: false,
      subtype: "text",
      placeHolder: "Text input",
      subLabel: "Sample input.",
    },
  },
  {
    icon: <ContactsIcon className="text-white" />,
    title: "Contact number",
    element: {
      type: "tel",
      title: "Contact",
      required: true,
      section: "text",
      label: "Contact number",
      placeHolder: "000 0000 000",
      className: "form-control rounded-0",
      access: false,
      subLabel: "Please enter a valid phone number.",
    },
  },
  {
    icon: <AlternateEmailIcon className="text-white" />,
    title: "Email Input",
    element: {
      type: "email",
      required: false,
      title: "Em@il",
      section: "text",
      label: "Enter email",
      placeHolder: "email@.com",
      className: "form-control rounded-0",
      access: false,
      subLabel: "example@example.com",
    },
  },
  {
    icon: <FmdGoodIcon className="text-white" />,
    title: "Address",
    element: {
      type: "address",
      required: false,
      label: "Address",
      section: "address",
      className: "form-control",
      street: "Street",
      city: "City",
      state: "State",
      country: "Country",
      zipCode: "Zip Code",
    },
  },
  {
    icon: <FormatColorTextIcon className="text-white" />,
    title: "Text Area",
    element: {
      type: "textarea",
      section: "textarea",
      title: "Text area",
      required: false,
      label: "Text area",
      className: "form-control",
      placeHolder: "Leave a text here",
      access: false,
      subtype: "textarea",
      subLabel: "Type a sublable.",
    },
  },
  {
    icon: <LibraryAddCheckIcon className="text-white" />,
    title: "Mutiple Select",
    element: {
      type: "checkbox",
      label: "Type a question",
      section: "text",
      access: false,
      options: [
        { option: "Type option 1" },
        { option: "Type option 2" },
        { option: "Type option 3" },
        { option: "Type option 4" },
      ],
    },
  },
  {
    icon: <RadioButtonCheckedIcon className="text-white" />,
    title: "Single Select",
    element: {
      type: "radio",
      label: "Type a question",
      section: "text",
      access: false,
      options: [
        { option: "Type option 1" },
        { option: "Type option 2" },
        { option: "Type option 3" },
        { option: "Type option 4" },
      ],
    },
  },
  {
    icon: <DateRangeIcon className="text-white" />,
    title: "Date Picker",
    element: {
      type: "date",
      required: false,
      section: "text",
      label: "Date",
      className: "form-control rounded-0",
      access: false,
      subLabel: "Type a sublabel.",
    },
  },
  {
    icon: <AlignVerticalTopIcon className="text-white" />,
    title: "Drop Down",
    element: {
      type: "dropdown",
      required: false,
      section: "text",
      label: "Type a question",
      placeHolder: "email@.com",
      className: "form-select rounded-0",
      access: false,
      subLabel: "Type a sublabel.",
      options: [{ option: "option 1" }, { option: "option 2" }],
    },
  },

  {
    icon: <ViewDayOutlinedIcon className="text-white" />,
    title: "Details Heading",
    element: {
      type: "details-heading",
      section: "details-heading",
      required: false,
      label: "EMPLOYMENT DETAILS",
      className: "form-control",
      detailsTitle: "SSC",
      subTitle:
        "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps",
    },
  },
  {
    icon: <ViewDayOutlinedIcon className="text-white" />,
    title: "Details",
    element: {
      type: "details",
      section: "details",
      required: false,
      label: "EMPLOYMENT DETAILS",
      className: "form-control",
      detailsTitle: "Employment history",
      subTitle:
        "(List all detail since your 18th birthday or for past 10 years whichever is most recent) *Give details and please do not leave any gaps",
      inputsArray: [
        { fieldInput: "Start date (YY/MM)" },
        { fieldInput: "End date (YY/MM)" },
        { fieldInput: "Institue Name" },
        { fieldInput: "Institue Name" },
      ],
      inputValue: [["", "", "", ""]],
    },
  },
  {
    icon: <LocalParkingIcon className="text-white" />,
    title: "Paragraph",
    element: {
      type: "paragraph",
      label:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.",
      className: "cad-text-medium",
      section: "paragraph",
      alignment: "text-center",
      access: false,
    },
  },
  {
    icon: <BackupIcon className="text-white" />,
    title: "File_upload",
    element: {
      type: "file",
      required: false,
      multiple: false,
      label: "Upload file here",
      className: "form-control cad-text-medium",
      subLabel: "Type a sublabel.",
    },
  },
  {
    icon: <HorizontalRuleIcon className="text-white" />,
    title: "Devider",
    element: {
      type: "hr",
      section: "hr",
    },
  },
  {
    icon: <CheckIcon className="text-white" />,
    title: "Submit",
    element: {
      type: "submit",
      label: "Button",
      subtype: "button",
      alignment: "justify-content-center",
      className: "btn-default btn ",
      access: false,
      style: "default",
    },
  },
  {
    icon: <Crop169Icon className="text-white" />,
    title: "Footer",
    element: {
      type: "footer",
      subtype: "h1",
      className: "p",
      section: "footer",
      subTitleClassName: "cad-text-medium",
      alignment: "align-items-center",
      label: "CloudifyDocs Forms",
      subTitle: "© 2024 CloudifyDocs forms.",
      access: false,
    },
  },

  {
    icon: <ViewDayOutlinedIcon className="text-white" />,
    title: "Signature",
    element: {
      type: "signeture",
      required: false,
    },
  },
  {
    icon: <TableRowsOutlinedIcon className="text-white" />,
    title: "Table",
    element: {
      type: "table",
      section: "table",
      columns: [
        { heading: "" },
        { heading: "Column 1" },
        { heading: "Column 2" },
        { heading: "Column 3" },
        { heading: "Column 4" },
      ],
      rows: [
        ["Row 1", "", "", "", ""],
        ["Row 2", "", "", "", ""],
        ["Row 3", "", "", "", ""],
        ["Row 4", "", "", "", ""],
      ],
    },
  },
  // {x
];

// details-heading
const FormTemplate = () => {
  const { id, FormID } = useParams();
  const [isLeftSideBarOpen, setIsLeftSideBarOpen] = useState(false);
  const [isRightSideBarOpen, setIsRightSideBarOpen] = useState(false);
  const [editableObject, setEditableObject] = useState({});
  const [editableIndex, setEditableIndex] = useState("");
  const [formData, setFormData] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [formDeatils, setFormDeatils] = useState({})
  useEffect(() => {
    getFormById(id || FormID);
  }, [id, FormID]);

  const getFormById = async (id) => {
    setIsFormLoading(true);
    try {
      const res = await getFormsById(id);
      setFormDeatils(res?.data?.payload)
      if (typeof res?.data?.payload?.formData === 'string') {
        setFormData(JSON.parse(res?.data?.payload?.formData));
      } else {
        setFormData(res?.data?.payload?.formData);
      }
      setIsFormLoading(false);
    } catch (error) {
      setIsFormLoading(false);
      console.log(error);
    }

  };

  // console.log(formData);

  const handleOpenSaveModal = () => {
    const cleanForm = formData?.filter((item) => item !== undefined);
    // console.log(formData);
    if (cleanForm?.length === 1) {
      notify("Please add form fields", "info");
    } else if (id) {
      setOpenSaveModal(true);
    } else {
      handleSaveForm(formDeatils?.title)
    }
  };
  const dispatch = useDispatch();

  const [themeObject, setThemeObject] = useState({
    backgroundColor: "#F3F3FE",
    formColor: "#FFFFFF",
    color: "#2C3345",
  });

  const handleOpenFormElement = () => {
    setIsLeftSideBarOpen(!isLeftSideBarOpen);
    setIsRightSideBarOpen(false);
  };

  const handleOpenEditElement = (data, index) => {
    setIsRightSideBarOpen(!isRightSideBarOpen);
    setIsLeftSideBarOpen(false);
    setEditableIndex(index);
    setEditableObject(data);
  };

  const addElement = (element) => {
    setFormData([...formData, element]);
  };

  const handleDragStart = (event, index) => {
    event.dataTransfer.setData("index", index);
  };

  const handleEditBarChange = (newValue, fieldToUpdate) => {
    const editedObjectIndex = formData.findIndex(
      (item) => item.id === editableObject.id
    );

    if (editedObjectIndex !== -1) {
      // Create a copy of the object being edited
      const updatedObject = { ...formData[editedObjectIndex] };

      // Update the specific field in the copied object
      updatedObject[fieldToUpdate] = newValue;

      // Create a new array with the updated object
      const updatedFormData = [
        ...formData.slice(0, editedObjectIndex),
        updatedObject,
        ...formData.slice(editedObjectIndex + 1),
      ];

      // Update the state with the modified 'formData'
      setFormData(updatedFormData);
    }
  };

  const navigate = useNavigate();

  const handleSaveForm = async (formName) => {
    const cleanForm = formData.filter((item) => item !== undefined);
    // console.log(cleanForm?.length);

    const updateForm = {
      title: formName,
      formData: cleanForm,
    };

    // try {
    //   const res = await createForm(updateForm);
    //   navigate("/my-forms");
    //   dispatch(fetchForms());
    // } catch (error) {
    //   // console.log(error);
    // }

    try {
      if (FormID) {
        await putFormsById(updateForm, FormID)
      } else {
        await createForm(updateForm);
      }
      navigate("/forms");
      dispatch(fetchForms());
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    // ====[ theme alert ]====
    isFormLoading ? (
      <div style={{
        width: "100%",
        height: "100vh"
      }} className=" d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>
    ) : (
      <div
        style={{
          backgroundColor: formData[0]?.backgroundColor,
          height: "auto",
          overflowX: "hidden"
        }}
        className=" position-relative d-flex justify-content-center rounded-3"
      >
        <span
          style={{
            position: "absolute",
            top: 100,
            left: 0,
            zIndex: 999,
            // opacity: isLeftSideBarOpen ? "0" : "1",
            transform:
              isLeftSideBarOpen || isRightSideBarOpen
                ? "translate(-500px,0px)"
                : "translate(0px,0px)",
            transition: "all 0.5s",
            backgroundColor: "#33E4652"
          }}
          onClick={handleOpenFormElement}
          className="form-element-btn text-white cad-fw-600"
        >
          Add form element
        </span>

        <span
          style={{
            position: "absolute",
            top: 100,
            right: 20,
            opacity: isLeftSideBarOpen ? "0" : "1",
            transform:
              isLeftSideBarOpen || isRightSideBarOpen
                ? "translate(-40px,0px)"
                : "translate(0px,0px)",
            transition: "all 0.5s",
            // backgroundColor:"#33E4652"
          }}
          onClick={() => handleOpenEditElement(0, formData[0])}
          className="form-theme-btn text-white cad-fw-600"
        >
          <ColorLensIcon />
        </span>

        <Button
          variant="contained"
          style={{
            textTransform: "none",
            position: "absolute",
            top: 40,
            right: 20,
            opacity: isLeftSideBarOpen ? "0" : "1",
            transform:
              isLeftSideBarOpen || isRightSideBarOpen
                ? "translate(-40px,0px)"
                : "translate(0px,0px)",
            transition: "all 0.5s",
            // backgroundColor:"#33E4652"
          }}
          onClick={handleOpenSaveModal}
          className="form-save-btn text-white cad-fw-600"
        >
          {FormID ? "Update form" : "Save as"}
        </Button>

        <div
          style={{
            // backgroundColor: "au",
            width: isLeftSideBarOpen ? "25%" : "0%",
            transform: isLeftSideBarOpen
              ? "translate(0px,15px)"
              : "translate(-500px,15px)",
            transition: "0.5s",
            borderRadius: "0px 12px 12px 0px"

            // height: "88vh",
          }}
        >
          <div style={{
          }} className="d-flex bg-primary p-3 justify-content-between">
            <span className="cad-fw-600 cad-text-large text-white">
              Form Element
            </span>
            <CloseIcon
              onClick={() => setIsLeftSideBarOpen(false)}
              className="text-white pointer"
            />
          </div>

          {formElement.map(({ icon, title, element }, index) => (
            <div
              draggable
              onDragStart={(event) => handleDragStart(event, index)}
              // onClick={() => addElement(element)}
              key={index}
              style={{ cursor: "all-scroll" }}
              className="d-flex  w-100 border-bottom"
            >
              <span
                style={{ backgroundColor: "#4ccd8d" }}
                className="p-3 col-2 d-flex justify-content-center"
              >
                {icon}
              </span>
              <span className="p-3 cad-fw-600 bg-white col-10">{title}</span>
            </div>
          ))}
        </div>


        <FormCanvas
          id={id}
          themeObject={Array.isArray(formData) ? formData[0] : JSON.parse(formData)[0]}
          handleOpenEditElement={handleOpenEditElement}
          setFormData={setFormData}
          formData={Array.isArray(formData) ? formData : JSON.parse(formData)}
        />


        <div
          style={{
            backgroundColor: "#3E4652",
            width: isRightSideBarOpen ? "30%" : "0%",
            transform: isRightSideBarOpen
              ? "translate(16px,15px)"
              : "translate(600px,15px)",
            transition: "0.5s",
            height: "88vh",
          }}
          className=" overflow-auto"
        >
          <div className="d-flex bg-primary p-3 justify-content-between">
            <span className="cad-fw-600 cad-text-large text-white">
              {editableObject?.title} Properties
            </span>
            <CloseIcon
              onClick={() => setIsRightSideBarOpen(false)}
              className="text-white pointer"
            />
          </div>

          <EditBar
            editableIndex={editableIndex}
            setThemeObject={setThemeObject}
            setFormData={setFormData}
            formData={formData}
            setEditableObject={setEditableObject}
            editableObject={editableObject}
          />
          <div className="w-100 d-flex justify-content-center pb-4">
            <Button
              onClick={() => setIsRightSideBarOpen(false)}
              variant="contained"
            >
              Save
            </Button>
          </div>
        </div>
        <SaveFormModal
          open={openSaveModal}
          setOpen={setOpenSaveModal}
          handelSaveForm={handleSaveForm}
        />
      </div>)
  );
};

export default FormTemplate;
