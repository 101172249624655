import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import CollectionsOutlinedIcon from "@mui/icons-material/CollectionsOutlined";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";

const PreviewImage = ({ imagePrevopen, setImagePrevOpen, attachments }) => {
  const [imagePrevArray, setImagePrevArray] = useState([]);

  useEffect(() => {
    setImagePrevArray(attachments);
  }, [attachments]);
  const handleImagePrevClose = () => setImagePrevOpen(false);

  const fileURL = (path) => {
    return new URL(path, process.env.REACT_APP_BACKEND_BASEURL).toString();
  };
  const handlePrint = (imageUrl) => {
    const printWindow = window.open("", "", "width=600,height=400");
    const img = new Image();
    img.onload = function () {
      printWindow.document.write(
        '<img src="' + fileURL(imageUrl) + '" style="width:100%;"/>'
      );
      printWindow.print();
      printWindow.document.close();
    };

    img.onerror = function () {
      console.error("Error loading the image for printing.");
    };

    img.src = fileURL(imageUrl);
  };

  const handleDownload = async (imageUrl, imgName) => {
    try {
      const response = await fetch(fileURL(imageUrl));
      if (!response.ok) {
        console.log(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob({ type: "image/jpeg" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = imgName; // Desired name for the downloaded file
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Modal
      className="cad-modal-blur"
      open={imagePrevopen}
      onClose={handleImagePrevClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="img-prev-modal"
        onClick={handleImagePrevClose}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // overflowX:"scroll",
          outline: "none",
        }}
      >
        <div className="img-prev-cnt h-100 d-flex overflow-auto">
          {imagePrevArray?.map((image) => (
            <div key={image.id} className="image-prev-control-panel ">
              <img
                style={{ maxHeight: "400px" }}
                className=" rounded-3 m-2"
                src={fileURL(image?.path)}
                alt=""
              />

              <div className="d-flex gap-4 align-items-center justify-content-between ps-3">
                {/* <ArrowBackOutlinedIcon className="text-white " /> */}

                <div className="d-flex gap-3 align-items-center">
                  <CollectionsOutlinedIcon className="text-danger cad-text-large" />
                  <span className="text-white cad-text-medium cad-fw-500 d-flex align-items-center">
                    {image?.originalname}
                  </span>
                </div>

                <div className="d-flex gap-3  align-items-center text-white fw-bold">
                  <span
                    onClick={() => handlePrint(image?.path)}
                    className="cad-text-small image-prev-control-panel-icon p-2"
                  >
                    <LocalPrintshopOutlinedIcon />
                  </span>

                  <span
                    onClick={() =>
                      handleDownload(image?.path, image?.originalname)
                    }
                    className="image-prev-control-panel-icon p-2 cad-text-small"
                  >
                    <VerticalAlignBottomOutlinedIcon />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default PreviewImage;
