import Dashboard from "./Pages/Dashboard/Dashboard";
import Notifications from "./Pages/Notifications/Notifications";
import Profile from "./Pages/Profile/Profile";
import Settings from "./Pages/Settings/Settings";
import Supports from "./Pages/Supports/Supports";
import AdminUsers from "./Pages/AdminUsers/AdminUsers";
import Subscription from "./Pages/Subscription/Subscription";
import Payments from "./Pages/Payments/Payments";
import CMS from "./Pages/Cms/Cms";
import Reports from "./Pages/Reports/Reports";
import Contact from "./Pages/Contact/Contact";
import SubAdmin from "./Pages/SubAdmin/SubAdmin.js";
import PrivacyPolicy from "./Components/CmsCard/Pages/PrivacyPolicy";
import Gdpr from "./Components/CmsCard/Pages/Gdpr";
import Guidance from "./Components/CmsCard/Pages/Guidance";
import Faq from "./Components/CmsCard/Pages/Faq";
import LandingPage from "./Components/CmsCard/Pages/LandingPage";
import Chat from "./Chats/Chat.js";
import GuestWebPage from "./Components/ReportCard/Pages/GuestWebPage";
import TheAplication from "./Components/ReportCard/Pages/TheAplication";
import AboutMetadata from "./Components/ReportCard/Pages/AboutMetadata";
import AddFaq from "./Components/CmsCard/AddFaq";
import OpretingSystem from "./Components/ReportCard/Pages/OpretingSystem";
import TermsCondition from "./Components/CmsCard/Pages/TermsCondition";
import TicketDetails from "./Pages/Supports/TicketDetails";
import EditDetails from "./Components/CmsCard/CmsQuill/EditDetails.js";
import AddDetails from "./Components/CmsCard/CmsQuill/AddDetails.js";
import TermsAdd from "./Components/CmsCard/CmsQuill/TermsAdd.js";
import TermsEdit from "./Components/CmsCard/CmsQuill/TermsEdit.js";
import PrivacyEdit from "./Components/CmsCard/CmsQuill/PrivacyEdit.js";
import PrivacyAdd from "./Components/CmsCard/CmsQuill/PrivacyAdd.js";
import EditGuidance from "./Components/CmsCard/CmsQuill/EditGuidance.js";
import AddGuidance from "./Components/CmsCard/CmsQuill/AddGuidance.js";
import { Login } from "@mui/icons-material";
import GdprAdd from "./Components/CmsCard/CmsQuill/AddGDPR.js";
import GdprEdit from "./Components/CmsCard/CmsQuill/EditGDPR.js";
import EditFaq from "./Components/CmsCard/EditFaq.js";
import Form from "./Pages/Form/Form.js";
import Users from "./Pages/Users/Users.js";
import AddSubAdmin from "./Pages/SubAdmin/AddSubAdmin.js";
import EditSubAdmin from "./Pages/SubAdmin/EditSubAdmin.js";
import AddSubscription from "./Pages/Subscription/AddSubscription.js";
import EditSubscription from "./Pages/Subscription/EditSubscription.js";
import MyForms from "./Pages/MyForms/MyForms.js";
import FormTemplate from "./Pages/Form/FormTemplate/FormTemplate.js";
import ReportSubs from "./Pages/Reports/Reports-subs.js";
import ReportUser from "./Pages/Reports/Reports-user.js";
import PersonalDetailsNew from "./Pages/Profile/PersonalDetailsNew.js";
import ForgotPasswordChange from "./Pages/Login/Forgot-password-change.js";
import FeaturesRequest from "./Pages/Features Request/FeaturesRequest.js";
import AddPackage from "./Pages/Subscription/Add package/AddPackage.js";
import PackageDetails from "./Pages/Subscription/package/PackageDetails.jsx";
import UserDetails from "./Components/UserDetails/UserDetails.js";

export const RouteLink = [
  { path: "/", component: <Dashboard /> },
  { path: "/profile-old", component: <Profile /> },
  { path: "/profile", component: <PersonalDetailsNew /> },
  { path: "/adminUsers", component: <AdminUsers /> },
  { path: "/subscription", component: <Subscription /> },
  { path: "/payments", component: <Payments /> },
  { path: "/cms", component: <CMS /> },
  { path: "/forms", component: <Form /> },
  { path: "/reports", component: <Reports /> },
  { path: "/supports", component: <Supports /> },
  { path: "/contact", component: <Contact /> },
  { path: "/subadmin", component: <SubAdmin /> },
  { path: "/settings", component: <Settings /> },
  { path: "/termscondition", component: <TermsCondition /> },
  {
    path: "/editdetails/:id",
    component: <EditDetails />,
  },
  { path: "/privacypolicy", component: <PrivacyPolicy /> },
  { path: "/gdpr", component: <Gdpr /> },
  { path: "/guidance", component: <Guidance /> },
  { path: "/faq", component: <Faq /> },
  { path: "/landingpage/:id", component: <LandingPage /> },
  { path: "/chat", component: <Chat /> },
  { path: "/GuestWebPage", component: <GuestWebPage /> },
  { path: "/theaplication", component: <TheAplication /> },
  { path: "/aboutmetadata", component: <AboutMetadata /> },
  { path: "/addfaq", component: <AddFaq /> },
  { path: "/my-forms", component: <MyForms /> },
  { path: "/template-forms/:id", component: <FormTemplate /> },
  { path: "/template-preview/:FormID", component: <FormTemplate /> },
  { path: "/editfaq/:id", component: <EditFaq /> },
  { path: "/opretingsystem", component: <OpretingSystem /> },
  { path: "/ticketdetails/:id", component: <TicketDetails /> },
  { path: "/addgdpr", component: <GdprAdd /> },
  { path: "/editgdpr/:id", component: <GdprEdit /> },
  { path: "/termsadd", component: <TermsAdd /> },
  { path: "/termsedit/:id", component: <TermsEdit /> },
  { path: "/privacyedit/:id", component: <PrivacyEdit /> },
  { path: "/privacyadd", component: <PrivacyAdd /> },
  { path: "/editguidance/:id", component: <EditGuidance /> },
  { path: "/addguidance", component: <AddGuidance /> },
  { path: "/login", component: <Login /> },
  { path: "/users", component: <Users /> },
  { path: "/addSubAdmin", component: <AddSubAdmin /> },
  { path: "/editSubAdmin/:id", component: <EditSubAdmin /> },
  { path: "/add-subscription", component: <AddSubscription /> },
  { path: "/report-subscription", component: <ReportSubs /> },
  { path: "/report-user", component: <ReportUser /> },
  { path: "/features-request", component: <FeaturesRequest /> },
  { path: "/add-package", component: <AddPackage /> },
  { path: "/details-package/:id", component: <PackageDetails /> },
  { path: "/user-details/:id", component: <UserDetails /> },
];
