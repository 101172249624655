import React from 'react'
import maps from '../ImageIcon/map.png'
import { Paper } from '@mui/material'
import { Box } from '@mui/system'

const Maps = ({title}) => {
  return (
    <Paper
    className='p-2'
    elevation={2}>
        <Box className="border-none rounded-top-3 " sx={{ border: 0, padding: 0, backgroundColor: "#fff", width: "100%" }}>
        <h2 className='mb-3' style={{fontSize:'15px',fontWeight:'500'}}>{title}</h2>
        <img style={{height:'152px'}} className='w-100' src={maps}/>
        </Box>
    </Paper>
  )
}

export default Maps
