import { useNavigate } from "react-router-dom";
import TokenService from "../service/token.service";
import { useEffect } from "react";
import NewDrawerPersistace from "../Components/NewDrawerPersistace";
import { useDispatch } from "react-redux";

const DefaultLayout = () => {
  const user = TokenService.getUser();
  const isAuthenticate = user?.isAuthenticate || false;
  const navigate = useNavigate();
  const dispatch = useDispatch()

  useEffect(() => {
    // console.log(isAuthenticate);
    if (!isAuthenticate) {
      return navigate("/login");
    }
  }, [isAuthenticate, user, navigate, dispatch]);


  return (
    <NewDrawerPersistace />
  );
};


export default DefaultLayout;
