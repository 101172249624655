import axios from "axios";
import TokenService from "./token.service";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_BACKEND_BASEURL +
    "/api/" +
    process.env.REACT_APP_NODE_ENV +
    "/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getLocalAccessToken();
    if (accessToken) {
      config.headers["x-auth-token"] = accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (
      error.response &&
      error.response.status === 403 &&
      !originalConfig.__isRetryRequest
    ) {
      originalConfig.__isRetryRequest = true;

      try {
        const refreshToken = TokenService.getLocalRefreshToken();
        const res = await instance.post("auth/admin/token", { refreshToken });
        if (res) TokenService.updateNewAccessToken(res.data?.accessToken);
        return instance(originalConfig);
      } catch (refreshError) {
        // console.log("____hit error", refreshError.response.data?.error?.message === "jwt expired");
        if (refreshError.response && refreshError.response.data?.error?.message === "jwt expired") {
          window.location.replace("/login");
          TokenService.isAuthenticateFailed();
        }
        return Promise.reject(refreshError);
      }
    }

    // Handle 401 status code explicitly
    if (error?.response?.status === 401 && TokenService.getUser() !== null) {
      TokenService.isAuthenticateFailed();
      return Promise.reject(error); // Pass the response as-is
    }

    return Promise.reject(error);
  }
);

export default instance;
