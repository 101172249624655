import * as yup from "yup";
export const subscriptionSchema = yup.object().shape({
    title: yup
        .string().required('Title is required')
        .oneOf(["Essential", "Exclusive", "Premium"], "Please select a Title"),

    amount: yup
        .number()
        .positive('Amount must be greater than zero')
        .required('Amount is required'),

    duration: yup
        .string().required('Duration is required')
        .oneOf(["1", "12"], "Please select duration"),

    currency: yup
        .string().required('Currency is required')
        .oneOf(["INR", "USD"], "Please select a valid currency"),
})