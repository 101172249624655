import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userService } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const userThunkAPI = {
    getAll: createAsyncThunk("user/getAll", async (searchTerm, { getState }) => {
        try {
            const { size, currentPage } = getState().user
            const params = getRequestParams(searchTerm, currentPage, size)
            const res = await userService.get(params);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }
    ),

    getById: createAsyncThunk("user/getById", async (id) => {
        try {
            const res = await userService.getById(id);
            if (res.status === 200) return res?.data;
        } catch (error) {
            console.log(error);
        }
    }),

    softDelete: createAsyncThunk("user/softDelete", async (id) => {
        try {
            const res = await userService.softdelete(id);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }),

    getArchiveList: createAsyncThunk("user/getArchiveList", async (_, { getState }) => {
        try {
            const { size, currentPage, search } = getState().user;
            const params = getRequestParams(search, currentPage, size);
            const res = await userService.archiveList(params);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }),

    restoreUser: createAsyncThunk("user/restore", async (id) => {
        try {
            const res = await userService.restore(id);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }),
}

const userSlice = createSlice({
    name: "user",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: [],
        userCount: null
    },
    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        },
        setDataById: (state, action) => {
            state.dataById = action?.payload?.data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userThunkAPI.getAll.pending, handlePending)
            .addCase(userThunkAPI.getAll.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data || [];
                state.rows = action?.payload?.data?.rows || [];
                state.totalItems = action?.payload?.data?.totalItems;
                state.totalPages = action?.payload?.data?.totalPages;
                state.currentPage = action?.payload?.data?.currentPage;
                state.isLoading = false;
            })
            .addCase(userThunkAPI.getAll.rejected, handleRejected);

        builder
            .addCase(userThunkAPI.getById.pending, handlePending)
            .addCase(userThunkAPI.getById.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.dataById = action?.payload?.data
            })
            .addCase(userThunkAPI.getById.rejected, handleRejected);

        builder
            .addCase(userThunkAPI.softDelete.pending, handlePending)
            .addCase(userThunkAPI.softDelete.fulfilled, (state, action) => {
                handleFulfilled(state, action);
            })
            .addCase(userThunkAPI.softDelete.rejected, handleRejected);

        builder
            .addCase(userThunkAPI.getArchiveList.pending, handlePending)
            .addCase(userThunkAPI.getArchiveList.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.archive = action?.payload?.data?.rows
            })
            .addCase(userThunkAPI.getArchiveList.rejected, handleRejected);

        builder
            .addCase(userThunkAPI.restoreUser.pending, handlePending)
            .addCase(userThunkAPI.restoreUser.fulfilled, (state, action) => {
                handleFulfilled(state, action);
            })
            .addCase(userThunkAPI.restoreUser.rejected, handleRejected);
    }

});

export default userSlice.reducer;
export const { nextPage, previousPage, setDataById } = userSlice.actions;
