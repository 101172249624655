import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichTextQuill = ({ editorHtml, handleChange }) => {
  const [theme, setTheme] = useState("snow");

  return (
    <div className=" bg-white w-85 rounded-2 ">
      <ReactQuill
        theme={theme}
        onChange={handleChange}
        value={editorHtml}
        modules={RichTextQuill.modules}
        formats={RichTextQuill.formats}
        bounds={".app"}
        // placeholder={props.placeholder}
      />
    </div>
  );
};

RichTextQuill.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

RichTextQuill.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

RichTextQuill.propTypes = {
  placeholder: PropTypes.string,
};

export default RichTextQuill;
