import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { deepOrange, deepPurple } from "@mui/material/colors";
import { getRandomColor } from "../../utils/getRandomColor";

const domain = process.env.REACT_APP_BACKEND_BASEURL
const UserName = ({ row }) => {
  const [bgColor, setBgColor] = useState(null);

  useEffect(() => {
    // Generate a random color only if it's not already generated
    if (!bgColor) {
      const randomColor = getRandomColor();
      setBgColor(randomColor);    
    }
  }, [bgColor]);

  return (
    <div className="d-flex align-items-center gap-2">
      {row?.profile_photo ? (
        <Avatar
          alt="Img"
          src={`${domain}/${row?.profile_photo}`}
          sx={{ width: 24, height: 24 }}
        />
      ) : (
        <Avatar sx={{ bgcolor: bgColor, width: 24, height: 24 }}>
          {row?.first_name?.charAt(0) + row?.last_name?.charAt(0)}
        </Avatar>
      )}
      {row?.first_name + " " + (row?.last_name ? row?.last_name : "")}
    </div>
  );
};

export default UserName;
