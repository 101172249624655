class ApiHandler {
  constructor(baseEndpoint, ApiService) {
    this.baseEndpoint = baseEndpoint;
    this.ApiService = ApiService;
  }

  async handleApiCall(apiFunction, endpoint = "", data = null, params = {}, headers = {
    headers: {
      "Content-Type": "application/json",
    }
  }) {
    try {
      const url = `${this.baseEndpoint}${endpoint}`;
      // console.log(params);

      // Adjusting the structure to fit the expected ApiService call structure.
      // Assuming ApiService functions expect an object with data, params, and headers.
      let requestData = {};
      if (data) requestData = { ...data };
      if (Object.keys(params).length !== 0) requestData.params = params;
      // if (headers) requestData.headers = headers;

      const result = await apiFunction(url, requestData, headers);
      // console.log(result);
      return result
    } catch (error) {
      // Enhanced error handling
      // console.log(error.response.data.message);
      throw error.response
      // console.log(error);
    }
  }


  async post(endpoint, data, headers = {}) {
    return await this.handleApiCall(this.ApiService.post, endpoint, data, {}, headers);
  }

  async get(endpoint, params = {}, headers = {}) {
    // console.log(params);
    return await this.handleApiCall(this.ApiService.get, endpoint, null, params, headers);
  }

  async put(endpoint, data, headers = {}) {
    return await this.handleApiCall(this.ApiService.put, endpoint, data, {}, headers);
  }

  async delete(endpoint, params = {}, headers = {}) {
    // Assuming delete requests might not always send data but could send params in the URL
    return await this.handleApiCall(this.ApiService.delete, endpoint, null, params, headers);
  }
}

export default ApiHandler;
