import React from 'react'
import { GoPeople } from 'react-icons/go'
import { FaDollarSign } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { Paper } from '@mui/material'
import CountUp from 'react-countup';

const DashbordCard = ({ count, disc, iconName, themeColorsObject }) => {

  const iconComponents = {

    GoPeople: GoPeople,
    FaDollarSign: FaDollarSign,
    FaUsers: FaUsers,
  };

  // Get the corresponding icon component based on the iconName prop
  const IconComponent = iconComponents[iconName];

  if (!IconComponent) {
    // Handle the case where an invalid icon name is provided
    return <div>Invalid Icon</div>;
  }



  return (

    <div className="parrent col-lg col-12 p-2 mb-3">
      <Paper
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        elevation={2}
        className="dashboard-small-cards d-flex justify-content-around align-items-center flex-column gap-1"
      >
        <div className='d-flex align-items-center justify-content-between '>
          <IconComponent
            className='icon'
            size={50}
            color='#ffffff'
          />
        </div>
        <p style={{ color: themeColorsObject.textColor,fontSize:'15px' }}>{disc}</p>
        <CountUp className='fs-4 fw-semibold'
          start={0}
          suffix={count === 50 ? "k" : ""}
          end={count}
        />
      </Paper >
    </div >
  )
}

export default DashbordCard