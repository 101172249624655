import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { closedAccounts } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const userClosedAccountThunkAPI = {
    getAll: createAsyncThunk("userClosedAccount/getAll", async (searchTerm, { getState }) => {
        try {
            const { size, currentPage } = getState().user
            const params = getRequestParams(searchTerm, currentPage, size)
            const res = await closedAccounts.get(params);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }
    ),

    getById: createAsyncThunk("userClosedAccount/getById", async (id) => {
        try {
            const res = await closedAccounts.getById(id);
            if (res.status === 200) return res?.data;
        } catch (error) {
            console.log(error);
        }
    }),

}

const userClosedAccountSlice = createSlice({
    name: "userClosedAccount",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: [],
        userCount: null
    },
    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        },
        setDataById: (state, action) => {
            state.dataById = action?.payload?.data
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userClosedAccountThunkAPI.getAll.pending, handlePending)
            .addCase(userClosedAccountThunkAPI.getAll.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data || [];
                state.rows = action?.payload?.data?.rows || [];
                state.totalItems = action?.payload?.data?.totalItems;
                state.totalPages = action?.payload?.data?.totalPages;
                state.currentPage = action?.payload?.data?.currentPage;
                state.isLoading = false;
            })
            .addCase(userClosedAccountThunkAPI.getAll.rejected, handleRejected);

        builder
            .addCase(userClosedAccountThunkAPI.getById.pending, handlePending)
            .addCase(userClosedAccountThunkAPI.getById.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.dataById = action?.payload?.data
            })
            .addCase(userClosedAccountThunkAPI.getById.rejected, handleRejected);
    }

});

export default userClosedAccountSlice.reducer;
export const { nextPage, previousPage, setDataById } = userClosedAccountSlice.actions;
