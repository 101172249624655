import {
  Button,
  Drawer,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import * as themeColors from "../../../utils/Theme/Colors";
import { enqueueSnackbar } from "notistack";
import React from "react";
import { packageSubscription } from "../../../service/endpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { packageSchema } from "../../../utils/schema/package.schema";
import { Controller, useForm } from "react-hook-form";
import { Box, Stack } from "@mui/system";
import { AiOutlineLeft } from "react-icons/ai";
import { subsThunkAPI } from "../../../features/subscription/subscription.slice";

const AddPackageDrawer = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(packageSchema) });

  const onSubmit = async (formData) => {
    // console.log(formData);
    try {
      const response = await packageSubscription.create(formData);
      if (response?.status === 201) {
        enqueueSnackbar(response?.data?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      console.log(error);
      if (error.data && error.data.error) {
        enqueueSnackbar(error?.data?.error?.message, {
          variant: "error",
        });
      }
    }
    dispatch(subsThunkAPI.getAll());
    reset();
    onClose(false);
  };

  // List of country currencies
  const domain = [
    { value: "true", label: "Yes" },
    { value: "false", label: "No" },
  ];
  return (
    <React.Fragment>
      <Drawer anchor={"right"} open={open} onClose={onClose}>
        <div
          style={{
            height: "100%",
            backgroundColor: themeColorsObject.megaCardBackgroundColor,
            color: themeColorsObject.textColor,
            // transition:"0.3s"
          }}
          className="w-100 d-flex mt-1flex-column justify-content-start align-items-start"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <React.Fragment>
                <Box p={2}>
                  <Button
                    className="mb-4"
                    variant="text"
                    onClick={() => onClose(false)}
                  >
                    <AiOutlineLeft className="pointer" size={18} />
                    <label className="pointer">Packages</label>
                  </Button>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={6} md={6} sx={{ mb: 1 }}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            error={errors.title ? true : false}
                            label="Title"
                            helperText={
                              errors.title ? errors.title.message : ""
                            }
                            {...register("title")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} sx={{ mb: 1 }}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            error={errors.users ? true : false}
                            label="Number of Users"
                            helperText={
                              errors.users ? errors.users.message : ""
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  /package
                                </InputAdornment>
                              ),
                            }}
                            {...register("users")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} sx={{ mb: 1 }}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            error={errors.storage ? true : false}
                            label="Storage"
                            helperText={
                              errors.storage ? errors.storage.message : ""
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  GB
                                </InputAdornment>
                              ),
                            }}
                            {...register("storage")}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} md={6} sx={{ mb: 1 }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="domain-label">Domain</InputLabel>
                          <Controller
                            name="domain"
                            control={control}
                            render={({ field }) => (
                              <Select
                                {...field}
                                labelId="domain-label"
                                error={!!errors.domain}
                                fullWidth
                                label="domain"
                              >
                                {domain.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                          {errors.domain && (
                            <span style={{ color: "red", fontSize: "0.75rem" }}>
                              {errors.domain.message}
                            </span>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Box sx={{ mb: 3, mt: 1 }}>
                      <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={() => onClose(false)}>
                          Back
                        </Button>
                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </React.Fragment>
            </Box>
          </form>
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default AddPackageDrawer;
