// UserViewModal.jsx
import React, { useEffect, useState } from 'react';
import { Box, Modal, Paper } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { LiaUserTimesSolid } from 'react-icons/lia';
import { userThunkAPI } from '../../../features/users/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import UserSuspendModal from './user.suspend.modal';

const UserViewModal = ({ handleClose, open, id }) => {

    const dispatch = useDispatch();
    const userData = useSelector((state) => state?.user?.dataById); // Access data by ID from Redux state

    const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const openSuspendModal = (userId) => {
        setIsSuspendModalOpen(true);
        setSelectedUserId(userId);
    };

    const closeSuspendModal = () => {
        setIsSuspendModalOpen(false);
        setSelectedUserId(null)
    };

    useEffect(() => {
        dispatch(userThunkAPI.getById(id))
    }, [id, open, dispatch]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: '#fff',
        boxShadow: 24,
        p: 4,
        borderRadius: 1
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={2}>
                    <Box
                        sx={style}
                    >
                        <div className="modalhead d-flex justify-content-between align-items-center mb-3">
                            <h2 className="fs-5 mb-0">User Details</h2>
                            <h3 onClick={handleClose} className="text-end pointer">
                                <CloseIcon />
                            </h3>
                        </div>
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td className="pb-3">Name</td>
                                    <td className="pb-3">
                                        {userData?.first_name ? userData?.first_name : '-'}
                                        {userData?.last_name ? userData?.last_name : '-'}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="pb-3">Phone</td>
                                    <td className="pb-3">{userData?.phone_number ? userData?.phone_number : '-'}</td>
                                </tr>
                                <tr>
                                    <td className="pb-3">Email</td>
                                    <td className="pb-3">
                                        {userData?.email_address ? userData?.email_address : '-'}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button onClick={() => openSuspendModal(id)} style={{ border: '0', backgroundColor: 'transparent', color: '#E45D5B' }}>
                            <LiaUserTimesSolid size={22} style={{ color: '#E45D5B' }} /> Suspend an account cdcd
                        </button>
                    </Box>
                </Paper>
            </Modal>

            {isSuspendModalOpen && (
                <UserSuspendModal
                    handleClose={() => {
                        closeSuspendModal();
                        handleClose();
                        // console.log('close user modal');
                    }}
                    open={isSuspendModalOpen}
                    id={selectedUserId}
                />
            )}

        </div>
    )
}

export default UserViewModal;
