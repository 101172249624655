import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import { faqService } from "../../service/endpoints";

export const faqGetAll = createAsyncThunk(
  "faq/getAll",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await faqService.get(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const faqGetById = createAsyncThunk(
  "faq/getById",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await faqService.getById(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const faqUpdate = createAsyncThunk(
  "faq/update",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await faqService.update(arge.id, arge);
      if (result.status === 202) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const faqCreate = createAsyncThunk(
  "faq/create",
  async (arge, { rejectWithValue, dispatch }) => {
    try {
      const result = await faqService.post(arge);
      if (result.status === 201) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const faqDelete = createAsyncThunk(
  "faq/delete",
  async (arge, { rejectWithValue, dispatch }) => {
    try {
      const result = await faqService.delete(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    rows: [],
    dataById: {},
    id: null,
    size: 0,
    currentPage: 1,
    totalPage: 0,
    totalItems: 0,
    search: null,
    searchHistory: [],
  },
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    resetGetIdData: (state) => {
      state.dataById = {};
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(faqGetAll.pending, handlePending)
      .addCase(faqGetAll.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action?.payload?.data;
        state.rows = action?.payload?.data?.rows;
        state.totalItems = action?.payload?.data?.totalItems;
        state.totalPage = action?.payload?.data?.totalPage;
        state.currentPage = action?.payload?.data?.currentPage;
      })
      .addCase(faqGetAll.rejected, handleRejected);
    builder
      .addCase(faqGetById.pending, handlePending)
      .addCase(faqGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.dataById = action.payload.data;
      })
      .addCase(faqGetById.rejected, handleRejected);
    builder
      .addCase(faqUpdate.pending, handlePending)
      .addCase(faqUpdate.fulfilled, handleFulfilled)
      .addCase(faqUpdate.rejected, handleRejected);

    builder
      .addCase(faqCreate.pending, handlePending)
      .addCase(faqCreate.fulfilled, handleFulfilled)
      .addCase(faqCreate.rejected, handleRejected);
    builder
      .addCase(faqDelete.pending, handlePending)
      .addCase(faqDelete.fulfilled, handleFulfilled)
      .addCase(faqDelete.rejected, handleRejected);
  },
});

export default faqSlice.reducer;
export const { closeError, resetGetIdData } = faqSlice.actions;
