import React, { useState } from "react";
import { SkeletonTable } from "./Skeleton/SkeletonTable";
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import * as themeColors from "../utils/Theme/Colors";
import { useSelector } from "react-redux";
import UserProfile from "./GlobalTable/UserProfile";
import DateFormatter from "./DateFormatter";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlineUpload,
} from "react-icons/ai";
import NoData from "./NoData";
import Loader from "./Loader/Loader";

const TableData = ({
  extractedDataArray,
  cellConfig,
  tableHeadRowCell,
  setMouseEntered,
  setMouseEnteredId,
  padding,
  handleViewOpen,
  isLoading,
  totalPages,
  handlePagination,
  onPageChange,
  onRowsPerPageChange,
  currentPage
}) => {
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const [isLoading, setIsLoading] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange && onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    onPageChange && onPageChange(0); // Reset page to 0 when rows per page changes
    onRowsPerPageChange && onRowsPerPageChange(newRowsPerPage);
  };

  return (
    <>
      {isLoading ? (
        <div style={{
          height: "70vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Loader />
        </div>
      ) : (
        <TableContainer className="pb-4">
          {extractedDataArray?.length > 0 ? (
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead >
                <TableRow>
                  {tableHeadRowCell?.map(({ id, title, align, width }, index) => (
                    <TableCell
                      className="cad-fw-600 cad-text-small"
                      key={index}
                      align={align}
                      width={width}
                      style={{
                        color: themeColorsObject.textColor,
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {extractedDataArray.map((rowData, index) => (
                  <TableRow
                    key={index}
                    //   sx={{ "&:last-child td, &:last-child th": { border: 0 } }}

                    sx={{
                      cursor: "pointer",
                      backgroundColor: "transparent", // Set the default background color
                      transition: "background-color 0.5s ease", // Add a smooth transition effect
                    }}
                    onMouseEnter={(e) => {
                      // console.log("on")
                      e.currentTarget.style.backgroundColor = "transparent";
                      e.currentTarget.style.transition = "0.1s";
                      e.currentTarget.style.boxShadow =
                        "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                      setMouseEntered(true);
                      setMouseEnteredId(rowData.id);
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                      e.currentTarget.style.scale = 1;
                      e.currentTarget.style.transition = "0.1s";
                      e.currentTarget.style.boxShadow = "none";
                      setMouseEntered(false);
                    }}
                  >
                    {/* Render the table cells based on the rowData */}
                    {cellConfig.map((cell, cellIndex) => (
                      <TableCell
                        className={`cad-text-small ${padding ? padding : "table-row-padding"
                          }`}
                        component="th"
                        scope="row"
                        style={{
                          color: themeColorsObject.textColor,
                        }}
                        key={cellIndex}
                        align={cell.align}
                        width={cell.width}
                        onClick={handleViewOpen ? handleViewOpen(rowData.id) : null}
                      >
                        {rowData[cell.dataKey]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoData message="No Data" />
          )}


          {extractedDataArray?.length > 0 ?
            <div className="p-3 text-primary d-flex justify-content-end">
              <Pagination
                page={currentPage}
                count={totalPages}
                onChange={(e, page) => handlePagination(page)}
                color="primary"
                shape="rounded"
              />
              {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalPages}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
            </div>
            : null}
        </TableContainer>
      )}
    </>
  );
};

export default TableData;
