import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormHelperText } from "@mui/material";

export default function DynamicCheckbox({
  checkboxes,
  onCheckboxChange,
  errors,
}) {
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    // Initialize checked state based on initial values of checkboxes
    const initialCheckedState = checkboxes.map((checkbox) => ({
      id: checkbox.id,
      checked: checkbox.isChecked,
    }));
    setChecked(initialCheckedState);
  }, [checkboxes]);

  const handleChange = (index) => (event) => {
    // Update the checked state for the specific checkbox
    const newChecked = checked.map((checkbox, i) =>
      i === index ? { ...checkbox, checked: event.target.checked } : checkbox
    );
    setChecked(newChecked);
  
    // Call the provided callback with the updated checked items
    onCheckboxChange(getCheckedItemIds(newChecked));
  };

  const getCheckedItemIds = (checkedArray) => {
    // Extract the ids of checked checkboxes
    return checkedArray
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);
  };

  const children = checkboxes.map((checkbox, index) => (
    <FormControlLabel
      key={checkbox.id}
      label={checkbox.title}
      control={
        <Checkbox
          checked={checked[index]?.checked || false}
          onChange={handleChange(index)}
        />
      }
    />
  ));

  const errorMessage = Array.isArray(errors)
    ? errors[0]?.message
    : errors?.message;

  return (
    <div>
      <FormControlLabel
        label="Select all categories"
        id="select-all-checkbox"
        control={
          <Checkbox
            checked={checked.every((checkbox) => checkbox.checked)}
            indeterminate={
              checked.some((checkbox) => checkbox.checked) &&
              checked.some((checkbox) => !checkbox.checked)
            }
            onChange={(event) => {
              const newChecked = checked.map((checkbox) => ({
                ...checkbox,
                checked: event.target.checked,
              }));
              setChecked(newChecked);
              onCheckboxChange(getCheckedItemIds(newChecked));
            }}
          />
        }
      />
      <FormHelperText component={"div"} sx={{ color: "red" }}>
        {errorMessage}
      </FormHelperText>
      <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
        {children}
      </Box>
    </div>
  );
}
