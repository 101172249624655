import { combineReducers } from "redux";
import faqSlice from "../features/faq/faqSlice";
import termsSlice from "../features/cms/termsSlice";
import privacySlice from "../features/cms/privacySlice";
import guidanceSlice from "../features/cms/guidanceSlice";
import gdprSlice from "../features/cms/gdprSlice";
import authSlice from "../features/auth/authSlice";
import faqCategorySlice from "../features/faq/faqCategorySlice";
import supportSlice from "../features/support/support.slice";
import themeSlice from "../Redux/Slices/theme.Slice";
import userSlice from "../features/users/user.slice";
import subscriptionSlice from "../features/subscription/subscription.slice";
import paymentSlice from "../features/payments/payment.slice";
import contactSlice from "../features/contact/contact.slice";
import subAdminSlice from "../features/subAdmin/subAdmin.slice";
import globalSlice from "../features/global/global.slice";
import getFormsSlice from "../Redux/Slices/getFormsSlice";
import userArchiveSlice from "../features/users/user.archive.slice";
import packageSlice from "../features/subscription/package.slice";
import featuresRequestSlice from "../features/features request/featuresRequest.slice";
import chatsSlice from "../features/chat/chats.slice";
import groupSlice from "../features/chat/group.slice";
import userClosedAccountSlice from "../features/users/user.closedAccount.slice";
import paymentFilterSlice from "../features/paymentFilter/payment_filter"; // Import paymentFilterSlice

const rootReducer = combineReducers({
  auth: authSlice,
  faq: faqSlice,
  terms: termsSlice,
  privacy: privacySlice,
  guidance: guidanceSlice,
  gdpr: gdprSlice,
  faqCategory: faqCategorySlice,
  support: supportSlice,
  theme: themeSlice,
  user: userSlice,
  userArchive: userArchiveSlice,
  subscription: subscriptionSlice,
  payment: paymentSlice,
  contact: contactSlice,
  subAdmin: subAdminSlice,
  global: globalSlice,
  getForms: getFormsSlice,
  packageData: packageSlice,
  featuresReq: featuresRequestSlice,
  chat: chatsSlice,
  group: groupSlice,
  userClosedAccount: userClosedAccountSlice,
  paymentFilter: paymentFilterSlice, // Add paymentFilterSlice here
});

export default rootReducer;
