import { createSlice } from "@reduxjs/toolkit";
function toggleId(array = [], id) {
    const index = array?.indexOf(id);
    if (index > -1) {
        array.splice(index, 1);
    } else {
        array.push(id);
    }
    return array;
}
const groupSlice = createSlice({
    name: "group",
    initialState: {
        name: undefined,
        description: undefined,
        participants: [],
        profile: undefined
    },
    reducers: {
        addGroup: (state, action) => {
            state.name = action.payload.name;
            state.description = action.payload.description || undefined;
            state.profile = action.payload.profile || undefined
        },
        selectUnSelectParticipent: (state, action) => {
            state.participants = toggleId(state.participants, action.payload)
        },
        removeGroupDetails: (state, action) => {
            state.name = "";
            state.description = "";
            state.participants = [];
            state.profile = "";
        }
    }
})


export default groupSlice.reducer
export const { addGroup, selectUnSelectParticipent, removeGroupDetails } = groupSlice.actions