import { Chip, Divider, List, ListItem, ListItemText } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React from "react";

const initialValue = {
  title: undefined,
  description: undefined,
  id: undefined,
  status: { title: undefined, id: undefined },
  priority: { title: undefined, id: undefined },
};
const RightComponent = React.memo(({ data }) => {
  const { title, description, id, status, priority } = Object.assign(
    initialValue,
    data
  );
  return (
    <Box p={2}>
      <List aria-label="mailbox folders">
        <ListItem>
          <ListItemText>Ticket Details :{data.title}</ListItemText>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText>Subject: {data.description}</ListItemText>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText>Ticket Id: {data.id}</ListItemText>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText>
            Status :{data.status?.title}
            <Chip
              size="small"
              label={data.status?.title}
              color="success"
              variant="outlined"
            />
          </ListItemText>
        </ListItem>
      </List>
    </Box>
  );
});

RightComponent.prototype = {
  data: PropTypes.object,
};

export default RightComponent;
