import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { paymentFilterService, paymentService } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";


export const payThunkAPI = {
    getAll: createAsyncThunk("payment/getAll", async (searchTerm, { rejectWithValue, abort, getState }) => {
        try {
            const { size, currentPage } = getState().payment
            const params = getRequestParams(searchTerm, currentPage, size)
            // console.log(searchTerm, currentPage, size);
            const res = await paymentService.get(params);
            if (res?.status === 200) return res?.data;
            abort('Something Went wrong');
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
    )
}





const paymentSlice = createSlice({
    name: "payment",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: []
    },

    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(payThunkAPI.getAll.pending, handlePending)
            .addCase(payThunkAPI.getAll.fulfilled, (state, action) => {
                // console.log(action.payload.data);
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data;
                state.rows = filterdata(action?.payload?.data?.rows);
                state.totalItems = action?.payload?.data?.totalItems;
                state.totalPages = action?.payload?.data?.totalPages;
                state.currentPage = action?.payload?.data?.currentPage;
                state.isLoading = false;
            })
            .addCase(payThunkAPI.getAll.rejected, handleRejected);
    }
})

const filterdata = (data) => {
    // console.log(data);
    return data?.map((ele) => {
        return ({
            id: ele.id,
            profile_photo: ele.principal[0]?.profile_photo,
            first_name: ele.principal[0]?.first_name,
            last_name: ele.principal[0]?.last_name,
            name: ele.principal[0]?.first_name + " " + (ele.principal[0]?.last_name ? ele.principal[0]?.last_name : ''),
            package: ele?.checkout_plans?.package?.title,
            amount: ele?.checkout_plans?.unit_amount + " " + ele?.checkout_plans?.currency,
            status: ele?.payment_status,
            recurring: ele?.checkout_plans?.recurring,
            ...ele
        })
    });
}

export default paymentSlice.reducer;
export const { nextPage, previousPage } = paymentSlice.actions;
