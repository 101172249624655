import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserName from "../../Pages/Users/UserName";
import { chatThnkApi, setChat } from "../../features/chat/chats.slice";
import { Modal } from "@mui/material";
import { createOrGetOneOnOneChat } from "../../service/chat.service";
import { Socket } from "socket.io-client";
const filterById = (chats, id) => {
  return chats.find(chat => chat.chatId === id);
};
const NewChatModal = ({
  open,
  onClose,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(chatThnkApi.getUsersList())
  }, [dispatch])
  const chat = useSelector((state) => state?.chat);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredData = chat.users.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const handleChatClick = (id) => async () => {
    try {
      const { data } = await createOrGetOneOnOneChat(id);
      dispatch(chatThnkApi.getChatList());
      // console.log(data);
      if (chat?.socket instanceof Socket && data.payload) {
        chat?.socket.emit("joinChat", data?.payload[0].id)
        dispatch(setChat(filterById(chat?.chats, data?.payload[0].id)))
      }
    } catch (error) {
      console.log(error);
    }
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        className="rounded-2 "
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          height: "90%",
          backgroundColor: "#fff",
          boxShadow: 24,
          p: 4,
        }}
      >
        <div className="d-flex justify-content-center">
          <h4 className="p-3 cad-fw-700">New Chat</h4>
        </div>
        <div className="w-100 mb-3 d-flex justify-content-center">
          <input
            type="text"
            className="form-control searchbox"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="w-100 d-flex flex-column overflow-auto h-75 pb-3">
          {filteredData?.map(
            ({ id, first_name, last_name, profile_photo }, index) => (
              <span
                onClick={handleChatClick(id)}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow =
                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                  e.currentTarget.style.scale = 1;
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow = "none";
                }}
                style={{
                  cursor: "pointer",
                }}
                key={id}
                className="ms-3 me-3 border-bottom p-2  d-flex gap-2 align-items-center "
              >
                <UserName row={{ id, first_name, last_name, profile_photo }} />
              </span>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default NewChatModal;
