import { Button, Chip, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nextPage, supportGetAll } from "../../features/support/support.slice";
import { DeleteModel } from "../../Components/Modals/DeleteModel/DeleteModel";
import StickyHeadTable from "../../Components/Table/StickyHeadTable";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/converter/date.converter";
import * as themeColors from "../../utils/Theme/Colors.js";
import TableData from "../../Components/TableData";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import DateFormatter from "../../Components/DateFormatter";
import SearchInput from "../../Components/SearchInput/SearchInput.js";
import Refresher from "../../Components/Refresher.js";
import { create } from "@mui/material/styles/createTransitions.js";

const Supports = () => {
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject = theme === "light" ? themeColors?.lightTheme : themeColors?.darkTheme;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { totalPages, rows, currentPage, isLoading } = useSelector((state) => state.support);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(supportGetAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(nextPage(1));
    dispatch(supportGetAll(searchTerm));
  }, [dispatch, searchTerm]);


  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");


  const handlePagination = (value) => {
    dispatch(nextPage(value));
    dispatch(supportGetAll());
  };

  const handleViewOpen = (id) => () => {
    navigate(`/ticketdetails/${id}`)
  }

  const cellConfig = [
    { dataKey: "sr_no", align: "left", width: 10 },
    { dataKey: "title", align: "left", width: 20 },
    {
      dataKey: "description",
      headerName: "Description",
      width: 20,
    },
    { dataKey: "status", align: "center", width: 20 },
    { dataKey: "createdAt", align: "center", width: 20 },
  ];

  const tableHeadRowCell = [
    {
      title: "SR NO.",
      width: "10%",
      align: "left",
    },
    {
      title: "Title",
      width: "20%",
      align: "left",
    },
    {
      title: "Description",
      width: "20%",
      align: "left",
    },
    {
      title: "Status",
      width: "20%",
      align: "center",
    },
    {
      title: "CreatedAt",
      width: "25%",
      align: "center",
    }
  ];


  const getColorForTitle = (title) => {
    const colorMap = {
      'On Hold': 'primary',
      'In Progress': 'warning',
      'Completed': 'success',
    };

    if (title && colorMap.hasOwnProperty(title)) {
      return colorMap[title];
    } else {
      return 'error';
    }
  };

  const extractedDataArray = rows?.map((row, index) => {
    const extractedData = {
      key: index,
      id: row?.id,
      sr_no: (currentPage - 1) * 10 + index + 1,
      title: row?.title,
      description: row?.description,
      status: (
        <Chip
          label={row?.status?.title}
          size="small"
          variant="outlined"
          color={getColorForTitle(row?.status?.title)}
        />
      ),
      // createdAt:
      //   mouseEntered && mouseEnteredId === row.id ? (
      //     <div className="d-flex align-items-center justify-content-center">
      //       <span style={{ borderRadius: "18%" }} className="p-2 action-hover text-primary">
      //         <RemoveRedEyeOutlinedIcon
      //           onClick={handleViewOpen(row.id)}
      //           style={{
      //             fontSize: "20px",
      //           }}
      //         />
      //       </span>
      //     </div>
      //   ) : (
      //     <span className=" rounded-2 m-auto p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center  gap-2 pointer cad-text-small">
      //       {/* <formatDateShort inputDate={row?.createdAt} /> */}
      //       {formatDateShort(row?.createdAt)}
      //     </span>
      //   ),
      createdAt: (
        <span className=" rounded-2 m-auto p-1 ps-2 pe-2 text-decoration-none d-flex justify-content-center  gap-2 pointer cad-text-small">
          {formatDate(row?.createdAt)}
        </span>
      )
    }
    return extractedData;
  });

  const handleDelete = (id) => {
    // dispatch(faqDelete(deleteId));
    setOpen(false);
  };

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        // transition:"0.3s"
      }}
      className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
    >
      <DeleteModel
        open={open}
        handleClose={() => setOpen(false)}
        handleDelete={handleDelete}
      />
      <Box
        className=" rounded-top-3"
        sx={{
          borderBottom: 0,
          padding: 2,
          width: "100%",
          overflow: 'auto'
        }}
      >
        <div className='row'>
          <div className="col-3 d-flex align-items-center gap-1 mt-1">
            <SearchInput searchValue={searchTerm} searchFucntion={(e) => setSearchTerm(e.target.value)} />
            <Refresher
              isLoading={isLoading}
              handleRefresh={() => dispatch(supportGetAll())}
            />
          </div>
        </div>

        <TableData
          tableHeadRowCell={tableHeadRowCell}
          extractedDataArray={extractedDataArray}
          cellConfig={cellConfig}
          setMouseEnteredId={setMouseEnteredId}
          setMouseEntered={setMouseEntered}
          totalPages={totalPages}
          handlePagination={handlePagination}
          currentPage={currentPage}
          isLoading={isLoading}
          handleViewOpen={handleViewOpen}
        />

      </Box>

    </div>
  );
};

export default Supports;
