import React from 'react';
import { Line } from 'react-chartjs-2';
import { Paper } from '@mui/material'
import { Box } from '@mui/system'

const UserGraph = ({title}) => {
    const data = {
        labels: ['0', 'Aug 10', 'Sept 10', 'Oct 10', 'Nov 10'],
        datasets: [
            {
                label: 'Single Line Chart',
                data: [30,30, 31, 29, 30, 32, 0],
                fill: true,
                tension: 0.3,
                borderColor: '#548CFF',
                backgroundColor: '#F3F7FF',
            },
        ],
    };
    
    const options = {
        indexAxis: 'x', // Display data along the y-axis (vertical)
    elements: {
        line: {
            borderCapStyle: '', // Add rounded caps for better appearance
        },
    },
        scales: {
            x: {
                display: false, 
            },
            y: {
                display: false, 
            },
        },
        plugins: {
            legend: {
                display: false,
                position: '',
            },
        },
    };
    

  return (
    <Paper
        className='users-graph p-2 h-100'
        elevation={2}>
        <Box className="border-none users h-100">
        <h2 className='mb-3' style={{fontSize:'15px',fontWeight:'500'}}>{title}</h2>
            <div >
                <Line data={data} options={options} />
            </div>
        </Box>
    </Paper>
  )
}

export default UserGraph
