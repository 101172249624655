import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
    TextField,
    Button,
    Paper,
    Grid,
    Card,
    CardContent,
    FormControl,
    Typography,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { subAdminSchema } from "../../utils/schema/subAdmin.schema";
import DynamicCheckbox from "../../Components/CheckBox/DynamicCheckbox";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import * as themeColors from "../../utils/Theme/Colors";
import { datByIdAdd, removeDatById, subAdminThunkAPI } from "../../features/subAdmin/subAdmin.slice";
import { globalThunkAPI } from "../../features/global/global.slice";
import { subAdmin } from "../../service/endpoints";
import BackdropWithLoader from "../../Components/BackdropWithLoader ";
import { AiOutlineLeft } from "react-icons/ai";
import SubAdminSuspendModal from "../../Components/Modals/subAdmin/subAdmin.delete.modal";



const EditSubAdmin = () => {
    const { id } = useParams();

    // console.log(id);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({ mode: "all", resolver: yupResolver(subAdminSchema) });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkBoxValue, setCheckBoxValue] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dateById, setDateById] = useState()
    let global = useSelector((state) => state?.global);
    let { dataById } = useSelector((state) => state?.subAdmin);
    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    async function getByidSubAdmin() {
        try {
            const result = await subAdmin?.getById(id);
            if (result.status === 200) return setDateById(result?.data?.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            setOpen(true);
            try {
                const result = await subAdmin?.getById(id);
                if (result.status === 200) {
                    const newDataById = result?.data?.data;
                    setDateById(newDataById); // Update state with new data
                    setCheckBoxValue(
                        global?.data?.map((checkbox) => ({
                            ...checkbox,
                            isChecked: newDataById?.resources?.some((resource) => resource?.id === checkbox?.id)
                        }))
                    );
                    setValue(
                        "resources",
                        newDataById?.resources?.map((item) => item?.id)
                    );
                    setValue("first_name", newDataById?.first_name);
                    setValue("last_name", newDataById?.last_name);
                    setValue("email_address", newDataById?.email_address);
                    setValue("designation", newDataById?.metadata?.designation);
                    setLoading(false)
                    setOpen(false);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [id, global, setValue]);

    const onSubmit = async (value) => {
        setOpen(true); // Open the loader

        // Create a promise that resolves after 2 seconds
        const delay = new Promise(resolve => setTimeout(resolve, 2000));

        try {
            // Use Promise.all to wait for both the delay and the API call
            await Promise.all([delay, subAdmin.update(id, value)]);

            // At this point, the delay and API call have both completed
            enqueueSnackbar("Sub Admin Updated Successfully", {
                variant: "success"
            });
            setValue("resources", []);
            setCheckBoxValue(
                global?.data?.map((checkbox) => ({
                    ...checkbox,
                    isChecked: false,
                }))
            );
            navigate("/subadmin");
            dispatch(subAdminThunkAPI.getAll());
            reset();
            setValue("first_name", '');
            setValue("last_name", '');
            setValue("email_address", '');
            setValue("designation", '');
        } catch (error) {
            const message = error?.message
            message && enqueueSnackbar(message, {
                variant: "error"
            })
        } finally {
            setOpen(false); // Close the loader
        }
    };

    useEffect(() => {
        dispatch(globalThunkAPI.getResource());
        // dispatch(subAdminThunkAPI.getById(id));
    }, [dispatch, id]);


    const handleCheckboxChange = (newChecked) => {
        setValue("resources", newChecked);
        // // Do something with the updated values (newChecked)
        // console.log("Updated Checkbox Values:", newChecked);
    };


    return (
        <>
            <SubAdminSuspendModal
                handleClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                id={id}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* <BackdropWithLoader open={open} /> */}
                {loading ?
                    (
                        <div
                            style={{
                                height: "87vh",
                                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                                color: themeColorsObject.textColor,
                                // transition:"0.3s"
                            }}
                            className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
                        ></div>
                    ) :
                    (
                        <div
                            style={{
                                height: "87vh",
                                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                                color: themeColorsObject.textColor,
                                // transition:"0.3s"
                            }}
                            className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
                        >
                            <Box padding={1}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <React.Fragment>
                                            <Box p={2}>
                                                <Box className="mb-4">
                                                    <Link
                                                        to="/subadmin"
                                                        className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
                                                    >
                                                        <AiOutlineLeft className="pointer" size={18} />
                                                        <label className="pointer">Edit sub admin</label>
                                                    </Link>
                                                </Box>
                                                <Box>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    size="small"
                                                                    error={errors.first_name ? true : false}
                                                                    label="First Name"
                                                                    helperText={errors.first_name?.message}
                                                                    {...register("first_name")}
                                                                    defaultValue={'First Name'}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    size="small"
                                                                    error={errors.last_name ? true : false}
                                                                    label="Last Name"
                                                                    helperText={errors.last_name?.message}
                                                                    {...register("last_name")}
                                                                    defaultValue={'Last Name'}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    size="small"
                                                                    error={errors.email_address ? true : false}
                                                                    label="Email Id"
                                                                    helperText={errors.email_address?.message}
                                                                    {...register("email_address")}
                                                                    sx={{ mb: 2 }}
                                                                    defaultValue={'Email Address'}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <FormControl fullWidth>
                                                                <TextField
                                                                    size="small"
                                                                    error={errors.designation ? true : false}
                                                                    label="Designation"
                                                                    helperText={errors.designation?.message}
                                                                    {...register("designation")}
                                                                    sx={{ mb: 2 }}
                                                                    defaultValue={'Designation'}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>

                                                    <Box sx={{ mb: 3 }}>
                                                        <Stack spacing={2} direction="row">
                                                            {/* <Button variant="text" onClick={() => navigate("/subadmin")}>
                                                                Back
                                                            </Button> */}
                                                            <Button type="submit" variant="contained">
                                                                Submit
                                                            </Button>
                                                            <Button variant="outlined" onClick={() => setIsModalOpen(true)} color="error">
                                                                Delete
                                                            </Button>
                                                        </Stack>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </React.Fragment>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <React.Fragment>
                                            <CardContent>
                                                <DynamicCheckbox
                                                    checkboxes={checkBoxValue}
                                                    onCheckboxChange={handleCheckboxChange}
                                                    errors={errors.resources}
                                                />
                                            </CardContent>
                                        </React.Fragment>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    )}
            </form>

        </>
    );
};

export default EditSubAdmin;
