import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";

const StickyHeadTable = ({
  columns,
  rows,
  defaultRowsPerPage,
  defaultPage,
  onPageChange,
  onRowsPerPageChange,
  totalRows,
}) => {
  const [page, setPage] = useState(defaultPage || 0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange && onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    onPageChange && onPageChange(0); // Reset page to 0 when rows per page changes
    onRowsPerPageChange && onRowsPerPageChange(newRowsPerPage);
  };

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left">#</TableCell>
              {columns.map(
                (column) =>
                  // Display other columns
                  column.field !== "id" && (
                    <TableCell
                      key={column.field}
                      align="left"
                      style={{ minWidth: column.width }}
                    >
                      {column.headerName}
                    </TableCell>
                  )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const rowIndex = index + 1 + page * rowsPerPage;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell align="left">{rowIndex}</TableCell>
                    {columns.map(
                      (column) =>
                        // Display other columns
                        column.field !== "id" && (
                          <TableCell key={column.field} align="left">
                            {row[column.field]}
                          </TableCell>
                        )
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
};

export default StickyHeadTable;
