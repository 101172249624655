import { Avatar, Button, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../CheckBox";
import UserName from "../../Pages/Users/UserName";
import { removeGroupDetails, selectUnSelectParticipent } from "../../features/chat/group.slice";
import { createNewGroup } from "../../service/chat.service";
import { enqueueSnackbar } from "notistack";
import { chatThnkApi } from "../../features/chat/chats.slice";

const GroupList = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const { themeColorsObject } = useSelector((state) => state.theme);
  const group = useSelector((state) => state.group);
  const chat = useSelector((state) => state?.chat);
  const [profileSrc, setProfileSrc] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (group?.profile instanceof File) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileSrc(event.target.result);
      };
      reader.readAsDataURL(group?.profile);
    }
  }, [group.profile])

  const filteredData = chat?.users.filter(
    (item) =>
      item.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.last_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCheckboxChange = (id) => () => {
    dispatch(selectUnSelectParticipent(id))
  };

  const handleDone = async () => {
    if (group.participants.length > 2) {
      try {
        const formData = new FormData();
        formData.append("name", group.name);
        if (group.description) {
          formData.append("description", group.description);
        }
        if (group?.profile instanceof File) {
          formData.append("profile", group?.profile);
        }
        group.participants.forEach((participantId, index) => {
          return formData.append(`participants[${index}]`, participantId);
        });
        const { data } = await createNewGroup(formData);
        enqueueSnackbar("Create a Group " + data.payload && data.payload[0]?.name)
      } catch (error) {
        console.log(error);
        enqueueSnackbar(error.message, { variant: "error" })
      }
      onClose();
      dispatch(removeGroupDetails());
      dispatch(chatThnkApi.getChatList());
    } else {
      alert('At least 3 participants are mandatory');
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="cad-modal-blur"
    >
      <div
        className="rounded-2 overflow-auto"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 480,
          height: "90%",
          boxShadow: 24,
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
          p: 4,
        }}
      >
        <div className="d-flex  p-4 pb-0 justify-content-center align-items-center flex-column">
          <Avatar
            className="m-1"
            style={{
              width: "100px",
              height: "100px",
              border: "4px solid #E9E9E9",
            }}
            alt={group?.name}
            src={profileSrc}
          />
          <h4 className=" cad-fw-700">
            {group?.name}
          </h4>
          <p className="cad-text-medium">
            {group?.description}
          </p>
        </div>

        <div className="w-100 mb-3 d-flex justify-content-center">
          <input
            type="text"
            className={`form-control p-3 cad-fw-600 cad-text-medium rounded-0 text-center`}
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        <div className="w-100 d-flex flex-column px-2 pb-3">
          {filteredData?.map(
            ({ id, first_name, last_name, profile_photo }, index) => (
              <span
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent";
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow =
                    "rgba(0, 0, 0, 0.24) 0px 3px 8px";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = "transparent"; // Revert to default background color on hover out
                  e.currentTarget.style.scale = 1;
                  e.currentTarget.style.transition = "0.5s";
                  e.currentTarget.style.boxShadow = "none";
                }}
                // style={{
                //   cursor: "pointer",
                // }}
                key={id}
                className=" w-100 d-flex gap-2  border-bottom align-items-center justify-content-between w-100"
              >
                <UserName row={{ id, first_name, last_name, profile_photo }} />
                <span className="p-2 pe-3">
                  <CheckBox isChecked={group.participants?.includes(id)} handleHandleChange={handleCheckboxChange(id)} />
                </span>
              </span>
            )
          )}
        </div>

        <span
          className=" d-flex align-items-center justify-content-center p-3"
          style={{
            height: 70,
            width: "100%",
            position: "absolute",
            left: 0,
            bottom: 0,
          }}
        >
          <Button
            onClick={handleDone}
            variant="contained"
            className="bg-primary rounded-5 p-2 ps-3 pe-3"
          >
            Done
          </Button>
        </span>
      </div>
    </Modal>
  );
};

export default GroupList;
