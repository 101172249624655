import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
    TextField,
    Button,
    Paper,
    Grid,
    Card,
    CardContent,
    FormControl,
    Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { subAdminSchema } from "../../utils/schema/subAdmin.schema";
import DynamicCheckbox from "../../Components/CheckBox/DynamicCheckbox";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { faqCreate, faqGetAll } from "../../features/faq/faqSlice";
import { Link, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { subAdminThunkAPI } from "../../features/subAdmin/subAdmin.slice";
import { globalThunkAPI } from "../../features/global/global.slice";
import { subAdmin } from "../../service/endpoints";
import BackdropWithLoader from "../../Components/BackdropWithLoader ";
import * as themeColors from "../../utils/Theme/Colors";
import { AiOutlineLeft } from "react-icons/ai";

const AddSubAdmin = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({ mode: "all", resolver: yupResolver(subAdminSchema) });

    const [checkBoxValue, setCheckBoxValue] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let faqCategory = useSelector((state) => state?.global);
    const [open, setOpen] = useState(false);
    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

    const onSubmit = async (value) => {
        setOpen(true); // Open the loader

        // Create a promise that resolves after 2 seconds
        const delay = new Promise(resolve => setTimeout(resolve, 2000));

        try {
            // Use Promise.all to wait for both the delay and the API call
            await Promise.all([delay, subAdmin.create(value)]);

            // At this point, the delay and API call have both completed

            enqueueSnackbar("Sub Admin Created Successfully", {
                variant: "success"
            });
            setValue("resources", []);
            setCheckBoxValue(
                faqCategory.data?.map((checkbox) => ({
                    ...checkbox,
                    isChecked: false,
                }))
            );
            navigate("/subadmin");
            dispatch(subAdminThunkAPI.getAll());
            reset();
        } catch (error) {
            const message = error?.message
            message && enqueueSnackbar(message, {
                variant: "error"
            })
        } finally {
            setOpen(false); // Close the loader
        }
    };

    console.log(errors);
    useEffect(() => {
        dispatch(globalThunkAPI.getResource());
    }, [dispatch]);

    useEffect(() => {
        setCheckBoxValue(
            faqCategory.data?.map((checkbox) => ({
                ...checkbox,
                isChecked: false,
            }))
        );
    }, [faqCategory?.data]);

    const handleCheckboxChange = (newChecked) => {
        setValue("resources", newChecked);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <BackdropWithLoader open={open} />
            <div
                style={{
                    height: "87vh",
                    backgroundColor: themeColorsObject.megaCardBackgroundColor,
                    color: themeColorsObject.textColor,
                    // transition:"0.3s"
                }}
                className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
            >
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            {/* <Paper> */}
                            <React.Fragment>
                                <Box p={2}>
                                    <Box className="mb-4">
                                        <Link
                                            to="/subadmin"
                                            className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
                                        >
                                            <AiOutlineLeft className="pointer" size={18} />
                                            <label className="pointer">Add sub admin</label>
                                        </Link>
                                    </Box>
                                    <Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        error={errors.first_name ? true : false}
                                                        label="First Name"
                                                        helperText={errors.first_name?.message}
                                                        {...register("first_name")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        error={errors.last_name ? true : false}
                                                        label="Last Name"
                                                        helperText={errors.last_name?.message}
                                                        {...register("last_name")}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        error={errors.email_address ? true : false}
                                                        label="Email Id"
                                                        helperText={errors.email_address?.message}
                                                        {...register("email_address")}
                                                        sx={{ mb: 2 }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        size="small"
                                                        error={errors.designation ? true : false}
                                                        label="Designation"
                                                        helperText={errors.designation?.message}
                                                        {...register("designation")}
                                                        sx={{ mb: 2 }}
                                                        type="designation"
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Box sx={{ mb: 3 }}>
                                            <Stack spacing={2} direction="row">
                                                {/* <Button variant="text" onClick={() => navigate("/subadmin")}>
                                                    Back
                                                </Button> */}
                                                <Button type="submit" variant="contained">
                                                    Submit
                                                </Button>
                                            </Stack>
                                        </Box>
                                    </Box>
                                </Box>
                            </React.Fragment>
                            {/* </Paper> */}
                        </Grid>
                        <Grid item xs={4}>
                            {/* <Card variant="outlined"> */}
                            <React.Fragment>
                                <CardContent>
                                    <DynamicCheckbox
                                        checkboxes={checkBoxValue}
                                        onCheckboxChange={handleCheckboxChange}
                                        errors={errors.resources}
                                    />
                                </CardContent>
                            </React.Fragment>
                            {/* </Card> */}
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </form >
    );
};

export default AddSubAdmin;
