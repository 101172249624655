import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Paper } from '@mui/material'
import { Box } from '@mui/system'

const ClientGraph = ({title}) => {
    // State to manage the active tab
    const [activeTab, setActiveTab] = useState('tab1');

    // Define data and options for the different datasets
    const data1 = {
        labels: ['0', '5', '10', '15', '20', '25', '30','35','40'],
    datasets: [
        {
            label: 'Curved Line Graph',
            data: [0, 2, 3, 3, 4, 5, 5,6,6],
            fill: true,
            borderColor: '#548CFF',
            backgroundColor: '#F3F7FF',
            tension: 0.2,
            borderWidth:'2'
        },
    ],
    };

    const data2 = {
        labels: ['0', '5', '10', '15', '20', '25', '30','35','40'],
    datasets: [
        {
            label: 'Curved Line Graph',
            data: [0, 1, 3, 3, 4, 4, 5,5,6],
            fill: true,
            borderColor: '#548CFF',
            backgroundColor: '#F3F7FF',
            tension: 0.3,
        },
    ],
    };

    const data3 = {
        labels: ['0', '5', '10', '15', '20', '25', '30','35','40'],
    datasets: [
        {
            label: 'Curved Line Graph',
            data: [0, 1, 2, 2, 3, 3, 4,5,6],
            fill: true,
            borderColor: '#548CFF',
            tension: 0.3,
        },
    ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                position: '',
            },
        },
    };

    // Function to handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Paper
        className='users-graph p-2 h-100'
        elevation={2}>
            <Box className="border-none users h-100">
                <div className='d-flex justify-content-between aligh-items-center'>
                    <h2 style={{fontSize:'15px',fontWeight:'500'}}>{title}</h2>
                    <div className="tab-buttons">
                        <button
                            onClick={() => handleTabChange('tab1')}
                            className={activeTab === 'tab1' ? 'active' : ''}
                        >
                            1 Month
                        </button>
                        <button
                            onClick={() => handleTabChange('tab2')}
                            className={activeTab === 'tab2' ? 'active' : ''}
                        >
                            6 Month
                        </button>
                        <button
                            onClick={() => handleTabChange('tab3')}
                            className={activeTab === 'tab3' ? 'active' : ''}
                        >
                            1 Year
                        </button>
                    </div>
                </div>
                <div className="chart-container">
                    <Line data={activeTab === 'tab1' ? data1 : activeTab === 'tab2' ? data2 : data3} options={options} />
                </div>
            </Box>
        </Paper>
    );
};

export default ClientGraph;
