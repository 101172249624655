import { Button, Chip, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/converter/date.converter";
import * as themeColors from "../../utils/Theme/Colors";
import TableData from "../../Components/TableData";
import { AiOutlineEdit } from "react-icons/ai";
import { nextPage, subAdminThunkAPI } from "../../features/subAdmin/subAdmin.slice";
import { MdDelete } from "react-icons/md";
import SubAdminSuspendModal from '../../Components/Modals/subAdmin/subAdmin.delete.modal'
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";
import UserProfile from "../../Components/GlobalTable/UserProfile";
import UserName from "../Users/UserName";

const SubAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;
    const { totalPages, currentPage, rows, isLoading } = useSelector((state) => state?.subAdmin);
    const [mouseEntered, setMouseEntered] = useState(false);
    const [mouseEnteredId, setMouseEnteredId] = useState("");
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dispatch(subAdminThunkAPI.getAll());
    }, [dispatch]);

    useEffect(() => {
        dispatch(nextPage(1));
        dispatch(subAdminThunkAPI.getAll(searchTerm)); // Pass search term to getAll thunk
    }, [dispatch, searchTerm]);

    // trigger modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const openModal = (userId) => {
        setIsModalOpen(true);
        setSelectedUserId(userId);
        // console.log(userId);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUserId(null)
    };

    const cellConfig = [
        // { dataKey: "sr_no", align: "start", width: 5 },
        { dataKey: "name", align: "start", width: 20 },
        { dataKey: "resource", align: "start", width: 25 },
        { dataKey: "email_address", align: "start", width: 25, },
        { dataKey: "designation", align: "start", width: 10, },
        { dataKey: "createdAt", align: "start", width: 25, },
    ];

    const tableHeadRowCell = [
        // {
        //     title: "Sr No.",
        //     width: "5%",
        //     align: "start",
        // },
        {
            title: "Name",
            width: "20%",
            align: "start",
        },
        {
            title: "Resource",
            width: "25%",
            align: "start",
        },
        {
            title: "Email address",
            width: "25%",
            align: "start",
        },
        {
            title: "Designation",
            width: "10%",
            align: "start",
        },
        {
            title: "Created At",
            width: "25%",
            align: "start",
        },
    ];

    const extractedDataArray = rows?.map((row, index) => {
        const extractedData = {
            // sr_no: index + 1,
            id: row?.id,
            name: <UserName row={row} />,
            resource: <Stack
                spacing={{ xs: 1, sm: 2 }}
                direction="row"
                useFlexGap
                flexWrap="wrap"
            >
                {row.resources?.map((item) => (
                    <Chip
                        key={item.id}
                        label={item.title}
                        variant="outlined"
                        color="info"
                        size="small"
                    />
                ))}
            </Stack>,
            email_address: row?.email_address,
            createdAt: formatDate(row?.createdAt),
            designation : (row?.metadata)?.designation
            // designation: mouseEntered && mouseEnteredId === row?.id ? (
            //     <div>
            //         <span style={{ borderRadius: "18%" }} className="p-2 action-hover text-primary">
            //             <AiOutlineEdit
            //                 onClick={() => navigate(`/EditSubAdmin/${row.id}`)}
            //                 style={{ fontSize: "20px" }}
            //             />
            //         </span>
            //         <span style={{ borderRadius: "18%" }} className="p-2 action-hover text-primary">
            //             <MdDelete
            //                 onClick={() => openModal(row?.id)}
            //                 style={{ fontSize: "20px" }}
            //             />
            //         </span>
            //     </div>
            // ) : (row?.metadata)?.designation,
        }
        return extractedData;
    });

    const handlePagination = (value) => {
        // console.log(value);
        dispatch(nextPage(value));
        dispatch(subAdminThunkAPI.getAll());
    };

    const handleViewOpen = (id) => () => {
        navigate(`/EditSubAdmin/${id}`)
    }

    return (
        <div
            style={{
                height: "87vh",
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
                // transition:"0.3s"
            }}
            className="w-100  mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
        >
            <Box
                className=" rounded-top-3"
                sx={{
                    borderBottom: 0,
                    padding: 2,
                    width: "100%",
                }}
            >
                {/* <Stack
                    direction="row"
                    justifyContent="between"
                    alignItems="center"
                    spacing={2}
                >
                    <Button variant="outlined" onClick={() => navigate("/addSubAdmin")}>
                        Add
                    </Button>
                </Stack> */}

                <div className="row d-flex align-items-center">
                    <div className="col-3 d-flex align-items-center gap-1 mt-1">
                        <SearchInput searchValue={searchTerm} searchFucntion={(e) => setSearchTerm(e.target.value)} />
                        <Refresher
                            isLoading={isLoading}
                            handleRefresh={() => dispatch(subAdminThunkAPI.getAll())}
                        />
                    </div>
                    <div className="col-9 text-end">
                        <Button variant="outlined" onClick={() => navigate("/addSubAdmin")}>
                            Add
                        </Button>
                    </div>
                </div>

                <TableData
                    tableHeadRowCell={tableHeadRowCell}
                    extractedDataArray={extractedDataArray}
                    cellConfig={cellConfig}
                    setMouseEnteredId={setMouseEnteredId}
                    setMouseEntered={setMouseEntered}
                    totalPages={totalPages}
                    handlePagination={handlePagination}
                    currentPage={currentPage}
                    isLoading={isLoading}
                    handleViewOpen={handleViewOpen}
                />

                {isModalOpen && (
                    <SubAdminSuspendModal
                        handleClose={closeModal}
                        open={isModalOpen}
                        id={selectedUserId}
                    />
                )}

            </Box>
        </div>
    );

}

export default SubAdmin