import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Modal } from '@mui/material';
import { Paper } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import PrimaryButton from '../../Components/PrimaryButton';
import { LiaUserTimesSolid } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../Redux/Slices/userSlice/userSlice';

const UserView = ({ handleClose, open }) => {

  const dispatch = useDispatch();

  const tabledata = useSelector((state) => state.userSlice.data);
  // console.log(tabledata?.rows);

  // useEffect(() => {
  //   console.log(tabledata);
  // }, [tabledata])

  useEffect(() => {
    dispatch(fetchUser());
  }, []);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius: 1
  };

  const iconstyle = {
    color: '#red'
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper elevation={2}>
          <Box
            sx={style}
          >
            <div className="modalhead d-flex justify-content-between align-items-center mb-3">
              <h2 className="fs-5 mb-0">View Details</h2>
              <h3 onClick={handleClose} className="text-end pointer">
                <CloseIcon />
              </h3>
            </div>
            <table className="table table-borderless">
              <tbody>
                <tr>
                  <td className="pb-3">Name</td>
                  <td className="pb-3">{tabledata?.rows[0]?.first_name}</td>
                </tr>
                <tr>
                  <td className="pb-3">Phone</td>
                  <td className="pb-3">{tabledata?.rows[0]?.phone_number}</td>
                </tr>
                <tr>
                  <td className="pb-3">Email</td>
                  <td className="pb-3">{tabledata?.rows[0]?.email_address}</td>
                </tr>
                <tr>
                  <td className="pb-3">Total Client</td>
                  <td className="pb-3">50</td>
                </tr>
                <tr>
                  <td className="pb-3">Subscription Detail</td>
                  <td className="pb-3 w-10 "><div style={{ width: '150px' }}><PrimaryButton title='View' /></div></td>
                </tr>
              </tbody>
            </table>
            <button style={{ border: '0', backgroundColor: 'transparent', color: '#E45D5B' }}> <LiaUserTimesSolid size={22} style={{ color: '#E45D5B' }} /> Suspend an account</button>
          </Box>
        </Paper>
      </Modal>
    </div>
  )
}

export default UserView;