import React, { useCallback, useEffect, useState } from "react";
import "./Dashboard.css";
import DashbordCard from "../../Components/DashboardCard/DashbordCard";
import DashCharts from "../../Components/DashboardCard/DashCharts";
import GlobalTable from "../../Components/GlobalTable/GlobalTable";
import UserProfile from "../../Components/GlobalTable/UserProfile";
import { Chip, Paper } from "@mui/material";
import { Box } from "@mui/system";
import * as themeColors from "../../utils/Theme/Colors";
import { useDispatch, useSelector } from "react-redux";
import TableData from "../../Components/TableData";
import { nextPage, userThunkAPI } from "../../features/users/user.slice";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import UserViewModal from "../../Components/Modals/users/user.view.modal";
import { subsThunkAPI } from "../../features/subscription/subscription.slice";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";
import UserName from "../Users/UserName";
import { dashboard, dashboardservice } from "../../service/endpoints";

export const formatDate = (dateString) => {
  const dateObject = new Date(dateString);
  const options = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true, // Use 12-hour format
  };
  const formattedDate = `${dateObject.toLocaleDateString("en-US", options)}`;
  return formattedDate;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [dashValue, setDashValue] = useState({
    activeSubscription: Number,
    freeUserCount: Number,
    taskCount: Number,
    usersCount: Number,
    totalRevenue: undefined,
  });

  useEffect(() => {
    (async () => {
      try {
        const { data } = await dashboardservice.get();
        // console.log(data.data);
        setDashValue(data.data);
      } catch (error) {
        console.log(error.response);
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    dispatch(userThunkAPI.getAll());
    dispatch(subsThunkAPI.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(nextPage(1));
    dispatch(userThunkAPI.getAll(searchTerm)); // Pass search term to getAll thunk
  }, [dispatch, searchTerm]);

  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const { totalPages, rows, totalItems, currentPage, isLoading } = useSelector(
    (state) => state?.user
  );
  const subsCount = useSelector((state) => state?.subscription);
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");

  // console.log(rows);

  // trigger modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const cellConfig = [
    // { dataKey: "sr_no", align: "left", width: 15 },
    { dataKey: "name", align: "left", width: 25 },
    {
      dataKey: "email_address",
      align: "left",
      width: 25,
    },
    { dataKey: "verfied_user", align: "left", width: 10 },
    { dataKey: "sub_type", align: "left", width: 20 },
    { dataKey: "createdAt", align: "left", width: 30 },
  ];

  const tableHeadRowCell = [
    // {
    //   title: "S. No",
    //   width: "15%",
    //   align: "left",
    // },
    {
      title: "User",
      width: "20%",
      align: "left",
    },
    {
      title: "Email address",
      width: "25%",
      align: "left",
    },
    {
      title: "Verified user",
      width: "15%",
      align: "left",
    },
    {
      title: "Subscription",
      width: "10%",
      align: "left",
    },
    {
      title: "Create At",
      width: "20%",
      align: "left",
    },
  ];

  const extractedDataArray = rows?.map((row, index) => {
    const extractedData = {
      id: row?.id,
      sr_no: (currentPage - 1) * 10 + index + 1,
      name: <UserName row={row} />,
      email_address: row?.email_address,
      phone_number: row?.phone_number, // need values from backend
      createdAt: formatDate(row?.createdAt),
      sub_type:
        row?.business && row?.business[0]?.subscribed_user ? (
          <Chip
            label={"subscriber"}
            color="success"
            variant="outlined"
            size="small"
          />
        ) : (
          <Chip label={"free"} color="error" variant="outlined" size="small" />
        ),
      verfied_user: row?.verfied_user ? (
        <Chip
          label={"Verified"}
          color="success"
          variant="outlined"
          size="small"
        />
      ) : (
        <Chip
          label={"Not-Verified"}
          color="error"
          variant="outlined"
          size="small"
        />
      ),
    };
    return extractedData;
  });

  const handleViewOpen = (id) => () => {
    // console.log(id);
    setIsModalOpen(true);
    setSelectedUserId(id);
    // navigate(`/user-details/${id}`);
  };

  const handlePagination = (value) => {
    // console.log(value);
    dispatch(nextPage(value));
    dispatch(userThunkAPI.getAll());
  };

  return (
    <div className="dashboard mb-4 pb-1  overflow-auto  align-items-start d-flex w-85 row p-0  rounded-2 text-center">
      <DashbordCard
        themeColorsObject={themeColorsObject}
        iconName="GoPeople"
        title="Users"
        count={dashValue?.freeUserCount}
        disc="Total Number of free trials"
      />
      <DashbordCard
        themeColorsObject={themeColorsObject}
        iconName="FaDollarSign"
        title="Company"
        count={dashValue?.activeSubscription}
        disc="Total Number of subscription"
      />
      <DashbordCard
        themeColorsObject={themeColorsObject}
        iconName="FaUsers"
        title="Contacts"
        count={dashValue?.usersCount}
        disc="Total Number of Users"
      />
      <DashbordCard
        themeColorsObject={themeColorsObject}
        iconName="FaDollarSign"
        title="Contacts"
        count={dashValue?.totalRevenue?.total_transaction_value}
        disc="Total Revenue"
      />

      <div className="col-lg-12 col-12 p-2">
        <DashCharts
          themeColorsObject={themeColorsObject}
          title="Subscription"
        />
      </div>

      <Paper
        style={{
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
        }}
        elevation={2}
        className=" mt-3 w-100 d-flex overflow-hidden flex-column justify-content-start align-items-start"
      >
        <Box
          className="custom-panel  border-bottom rounded-top-3 p-3"
          sx={{
            borderBottom: 0,
            padding: 0,
            // backgroundColor: "#fff",
            width: "100%",
          }}
        >
          <div className="d-flex justify-content-between align-items-center mb-2 row">
            <div className="col-9 d-flex justify-content-start">
              <label className="cad-text-large cad-fw-600 text-start">
                Recently registered Users
              </label>
            </div>
            <div className="col-3 d-flex align-items-center gap-1">
              <Refresher
                isLoading={isLoading}
                handleRefresh={() => dispatch(userThunkAPI.getAll())}
              />
              <SearchInput
                searchValue={searchTerm}
                searchFucntion={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <TableData
            tableHeadRowCell={tableHeadRowCell}
            extractedDataArray={extractedDataArray}
            cellConfig={cellConfig}
            setMouseEnteredId={setMouseEnteredId}
            setMouseEntered={setMouseEntered}
            totalPages={totalPages}
            handlePagination={handlePagination}
            currentPage={currentPage}
            isLoading={isLoading}
            handleViewOpen={handleViewOpen}
          />

          {isModalOpen && (
            <UserViewModal
              handleClose={closeModal}
              open={isModalOpen}
              id={selectedUserId}
            />
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default Dashboard;
