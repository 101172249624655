import React from 'react'
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const Notification = () => {
  return (
    <div className='p-3  rounded-3 notification bg-white mt-5'>
      <div className='pb-2 mb-2' style={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid #EBEBEB'}}>
        <h4 className='cad-fw-500 cad-text-medium' style={{marginBottom:'0', color: '#4A5057'}}>Notify users or clients by email for each task activity?</h4>
        <Switch {...label} defaultChecked />
      </div>
      <div className='pb-2 mb-2' style={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid #EBEBEB'}}>
        <h4 className='cad-fw-500 cad-text-medium' style={{marginBottom:'0', color: '#4A5057'}}>Notify users or clients by SMS for each task activity?</h4>
        <Switch {...label} defaultChecked />
      </div>
      <div className='pb-2 mb-2' style={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid #EBEBEB'}}>
        <h4 className='cad-fw-500 cad-text-medium' style={{marginBottom:'0', color: '#4A5057'}}>Notify users or clients by SMS for each task activity?</h4>
        <Switch {...label} defaultChecked />
      </div>
      <div className='pb-2 mb-2' style={{display:'flex',justifyContent:'space-between',alignItems:'center',borderBottom:'1px solid #EBEBEB'}}>
        <h4 className='cad-fw-500 cad-text-medium' style={{marginBottom:'0', color: '#4A5057'}}>Notify users or clients by toast notification for each task activity?</h4>
        <Switch {...label} defaultChecked />
      </div>
    </div>
  )
}

export default Notification
