import * as yup from "yup";
export const landingSchema = yup.object().shape({
  title: yup
    .string()
    .required("Title is required")
    .min(4, "Title should be atleast 4 characters")
    .max(200),
  email: yup
    .string()
    .required("Email is required")
    .email("Email address must be a valid email"),
  address: yup.string().required("Address is required"),
  phone: yup
    .number()
    .typeError("Phone number must be a number")
    .integer("Phone number must be an integer")
    .required("Phone number is required"),
  mobile: yup
    .number()
    .typeError("Mobile number must be a number")
    .integer("Mobile number must be an integer")
    .required("Mobile number is required"),
  about: yup.string().required("About Us content is required"),
  subtitle: yup
    .string()
    .required("Sub title is required")
    .min(4, "Sub title should be atleast 4 characters")
    .max(200),
  description: yup.string().required("Description is required"),
  Off_on_Non_Profits: yup.string().required("Description is required"),
});
