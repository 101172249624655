// UserViewModal.jsx
import React, { useEffect, useState } from 'react';
import { Avatar, Box, Chip, IconButton, Modal, Paper, Tooltip } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { paymentService } from '../../../service/endpoints';
import PdfDocument from '../../PdfDocument';
// import { PDFDownloadLink } from '@react-pdf/renderer';
import BackdropWithLoader from '../../BackdropWithLoader ';
import { getRandomColor } from '../../../utils/getRandomColor';

const domain = process.env.REACT_APP_BACKEND_BASEURL
const PaymentViewModal = ({ handleClose, open, id }) => {

    const [data, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [bgColor, setBgColor] = useState(null);

    useEffect(() => {
        // Generate a random color only if it's not already generated
        if (!bgColor) {
            const randomColor = getRandomColor();
            setBgColor(randomColor);
        }
    }, [bgColor]);

    useEffect(() => {
        // const fetchData = async () => {
        //     try {
        //         const details = await paymentService.deatils(id);
        //         // Handle response data here
        //         setData(details?.data?.data)
        //         console.log('Payment details:', details?.data?.data);
        //         setLoading(false)
        //     } catch (error) {
        //         // Handle error here
        //         console.error('Error fetching payment details:', error);
        //     }
        // };

        // if (open && id) {
        //     fetchData();
        // }
        (async () => {
            setLoading(true)
            try {
                const details = await paymentService.deatils(id);
                setData(details?.data?.data)
                console.log('Payment details:', details?.data?.data);
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.error('Error fetching payment details:', error);
            }
        })()
    }, [id]);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 850,
        bgcolor: '#fff',
        boxShadow: 24,
        p: 4,
        borderRadius: 1
    };

    console.log(domain);
    return (
        <>
            {loading && data?.principal ?
                <BackdropWithLoader open={loading} /> :
                (<div>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper elevation={2}>
                            <Box
                                sx={style}
                            >
                                <div className="modalhead d-flex justify-content-between align-items-center mb-3">
                                    <div className='d-flex justify-content-start align-items-center gap-2'>
                                        {/* <Avatar
                                            alt="Img"
                                            src={`${domain}/${data?.principal && data?.principal[0]?.profile_photo}`}
                                            sx={{ width: 56, height: 56 }}
                                        /> */}
                                        {data?.principal && data?.principal[0]?.profile_photo ? (
                                            <Avatar
                                                alt="Img"
                                                src={`${domain}/${data?.principal && data?.principal[0]?.profile_photo}`}
                                                sx={{ width: 25, height: 25 }}
                                            />
                                        ) : (
                                            <Avatar sx={{ bgcolor: bgColor, width: 25, height: 25 }}>
                                                {data?.principal[0]?.first_name?.charAt(0) + data?.principal[0]?.last_name?.charAt(0)}
                                            </Avatar>
                                        )}
                                        <h2 className="fs-5 mb-0">
                                            {`${data?.principal[0]?.first_name} ${data?.principal[0]?.last_name ? data?.principal[0]?.last_name : ''}`}
                                        </h2>
                                        <Tooltip title="Download Invoice" placement="right-start">
                                            {/* PDF Download Link */}
                                            {/* <PDFDownloadLink document={<PdfDocument data={data} />} fileName="invoice.pdf">
                                                {({ blob, url, loading, error }) =>
                                                    loading ? ( */}
                                            {/* <IconButton>
                                                <DownloadForOfflineIcon />
                                            </IconButton> */}
                                            {/* //         ) : (
                                            //             <IconButton>
                                            //                 <DownloadForOfflineIcon />
                                            //             </IconButton>
                                            //         )
                                            //     } */}
                                            {/* // </PDFDownloadLink> */}
                                        </Tooltip>
                                    </div>
                                    <h3 onClick={handleClose} className="text-end pointer">
                                        <CloseIcon />
                                    </h3>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="pb-3 fw-semibold">Title</td>
                                                    <td className="pb-3">
                                                        {data?.checkout_plans?.package?.title ? data?.checkout_plans?.package?.title : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-3 fw-semibold">Recurring</td>
                                                    <td className="pb-3">
                                                        {data?.checkout_plans && data?.checkout_plans?.recurring === "1" ? "Monthly" : "Annually"}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-3 fw-semibold">Amount</td>
                                                    <td className="pb-3">
                                                        {data?.checkout_plans && `${data?.checkout_plans?.unit_amount} ${data?.checkout_plans?.currency}`}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-md-6'>
                                        <table className="table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td className="pb-3 fw-semibold">Payment status</td>
                                                    <td className="pb-3">
                                                        {
                                                            data?.payment_status === 'paid'
                                                                ? <Chip label="Paid" color="success" variant="outlined" size='small' />
                                                                : <Chip label="Unpaid" color='error' variant="outlined" size='small' />
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-3 fw-semibold">Email Id</td>
                                                    <td className="pb-3">
                                                        {data?.principal[0]?.email_address ? data?.principal[0]?.email_address : '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="pb-3 fw-semibold">Phone No.</td>
                                                    <td className="pb-3">
                                                        {data?.principal[0]?.phone_number ? data?.principal[0]?.phone_number : '-'}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Box>
                        </Paper>
                    </Modal>
                </div>)}
        </>

    )
}

export default PaymentViewModal;
