import React, { useEffect, useState } from "react";
import * as themeColors from "../../utils/Theme/Colors";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { LiaUserTimesSolid } from "react-icons/lia";
import { userThunkAPI } from "../../features/users/user.slice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import UserSuspendModal from "../Modals/users/user.suspend.modal";
import StickyHeadTable from "../Table/StickyHeadTable";
import ListAltIcon from '@mui/icons-material/ListAlt';
import BasicTabs from "../Tabs/BasicTabs";
import NoDataFound from "../NoDataFound/NoDataFound";
import { formatDateShort, formatDate } from "../../utils/converter/date.converter";
import { Line } from "react-chartjs-2";
import { AiOutlineLeft } from "react-icons/ai";
const UserDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.user?.dataById); // Access data by ID from Redux state
  const theme = useSelector((state) => state.theme.theme);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  useEffect(() => {
    dispatch(userThunkAPI.getById(id));
  }, [dispatch, id]);

  const CheckoutSession_columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "plan", headerName: "Paln", width: 130 },
    { field: "package", headerName: "Package id", width: 130 },
    { field: "frequency", headerName: "Frequency", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
  ];
  const CheckoutSession__rows = userData?.checkout_sessions?.map((row, index) => ({
    id: row.id,
    plan: row?.checkout_plans?.unit_amount + " " + row?.checkout_plans?.currency,
    package: row?.checkout_plans?.package.title,
    frequency: row?.checkout_plans?.frequency,
    status: row?.payment_status === "paid" ? (
      <Chip label={row?.payment_status} variant="outlined" color="success" size="small" />
    ) : (
      <Chip label={row?.payment_status} variant="outlined" color="error" size="small" />
    ),
    createdAt: formatDate(row.createdAt),
  }));

  const subscription_columns = [
    { field: "id", headerName: "ID", width: 30 },
    { field: "subscription_id", headerName: "Subscription Id", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
    { field: "start_date", headerName: "Start date", width: 130 },
    { field: "end_date", headerName: "End date", width: 130 },
    { field: "canceled_at", headerName: "Canceled At", width: 130 },
    { field: "package", headerName: "Package", width: 130 },
    { field: "plan", headerName: "Plan", width: 130 },
    { field: "createdAt", headerName: "Created At", width: 130 },
  ];
  const subscription__rows = userData?.business && userData?.business[0]?.subscriptions?.map((row, index) => ({
    id: index + 1,
    subscription_id: row?.stripe_subscription_id,
    package: row?.package?.title,
    plan: row?.plan?.frequency,
    status: row.status !== "canceled" ? (
      <Chip label={"Active"} variant="outlined" color="success" size="small" />
    ) : (
      <Chip label={"canceled"} variant="outlined" color="error" size="small" />
    ),
    start_date: formatDate(row.start_date),
    end_date: formatDate(row.end_date),
    canceled_at: row.canceled_at ? formatDate(row.canceled_at) : null,
    createdAt: formatDateShort(row.createdAt),
  }));

  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);

  const tabs = [
    {
      label: "CheckOut Session",
      content: !userData?.checkout_sessions?.length > 0 ?
        (<NoDataFound title={'No Data Found'} subtitle={'No record add in checkout session these current date'} icon={<ListAltIcon />} />)
        :
        (<StickyHeadTable
          columns={CheckoutSession_columns}
          rows={CheckoutSession__rows}
          defaultRowsPerPage={5}
          defaultPage={0}
          totalRows={userData?.checkout_sessions?.length}
        />),
    },
    {
      label: "Subscription List",
      content: userData?.business && !userData?.business[0]?.subscriptions?.length > 0 ?
        <NoDataFound title={'No Data Found'} subtitle={'No record add in Subscription these current date'} icon={<ListAltIcon />} /> :
        <StickyHeadTable
          columns={subscription_columns}
          rows={subscription__rows}
          defaultRowsPerPage={5}
          defaultPage={0}
          totalRows={userData?.business && userData?.business[0]?.subscriptions?.length}
        />,
    },
  ];

  return (
    <>
      <UserSuspendModal
        handleClose={() => setIsSuspendModalOpen(false)}
        open={isSuspendModalOpen}
        id={id}
      />
      <Box
        style={{
          height: "85vh",
          backgroundColor: themeColorsObject.megaCardBackgroundColor,
          color: themeColorsObject.textColor,
          // transition:"0.3s"
        }}
        className="w-100 overflow-auto mb-4 d-flex rounded-3 mt-1 cad-shadow flex-column justify-content-start align-items-start"
      >
        <Box sx={{ p: 3, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "18px",
            }}
          >
            <Link
              to="/users"
              className="mb-2 d-flex align-items-center gap-1 text-decoration-none"
            >
              <AiOutlineLeft className="pointer me-1" size={18} />
              <label className="pointer">User Details</label>
            </Link>
            <button
              onClick={() => setIsSuspendModalOpen(true)}
              style={{
                border: "0",
                backgroundColor: "transparent",
                color: "#E45D5B",
              }}
            >
              <LiaUserTimesSolid size={22} style={{ color: "#E45D5B" }} />{" "}
              Suspend an account
            </button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="modalhead d-flex justify-content-between align-items-center mb-1">
                <h2 className="fs-5 mb-0">Profile</h2>
              </div>
              <table className="table table-borderless mb-4">
                <tbody>
                  <tr>
                    <td className="pb-1 w-25">Name</td>
                    <td className="pb-1">
                      {userData?.first_name ? userData?.first_name : "-"}
                      {userData?.last_name ? userData?.last_name : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">Phone</td>
                    <td className="pb-1">
                      {userData?.phone_number ? userData?.phone_number : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">Email</td>
                    <td className="pb-1">
                      {userData?.email_address ? userData?.email_address : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">Country</td>
                    <td className="pb-1">
                      {userData?.country ? userData?.country : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
            {/* <Grid item xs={6}>
              <div className="modalhead d-flex justify-content-between align-items-center mb-1">
                <h2 className="fs-5 mb-0">Address</h2>
              </div>
              <table className="table table-borderless mb-4">
                <tbody>
                  <tr>
                    <td className="pb-1 w-25">City</td>
                    <td className="pb-1">
                      {userData?.city ? userData?.city : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">State</td>
                    <td className="pb-1">
                      {userData?.state ? userData?.state : "-"}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">Country</td>
                    <td className="pb-1">
                      {userData?.country ? userData?.country : "-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid> */}
            <Grid item xs={6}>
              <div className="modalhead d-flex justify-content-between align-items-center mb-1">
                <h2 className="fs-5 mb-0">Business</h2>
              </div>
              <table className="table table-borderless mb-4">
                <tbody>
                  <tr>
                    <td className="pb-1 w-25">Business name</td>
                    <td className="pb-1">
                      {/* {userData?.business_name ? userData?.business_name : "-"} */}
                      {userData?.business && userData?.business[0].business_name}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">Business Email</td>
                    <td className="pb-1">
                      {/* {userData?.business_name ? userData?.business_name : "-"} */}
                      {userData?.business && userData?.business[0].business_email}
                    </td>
                  </tr>
                  <tr>
                    <td className="pb-1 w-25">Business Type</td>
                    <td className="pb-1">{userData?.business && userData?.business[0].business_type}</td>
                  </tr>
                  {/* <tr>
                    <td className="pb-1 w-25">Business URL</td>
                    <td className="pb-1">
                      {userData?.business_url ? userData?.business_url : "-"}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </Grid>
            <Grid item xs={12}>
              <BasicTabs tabs={tabs} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default UserDetails;
