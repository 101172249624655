import React, { useEffect, useState } from "react";
import PrimaryButton from "../../Components/PrimaryButton";
import { AiOutlinePlus } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa6";
import SecondaryButton from "../../Components/SecondaryButton";
import { Button, Chip, Grid } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Link } from "react-router-dom";
import { formArray } from "./formArray";
import FormViewModal from "./FormViewModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchForms } from "../../Redux/Slices/getFormsSlice";
import SearchInput from "../../Components/SearchInput/SearchInput";
import Refresher from "../../Components/Refresher";
import { form } from "../../service/endpoints";
import { enqueueSnackbar } from "notistack";
import { Box } from "@mui/system";
// import { fetchForms } from "../../Redux/Slice/getFormsSlice";

const Form = () => {
  const [openFormView, setOpenFormView] = useState(false);
  const [mouseEnterId, setMouseEnterId] = useState("");
  const dispatch = useDispatch();
  const { theme, themeColorsObject } = useSelector((state) => state?.theme);

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  const { formArray, isLoading } = useSelector((state) => state?.getForms);
  // console.log(formArray);

  const [filteredTemplates, setFilteredTemplates] = useState(formArray);

  const [searchText, setSearchText] = useState("");

  const handleOpenFormView = (id) => {
    setMouseEnterId(id);
    setOpenFormView(true);
  };
  const handleCloseFormView = () => setOpenFormView(false);

  // const filterTemplates = (searchText) => {
  //   const filtered = formArray.filter((template) =>
  //     template.title.toLowerCase().includes(searchText.toLowerCase())
  //   );
  //   setFilteredTemplates(filtered);
  // };

  // const handleSearchInputChange = (e) => {
  //   const text = e.target.value;
  //   setSearchText(text);
  //   filterTemplates(text);
  // };
  // console.log("====[ filtered template ]======>", filteredTemplates);

  const filtered = formArray.filter((template) =>
    template.title.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleSearchInputChange = (e) => {
    const text = e.target.value;
    setSearchText(text);
  };

  // TODO create handlerToggle() await form.toggleForm()
  const handleToggle = (id) => async () => {
    // alert(id);
    try {
      const result = await form.toggleForm(id);
      // console.log(result);
      dispatch(fetchForms());
      enqueueSnackbar(result.data.message, { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response.data.error.message, { variant: "error" });
    }
  };
  
  const handleDelete = (id) =>  async() => {
    // alert(id)
    try {
      const result = await  form.delete(id);
      // console.log(result)
      dispatch(fetchForms());
      enqueueSnackbar(result.data.message, { variant: "success" });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.response.data.error.message, { variant: "error" });
    }
  }


  

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor,
        //   transition:"0.3s"
      }}
      className=" cad-shadow overflow-auto rounded-3 mb-4 mt-1 "
    >
      {/* =======[ Top Section ]====== */}
      <div className="d-flex align-items-center justify-content-between  p-3 col-md-12 row">
        <div className="col-9">
          <label className="cad-fw-500 cad-text-xlarge">
            Pick your template
          </label>
        </div>
        <div className="col-3 d-flex align-items-center gap-1">
          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(fetchForms())}
          />
          <SearchInput
            searchValue={searchText}
            searchFucntion={handleSearchInputChange}
          />
        </div>
      </div>

      <div className="d-flex gap-4 flex-wrap p-3 w-100">
        <Link
          to="/template-forms/new-form"
          style={{
            backgroundColor: "#DFFFEF",
            height: "320px",
            width: "23%",
            textDecoration: "none",
          }}
          className="col-3  rounded-3  create-form-card p-5 d-flex justify-content-center align-items-center flex-column"
        >
          <AiOutlinePlus color="#4CCD8D" size={40} />
          <h4 style={{ color: "#4CCD8D" }} className="cad-text-large">
            Create Form
          </h4>
        </Link>

        {filtered?.map(({ id, title, status }) => (
          <div
            key={id}
            style={{
              height: "320px",
              width: "23%",
            }}
            className=" create-form-card d-flex justify-content-between align-items-between flex-column position-relative"
          >
            <div className="form-hover h-75 form-png rounded-3 w-100">
              {/* <iframe src={`/template-forms/${id}`} width="100%" height="100%" title={`iframe-${id}`}></iframe> */}

              <span
                onClick={() => handleOpenFormView(id)}
                className="icon-center rouded-3 d-flex gap-2 ps-3 pe-3 p-2 cad-fw-700 text-white"
                // style={{ position: 'absolute' }}
              >
                <RemoveRedEyeIcon style={{ color: "#ffffff" }} />
                Preview
              </span>
            </div>

            <div className="d-flex flex-column gap-1">
              <Grid
                sx={{
                  justifyContent: "space-between",
                  alignContent: "center",
                  width: "100%",
                  display: "flex",
                }}
              >
                <span className="cad-text-medium cad-fw-600">{title}</span>
                <span>
                  <Chip
                    label={status ? "public" : "Private"}
                    color={status ? "success" : "error"}
                    size="small"
                  />
                </span>
              </Grid>
              <Link to={ status ? `/template-forms/${id}` : `/template-preview/${id}`}>
                <Button variant="outlined" className="button-outlined-preview">
                  Use Template
                </Button>
              </Link>
              {/* TODO add logic here to toggle status */}
              <Box
                sx={{
                  justifyContent: "center",
                  gap:1,
                  width: "100%",
                  display: "flex",
                }}
              >
                <Button
                  onClick={handleToggle(id)}
                  variant="outlined"
                  className="button-outlined-active"
                >
                  Toggle
                </Button>
                <Button
                 onClick={handleDelete(id)}
                  variant="outlined"
                  className="button-outlined-inactive"
                >
                  Delete
                </Button>
              </Box>
            </div>
          </div>
        ))}
      </div>
      <FormViewModal
        id={mouseEnterId}
        open={openFormView}
        handleOpen={handleOpenFormView}
        handleClose={handleCloseFormView}
      />
    </div>
  );
};

export default Form;
