import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import produce from "immer";
import { guidanceService } from "../../service/endpoints";

export const guidanceGetAll = createAsyncThunk(
  "guidance/getAll",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await guidanceService.get(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const guidanceGetById = createAsyncThunk(
  "guidance/getById",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await guidanceService.getById(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const guidanceUpdate = createAsyncThunk(
  "guidance/update",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await guidanceService.update(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const guidanceDelete = createAsyncThunk(
  "guidance/delete",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await guidanceService.delete(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);

const guidanceSlice = createSlice({
  name: "guidance",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    dataById: {},
    id: null,
    size: 10,
    page: 1,
    totalPage: 1,
    search: null,
    searchHistory: [],
  },
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    resetGetIdData: (state) => {
      state.dataById = {};
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(guidanceGetAll.pending, handlePending)
      .addCase(guidanceGetAll.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action?.payload?.data;
      })
      .addCase(guidanceGetAll.rejected, handleRejected);
    builder
      .addCase(guidanceGetById.pending, handlePending)
      .addCase(guidanceGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action?.payload?.data;
      })
      .addCase(guidanceGetById.rejected, handleRejected);
    builder
      .addCase(guidanceUpdate.pending, handlePending)
      .addCase(guidanceUpdate.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action?.payload?.data;
      })
      .addCase(guidanceUpdate.rejected, handleRejected);
    builder
      .addCase(guidanceDelete.pending, handlePending)
      .addCase(guidanceDelete.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(guidanceDelete.rejected, handleRejected);
  },
});

export default guidanceSlice.reducer;
export const { closeError, resetGetIdData } = guidanceSlice.actions;
