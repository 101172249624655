import * as React from "react";
import Modal from "@mui/material/Modal";
import { Button, Paper } from "@mui/material";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchForms } from "../Redux/Slices/getFormsSlice";

const schema = yup.object().shape({
  title: yup.string().min(4).max(200).required("these felied required"),
});

const SaveFormModal = ({ open, setOpen, handelSaveForm }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(schema) });

  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();

  const onSubmit = (value) => {
    handelSaveForm(value.title);
    reset();
    dispatch(fetchForms())
    setOpen(false);
    navigate('/forms')
  };

  return (
    <div className="p-2">
      <Modal
        open={open}
        className="cad-modal-blur p-2"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-discription"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            p: 2,
            pt: 3,
            backgroundColor: "#fff",
            // height: 300,
            borderRadius: 8,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} className="p-4">
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className="form-label cad-text-medium cad-fw-600"
              >
                Form name
              </label>
              <input
                {...register("title")}
                type="text"
                className="form-control cad-text-medium"
                id="exampleFormControlInput1"
                placeholder="Form name"
              />
              <label
                htmlFor="exampleFormControlTextarea1"
                className="form-label cad-text-small text-danger cad-fw-600"
              >
                {errors.title?.message}
              </label>
            </div>

            <div className="d-flex justify-content-center">
              <div className="col-3">
                <Button type="submit" className="button-contained">
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SaveFormModal;
