import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Card,
  CardContent,
  FormControl,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { faqSchema } from "../../utils/schema/faq.schema";
import DynamicCheckbox from "../CheckBox/DynamicCheckbox";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { faqGetById, faqUpdate } from "../../features/faq/faqSlice";
import { faqCategoryGetAll } from "../../features/faq/faqCategorySlice";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

const EditFaq = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "all", resolver: yupResolver(faqSchema) });

  const [checkBoxValue, setCheckBoxValue] = useState([]);
  let faqCategory = useSelector((state) => state?.faqCategory);
  let faq = useSelector((state) => state.faq);

  const onSubmit = async (value) => {
    await dispatch(faqUpdate(value));
    setValue("categories", []);
    setCheckBoxValue(
      faqCategory.data?.map((checkbox) => ({
        ...checkbox,
        isChecked: false,
      }))
    );
    reset();
    navigate("/faq");
    enqueueSnackbar(faq.isError ? faq.message : "FAQ Updated Successfully", {
      variant: faq.isError ? "error" : "success",
    });
    dispatch(faqCategoryGetAll());
  };


  useEffect(() => {
    dispatch(faqCategoryGetAll());
    dispatch(faqGetById(id));
  }, [dispatch, id]);

  useEffect(() => {
    setCheckBoxValue(
      faqCategory?.data?.map((checkbox) => ({
        ...checkbox,
        // isChecked: false,
        isChecked: faq?.dataById?.categories?.some(
          (obj) => obj.id === checkbox.id
        ),
      }))
    );

    const { question, answer, categories } = faq?.dataById;

    setValue("categories",categories?.map((item) => item.id));
    setValue("id", id);
    setValue("question", question);
    setValue("answer", answer);
  }, [faq?.dataById, faq?.dataById?.categories, faqCategory?.data, setValue, id]);

  const handleCheckboxChange = (newChecked) => {
    setValue("categories", newChecked);
    // // Do something with the updated values (newChecked)
    // console.log("Updated Checkbox Values:", newChecked);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box padding={3}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Paper>
              <React.Fragment>
                <Box p={2}>
                  <Box>
                    <Typography variant="h6">Add FAQ</Typography>
                  </Box>
                  <Box
                    sx={{
                      "& .MuiTextField-root": { m: 2 },
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        error={errors.question ? true : false}
                        label="Question"
                        helperText={errors.question?.message}
                        {...register("question")}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        size="small"
                        multiline
                        rows={4}
                        error={errors.answer ? true : false}
                        label="Answer"
                        //   defaultValue="Hello World"
                        helperText={errors.answer?.message}
                        {...register("answer")}
                      />
                    </FormControl>
                    <Box sx={{ mb: 3 }}>
                      <Stack spacing={2} direction="row">
                        <Button variant="text" onClick={() => navigate("/faq")}>
                          Back
                        </Button>
                        <Button type="submit" variant="contained">
                          Submit
                        </Button>
                      </Stack>
                    </Box>
                  </Box>
                </Box>
              </React.Fragment>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Card variant="outlined">
              <React.Fragment>
                <CardContent>
                  <DynamicCheckbox
                    checkboxes={checkBoxValue}
                    onCheckboxChange={handleCheckboxChange}
                    errors={errors.categories}
                  />
                </CardContent>
              </React.Fragment>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default EditFaq;
