import * as yup from "yup";
export const faqSchema = yup.object().shape({
  question: yup.string().min(4).max(200).required("Question is required"),
  answer: yup.string().min(4).max(500).required("Answer is required"),
  id: yup.string().uuid("Invalid UUID format").optional(),
  categories: yup
    .array()
    .of(yup.string().uuid("Invalid UUID format"))
    .default([])
    .min(1, "At least one category is required")
    .transform((value, originalValue) => {
      // Remove any null or undefined values from the array
      return originalValue.filter(
        (categoryId) => categoryId !== null && categoryId !== undefined
      );
    }),
});
