// UserSuspendModal.jsx
import React, { useEffect } from "react";
import { Box, Button, Modal, Paper } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { userThunkAPI } from "../../../features/users/user.slice";
import { subAdminThunkAPI } from "../../../features/subAdmin/subAdmin.slice";
import { subAdmin } from "../../../service/endpoints";
import { enqueueSnackbar } from "notistack";

const SubAdminSuspendModal = ({ handleClose, open, id }) => {
    const dispatch = useDispatch();

    const handleSoftDelete = (id) => {

        const deleteSubAdmin = async () => {
            try {
                const response = await subAdmin.delete(id);
                // console.log('SubAdmin deleted successfully:--------', response);
                if (response.status === 200) {
                    dispatch(subAdminThunkAPI.getAll());
                    handleClose()
                    enqueueSnackbar("Sub Admin deleted Successfully", {
                        variant: "success"
                    })
                }
            } catch (error) {
                console.error('Error deleting subAdmin:', error);
            }
        };

        // Call the delete function
        deleteSubAdmin();

    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "#fff",
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Paper elevation={2}>
                    <Box sx={style}>
                        <div className="modalhead d-flex justify-content-center align-items-center mb-2">
                            <IoCloseCircleOutline size={120} color="red" />
                        </div>
                        <div>
                            <h2 className="fs-4 mb-0 text-center mb-4">
                                Are you sure you want to suspend this account?
                            </h2>
                        </div>
                        <div className="row w-100">
                            <div className="col-6 m-auto d-flex">
                                <Button
                                    onClick={handleClose}
                                    className="button-outlined w-75"
                                    variant="outlined"
                                >
                                    No
                                </Button>
                            </div>

                            <div className="col-6 m-auto d-flex">
                                <Button
                                    onClick={() => handleSoftDelete(id)}
                                    className="w-100"
                                    variant="outlined"
                                    color="error"
                                >
                                    Yes
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Paper>
            </Modal>
        </div>
    );
};

export default SubAdminSuspendModal;
