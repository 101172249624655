import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { featureReq } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const featuresThunkAPI = {
    getAll: createAsyncThunk("features/getAll", async (searchTerm, { rejectWithValue, getState }) => {
        try {
            const { size, currentPage } = getState().featuresReq
            const params = getRequestParams(searchTerm, currentPage, size)
            const res = await featureReq.get(params);
            if (res.status === 200) {
                console.log(res?.data);
                return res?.data;
            }
        } catch (error) {
            return rejectWithValue(error)
            console.log(error);
        }
    }),
}

const featureSlice = createSlice({
    name: "features",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: [],
        userCount: null
    },
    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(featuresThunkAPI.getAll.pending, handlePending)
            .addCase(featuresThunkAPI.getAll.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data;
                state.rows = filterDataReqFeature(action?.payload?.data?.rows);
                state.totalItems = action?.payload?.data?.totalItems;
                state.totalPages = action?.payload?.data?.totalPages;
                state.currentPage = action?.payload?.data?.currentPage;
                state.isLoading = false;
            })
            .addCase(featuresThunkAPI.getAll.rejected, handleRejected);
    }

});

export const filterDataReqFeature = (data) => {
    return data && data?.map((item) => ({
        createdAt: item?.createdAt,
        id: item?.id,
        feature: item?.feature,
        description: item?.description,
        business: {
            business_name: item?.business[0]?.business_name,
            subscribed_user: item?.business[0]?.subscribed_user,
            business_type: item?.business[0]?.business_type,
            business_email: item?.business[0]?.business_email,
            business_logo: item?.business[0]?.business_logo,
        }
    })
    )
}
export default featureSlice.reducer;
export const { nextPage, previousPage } = featureSlice.actions;
