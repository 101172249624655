import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { BsArrowLeft } from 'react-icons/bs'
import 'react-quill/dist/quill.snow.css';
import PrimaryButton from '../../PrimaryButton'
import Quill from '../../Quill/Quill';  // Ensure Quill component is properly configured
import { sync } from 'framer-motion';
import { getGdpr, postGdpr } from '../../../service/cms/service.gdpr';

const EditDetails = () => {
  const [terms, setTearms] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getGdpr();
        setTearms(response.data.data);
        return response.data;
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  const { id } = useParams()


  const filteredData = terms.filter(item => item.id === id);

  const [editorHtml, setEditorHtml] = useState(null);


  const handleUpdate = async () => {
    const updatedContent = {
      content: editorHtml
    }

    const { status, data } = await postGdpr(updatedContent)

  }

  return (
    <div className='m-3 editdetails bg-white w-85 h-100 rounded-2 p-3'>
      <div className=' mb-4'>
        <Link to='/gdpr' className='text-black text-decoration-none'><BsArrowLeft fontSize="20px" style={{ marginRight: '10px' }} />Edit Details</Link>
      </div>
      <Quill editorHtml={filteredData[0]?.content} setEditorHtml={setEditorHtml} />
      <div className="d-flex mt-4 justify-content-center">
        <PrimaryButton
          onClick={handleUpdate}
          path="/gdpr"
          padding="0.4rem 3rem"
          title="Update"
        />
      </div>
    </div>
  )
}

export default EditDetails;
