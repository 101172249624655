import React from 'react'
import ReportCard from '../../Components/ReportCard/ReportCard';
import iconone from '../../Components/ReportCard/ImageIcon/iconone.png'
import icontwo from '../../Components/ReportCard/ImageIcon/icontwo.png'
import iconthree from '../../Components/ReportCard/ImageIcon/iconthree.png'
import iconfour from '../../Components/ReportCard/ImageIcon/iconfour.png'
import '../../Components/ReportCard/Report.css'


const Reports = () => {
  return (
    <div className="reports mt-1 mb-4  overflow-auto row p-0  fs-2 fw-bold text-white ">
      {/*<div className=' col-lg-4 col-12 p-2 text-decoration-none'>
           <ReportCard
            icon={iconone}
            title="user interactions on the guest web page"
            path="/GuestWebPage"
          />
        </div>
        <div className=' col-lg-4 col-12 p-2 text-decoration-none'>
          <ReportCard
            icon ={icontwo}
            title="user interactions on the application"
            path="/theaplication"
          /> 
        </div>
        <div className=' col-lg-4 col-12 p-2 text-decoration-none'> 
          <ReportCard
            icon={iconthree}
            title="Dashboard about metadata"
            path="/aboutmetadata"
          />
        </div> 
         <div className=' col-lg-4 col-12 p-2 text-decoration-none'>
          <ReportCard
            icon={iconone}
            title="dashboard about operating system"
            path="/opretingsystem"
          />
        </div> */}
      <div className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <ReportCard
          icon={iconfour}
          title="Subscription"
          path="/report-subscription"
        />
      </div>
      <div className=' col-lg-4 col-12 p-2 text-decoration-none'>
        <ReportCard
          icon={iconone}
          title="user interactions on the Task"
          path="/report-user"
        />
      </div>
    </div>
  )
}

export default Reports
