import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { userService } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const userArchiveThunkAPI = {
    getArchiveList: createAsyncThunk("user/getArchiveList", async (searchTerm, { getState }) => {
        try {
            const { size, currentPage } = getState().userArchive;
            const params = getRequestParams(searchTerm, currentPage, size);
            const res = await userService.archiveList(params);
            if (res.status === 200) return res.data;
        } catch (error) {
            console.log(error);
        }
    }),
}

const userArchiveSlice = createSlice({
    name: "userArchive",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: [],
        userCount: null
    },
    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        },
        refreshData: (state, action) => {
            state.rows = action?.payload?.data?.rows;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userArchiveThunkAPI.getArchiveList.pending, handlePending)
            .addCase(userArchiveThunkAPI.getArchiveList.fulfilled, (state, action) => {
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data;
                state.rows = action?.payload?.data?.rows;
                state.totalItems = action?.payload?.data?.totalItems;
                state.totalPages = action?.payload?.data?.totalPages;
                state.currentPage = action?.payload?.data?.currentPage;
                state.isLoading = false;
            })
            .addCase(userArchiveThunkAPI.getArchiveList.rejected, handleRejected);
    }

});

export default userArchiveSlice.reducer;
export const { nextPage, previousPage, refreshData } = userArchiveSlice.actions;
