import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { contactService } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const contactThunkAPI = {
    getAll: createAsyncThunk("user/getAll", async (searchTerm, { getState }) => {
        try {
            const { size, currentPage } = getState().contact
            const params = getRequestParams(searchTerm, currentPage, size)
            const res = await contactService.get(params);
            // console.log(res);
            if (res.status === 200) return res.data;
            // rejectWithValue(res.data);
        } catch (error) {
            console.log(error);
        }
    }
    ),

}

const contactSlice = createSlice({
    name: "contact",
    initialState: {
        isLoading: true,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: []
    },
    reducers: {
        nextPage: (state, action) => {
            state.currentPage = action.payload
        },
        previousPage: (state, action) => {
            state.currentPage = state.currentPage - 1
        },
        setSearchTerm: (state, action) => {
            state.search = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(contactThunkAPI.getAll.pending, handlePending)
            .addCase(contactThunkAPI.getAll.fulfilled, (state, action) => {
                // console.log(action?.payload?.data?.rows);
                handleFulfilled(state, action);
                state.search = null;
                state.data = action?.payload?.data?.data;
                state.rows = action?.payload?.data?.rows;
                state.totalItems = action.payload?.data?.totalItems;
                state.totalPages = action.payload?.data?.totalPages;
                state.currentPage = action.payload?.data?.currentPage;
                state.isLoading = false;
            })
            .addCase(contactThunkAPI.getAll.rejected, handleRejected);

        // builder
        //     .addCase(contactThunkAPI.getById.pending, handlePending)
        //     .addCase(contactThunkAPI.getById.fulfilled, (state, action) => {
        //         console.log("--------all data", action?.payload);
        //         handleFulfilled(state, action);
        //         state.dataById = action?.payload?.data
        //     })
        //     .addCase(contactThunkAPI.getById.rejected, handleRejected);
    }
});

export default contactSlice.reducer;
export const { nextPage, previousPage } = contactSlice.actions;
