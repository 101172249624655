import React, { useEffect, useState } from 'react'
import { Paper } from '@mui/material'
import { useSelector } from 'react-redux';
import DashCharts from '../../Components/DashboardCard/DashCharts';
import * as themeColors from "../../utils/Theme/Colors";
import { Box } from '@mui/system';
import { Line } from 'react-chartjs-2';
import { dashboardservice } from '../../service/endpoints';

const ReportUser = () => {

    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;
    // console.log(themeColorsObject.backgroundColor);
    const [data, setData] = useState({
        labels: Array,
        datasets: [
            {
                label: String,
                data: Array,
                fill: Boolean,
                borderColor: "#548CFF",
                backgroundColor: "#F3F7FF",
                tension: 1,
                borderWidth: "2",
            },
        ],
    });
    const [activeTab, setActiveTab] = useState('tab1');

    useEffect(() => {
        (async () => {
            try {
                const { data } = await dashboardservice.getTaskGraph()
                console.log(data.data);
                const labels = [];
                const counts = [];

                data?.data?.forEach(item => {
                    labels.push(item.date);
                    counts.push(parseInt(item.count));
                });

                setData({
                    labels: labels,
                    datasets: [
                        {
                            label: "Curved Line Graph",
                            data: counts,
                            fill: true,
                            borderColor: "#548CFF",
                            backgroundColor: "#F3F7FF",
                            tension: 1,
                            borderWidth: "2",
                        },
                    ],
                });
            } catch (error) {
                console.log(error);
            }
        })()
    }, [])

    // Define data and options for the different datasets
    const data1 = {
        labels: ['0', '5', '10', '15', '20', '25', '30', '35', '40'],
        datasets: [
            {
                label: 'Curved Line Graph',
                data: [0, 2, 3, 3, 4, 5, 5, 6, 6],
                fill: true,
                borderColor: '#548CFF',
                backgroundColor: '#F3F7FF',
                tension: 0.2,
                borderWidth: '2'
            },
        ],
    };

    const data2 = {
        labels: ['0', '5', '10', '15', '20', '25', '30', '35', '40'],
        datasets: [
            {
                label: 'Curved Line Graph',
                data: [0, 1, 3, 3, 4, 4, 5, 5, 6],
                fill: true,
                borderColor: '#548CFF',
                backgroundColor: '#F3F7FF',
                tension: 0.3,
            },
        ],
    };

    const data3 = {
        labels: ['0', '5', '10', '15', '20', '25', '30', '35', '40'],
        datasets: [
            {
                label: 'Curved Line Graph',
                data: [0, 1, 2, 2, 3, 3, 4, 5, 6],
                fill: true,
                borderColor: '#548CFF',
                tension: 0.3,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
                position: '',
            },
        },
    };

    // Function to handle tab change
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    return (
        <Paper
            style={{
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
            }}
            elevation={0}
            className=" mt-3 w-100 d-flex overflow-hidden flex-column justify-content-start align-items-start "
        >
            <Box
                className="  border-bottom rounded-top-3 p-3"
                sx={{
                    borderBottom: 0,
                    padding: 0,
                    // backgroundColor: "#fff",
                    width: "100%",
                }}
            >
                <div className='d-flex justify-content-between aligh-items-center'>
                    <h2 style={{ fontSize: '15px', fontWeight: '500' }}>Task Repots</h2>
                    {/* <div className="tab-buttons">
                        <button
                            onClick={() => handleTabChange('tab1')}
                            className={activeTab === 'tab1' ? 'active cad-fw-600' : 'cad-fw-600'}
                        >
                            1 Month
                        </button>
                        <button
                            onClick={() => handleTabChange('tab2')}
                            className={activeTab === 'tab2' ? 'active cad-fw-600' : ' cad-fw-600'}
                        >
                            6 Month
                        </button>
                        <button
                            onClick={() => handleTabChange('tab3')}
                            className={activeTab === 'tab3' ? 'active cad-fw-600' : 'cad-fw-600'}
                        >
                            1 Year
                        </button>
                    </div> */}
                </div>
                <div className="chart-container">
                    <Line data={data} options={options} />
                </div>
            </Box>
        </Paper>
    )
}

export default ReportUser