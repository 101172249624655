import { useSelector } from "react-redux";
import * as themeColors from "../../utils/Theme/Colors";
import { useState } from "react";
import FormViewModal from "../Form/FormViewModal";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const MyForms = () => {
  const [openFormView, setOpenFormView] = useState(false);
  const [mouseEnterId, setMouseEnterId] = useState("");
  const theme = useSelector((state) => state.theme.theme);
  const { formArray } = useSelector((state) => state?.getForms);
  const themeColorsObject =
    theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;

  const handleOpenFormView = (id) => {
    setMouseEnterId(id);
    setOpenFormView(true);
  };

  // console.log(formArray);

  return (
    <div
      style={{
        height: "87vh",
        backgroundColor: themeColorsObject.megaCardBackgroundColor,
        color: themeColorsObject.textColor
      }}
      className=" mt-1 position-relative cad-shadow overflow-auto rounded-3 mb-4 d-flex flex-wrap gap-3 p-4"
    >
      <div
        style={{
          height: "2rem",
          position: 'absolute',
          top: 14

        }}
        className="w-100 d-flex gap-3 align-items-center ">
        <Link to="/forms">
          <ArrowBackIcon className="fs-2 text-primary pointer" />
        </Link>
        <span className="text-primary cad-text-large fw-500">My forms</span>
      </div>
      {formArray?.map(({ id, title }) => (
        <div
          key={id}
          style={{
            height: "320px",
            width: "23%",
            marginTop: 28
          }}
          className=" create-form-card d-flex justify-content-between align-items-between flex-column p-3"
        >
          <div className="form-hover h-75 cad-shadow form-png rounded-3 w-100">
            {/* <iframe src={`/template-forms/${id}`} width="100%" height="100%" title={`iframe-${id}`}></iframe> */}

            <span
              onClick={() => handleOpenFormView(id)}
              className="icon-center rouded-3 d-flex gap-2 ps-3 pe-3 p-2 cad-fw-700 text-white"
            >
              <RemoveRedEyeIcon style={{ color: "#ffffff" }} />
              Preview
            </span>
          </div>

          <div className="d-flex flex-column gap-1">
            <span className="cad-text-medium cad-fw-600">{title}</span>
            <Link to={`/template-forms/${id}`}>
              <Button variant="outlined" className="button-outlined ">
                Use Template
              </Button>
            </Link>
          </div>
        </div>
      ))}

      <FormViewModal
        id={mouseEnterId}
        open={openFormView}
        handleOpen={handleOpenFormView}
        handleClose={() => setOpenFormView(false)}
      />
    </div>
  );
};

export default MyForms;
