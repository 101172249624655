import { createAsyncThunk, createSlice } from "@reduxjs/toolkit/dist";
import { termsService } from "../../service/endpoints";
import {
  handleFulfilled,
  handlePending,
  handleRejected,
} from "../../utils/handler/state.handler";
import produce from "immer";

export const termsGetAll = createAsyncThunk(
  "terms/getAll",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await termsService.get(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const termsGetById = createAsyncThunk(
  "terms/getById",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await termsService.getById(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const termsUpdate = createAsyncThunk(
  "terms/update",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await termsService.update(arge);
      if (result.status === 202) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const termsDelete = createAsyncThunk(
  "terms/Delete",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await termsService.delete(arge);
      if (result.status === 200) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);
export const termsAdd = createAsyncThunk(
  "terms/add",
  async (arge, { rejectWithValue }) => {
    try {
      const result = await termsService.post(arge);
      if (result.status === 201) return result.data;
      return rejectWithValue(result.data);
    } catch (error) {
      console.log(error)
    }
  }
);

const termsSlice = createSlice({
  name: "terms",
  initialState: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    message: null,
    data: [],
    dataById: {},
    id: null,
    size: 10,
    page: 1,
    totalPage: 1,
    search: null,
    searchHistory: [],
  },
  reducers: {
    closeError: (state) => {
      state.isError = false;
      state.message = null;
    },
    resetGetIdData: (state) => {
      state.dataById = {};
      state.id = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(termsGetAll.pending, handlePending)
      .addCase(termsGetAll.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(termsGetAll.rejected, handleRejected);
    builder
      .addCase(termsGetById.pending, handlePending)
      .addCase(termsGetById.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(termsGetById.rejected, handleRejected);
    builder
      .addCase(termsUpdate.pending, handlePending)
      .addCase(termsUpdate.fulfilled, (state, action) => {
        handleFulfilled(state, action);
        state.data = action.payload.data;
      })
      .addCase(termsUpdate.rejected, handleRejected);
    builder
      .addCase(termsDelete.pending, handlePending)
      .addCase(termsDelete.fulfilled, (state, action) => {
        handleFulfilled(state, action);
      })
      .addCase(termsDelete.rejected, handleRejected);
  },
});

export default termsSlice.reducer;
export const { closeError, resetGetIdData } = termsSlice.actions;
