import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { global } from "../../service/endpoints";
import {
    handleFulfilled,
    handlePending,
    handleRejected,
} from "../../utils/handler/state.handler";
import getRequestParams from "../../utils/helper/getRequestParams";

export const globalThunkAPI = {
    // getResource: createAsyncThunk("global/resource", async (_, { getState }) => {
    //     try {
    //         const { size, currentPage, search } = getState().user
    //         const params = getRequestParams(search, currentPage, size)
    //         const res = await global.getResource(params);
    //         // console.log(res);
    //         if (res.status === 200) return res.data;
    //         // rejectWithValue(res.data);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }),
    getResource: createAsyncThunk("global/resource", async () => {
        try {
            // const { size, currentPage, search } = getState().user
            // const params = getRequestParams(search, currentPage, size)
            const res = await global.getResource();
            // console.log(res);
            if (res.status === 200) return res.data;
            // rejectWithValue(res.data);
        } catch (error) {
            console.log(error);
        }
    }),

    getUsers: createAsyncThunk("global/users", async () => {
        try {
            const res = await global.getUsers();
            if (res.status === 200) return res.data;
            // rejectWithValue(res.data);
        } catch (error) {
            console.log(error);
        }
    }),

    getForms: createAsyncThunk("global/forms", async () => {
        try {
            const res = await global.getUsers();
            if (res.status === 200) return res.data;
            // rejectWithValue(res.data);
        } catch (error) {
            console.log(error);
        }
    }),

    getStatus: createAsyncThunk("global/status", async () => {
        try {
            const res = await global.getStatus();
            if (res.status === 200) return res.data;
            // rejectWithValue(res.data);
        } catch (error) {
            console.log(error);
        }
    })
}

const globalSlice = createSlice({
    name: "global",
    initialState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        message: null,
        data: [],
        rows: [],
        dataById: {},
        id: null,
        size: 10,
        page: 1,
        currentPage: 1,
        totalPage: 0,
        totalItems: 0,
        search: null,
        searchHistory: [],
        archive: [],
        globalSubAdmin: [],
        status: []
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(globalThunkAPI.getResource.pending, handlePending)
            .addCase(globalThunkAPI.getResource.fulfilled, (state, action) => {
                // console.log("---global resource", action?.payload?.data);
                handleFulfilled(state, action);
                state.data = action?.payload?.data;
            })
            .addCase(globalThunkAPI.getResource.rejected, handleRejected);

        builder
            .addCase(globalThunkAPI.getUsers.pending, handlePending)
            .addCase(globalThunkAPI.getUsers.fulfilled, (state, action) => {
                // console.log("---global------", action?.payload?.data);
                handleFulfilled(state, action);
                state.globalSubAdmin = action?.payload?.data;
            })
            .addCase(globalThunkAPI.getUsers.rejected, handleRejected);

        builder
            .addCase(globalThunkAPI.getStatus.pending, handlePending)
            .addCase(globalThunkAPI.getStatus.fulfilled, (state, action) => {
                // console.log("---global status------", action?.payload?.data);
                handleFulfilled(state, action);
                state.status = action?.payload?.data;
            })
            .addCase(globalThunkAPI.getStatus.rejected, handleRejected);

    }

});

export default globalSlice.reducer;
export const { } = globalSlice.actions;
