import React, { useEffect } from "react";
import { Grid, Paper } from "@mui/material";
import { Box } from "@mui/system";
import { LeftComponents } from "./components/LeftComponents";
import RightComponent from "./components/RightComponents";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { supportGetById } from "../../features/support/support.slice";
import SupportChat from "../../features/SupportChat/SupportChat";

const TicketDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { dataById } = useSelector((state) => state.support);
  useEffect(() => {
    dispatch(supportGetById(id));
  }, [dispatch, id]);

  return (
    // <Box m={3} height="90vh">
    //   <Paper sx={{ p: 2, height: "100%" }}>
    //     <Grid container height="100%">
    //       <Grid item xs={8}>
    //         <LeftComponents data={dataById} />
    //       </Grid>
    //       <Grid item xs={4}>
    //         <RightComponent data={dataById} />
    //       </Grid>
    //     </Grid>
    //   </Paper>
    // </Box>


    <SupportChat data={dataById} />
  );
};

export default TicketDetails;
