import { Button, Chip, Paper, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DashCharts from '../../Components/DashboardCard/DashCharts';
import * as themeColors from "../../utils/Theme/Colors";
import { Box } from '@mui/system';
import TableData from '../../Components/TableData';
import { payFilterThunkAPI } from '../../features/paymentFilter/payment_filter';
import UserName from '../Users/UserName';
import { formatDate } from '../Dashboard/Dashboard';
import axios from 'axios';
import { nextPage, userThunkAPI } from '../../features/users/user.slice';

const ReportSubs = () => {
    const dispatch = useDispatch()
    const [mouseEntered, setMouseEntered] = useState(false);
    const [mouseEnteredId, setMouseEnteredId] = useState("");
    const [resdata,setResponseData]=useState([])
  
    const { totalPages, rows, currentPage, isLoading } = useSelector(
      (state) => state?.paymentFilter
    );
  

    // const fetchData = async () => {
    //     try {

    //         let user
    //         // setIsLoading(true);
    //         const token = localStorage.getItem('user'); 
    //         if (token) {
    //              user = JSON.parse(token); // Parse the token string to an object if it's JSON
    //             console.log(user.accessToken); // Access the accessToken property of the user object
    //           } else {
    //             console.log('Token not found in localStorage');
    //           }
              
    //         // token.Stringify();
    //         // console.log(token?.accessToken)// Replace with your token retrieval logic
    //         const headers = {
    //             'x_auth_token': user.accessToken
    //           };
    //          const params = {
    //             startDate,
    //             endDate,
    //         };
    //         const res = await axios.get('http://localhost:5500/api/local/v1/billing/admin/checkout/filter', {
    //             headers,
    //             params,
    //         });
    //         if (res.status === 200) {
    //             const data = res.data;
    //             console.log(data.data.rows)
    //             setResponseData(data.data.rows)
    //             // const formattedData = formattedData(data);
    //             // setExtractedDataArray(formattedData);
    //         }
    //     } catch (error) {
    //         console.error('Error fetching data:', error);
    //     } 
    // };

    const handlePagination = (value) => {
      // console.log(value);
      dispatch(nextPage(value));
      dispatch(payFilterThunkAPI.getAll());
    };
  

    useEffect(() => {
      dispatch(nextPage(1));
      // dispatch(userThunkAPI.getAll(searchTerm)); // Pass search term to getAll thunk
    }, [dispatch, ]);
  
    // trigger modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
  
    const openModal = (userId) => () => {
      setIsModalOpen(true);
      setSelectedUserId(userId);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
      setSelectedUserId(null);
    };
  
const [startDate, setStartDate] = useState("2024-06-01");
const [endDate, setEndDate] = useState("2024-06-30");

    const extractedDataArray = rows?.map((row, index) => {
      const extractedData = {   
        id: row?.id,
        name: row?.principal[0]?.first_name, // Corrected access to stripe_price_id
        sr_no: (resdata.currentPage - 1) * 10 + index + 1, // Corrected access to currentPage from resdata
        package: row?.checkout_plans?.package?.title, // Assuming package name is under title
        amount: row?.stripe_checkout_object?.amount_total, // Adjusted to access amount_total correctly
        recurring: row?.checkout_plans?.recurring === "1" ? "Monthly" : "Yearly",
        status: row?.payment_status === "paid" ? (
          <Chip label="Paid" color="success" variant="outlined" size="small" />
        ) : (
          <Chip label="Unpaid" color="error" variant="outlined" size="small" />
        ),
        createdAt: formatDate(row?.createdAt),
      };
      return extractedData;
          });
    
    
  useEffect(() => {
    // dispatch(payFilterThunkAPI.getAll(startDate, endDate ));
    console.log("entered");
  }, []);
  
    // console.log(rows);
  
    const cellConfig = [
      // { dataKey: "sr_no", align: "left", width: 10 },
      { dataKey: "name", align: "left", width: 20 },
      { dataKey: "package", align: "left", width: 18 },
      { dataKey: "recurring", align: "left", width: 16 },
      { dataKey: "amount", align: "left", width: 16 },
      { dataKey: "status", align: "left", width: 16 },
      { dataKey: "createdAt", align: "left", width: 16 },
    ];
  
    const handleSearch = () => {
      console.log(startDate,endDate)
      dispatch(payFilterThunkAPI.getAll({ startDate, endDate }));
    };

  
    const tableHeadRowCell = [
        // {
        //   title: "SR NO.",
        //   width: "16%",
        //   align: "left",
        // },
        {
          title: "Name",
          width: "20%",
          align: "left",
        },
        {
          title: "Package",
          width: "18%",
          align: "left",
        },
        {
          title: "Recurring",
          width: "16%",
          align: "left",
        },
        {
          title: "Amount",
          width: "16%",
          align: "left",
        },
        {
          title: "Status",
          width: "10%",
          align: "left",
        },
        {
          title: "Created At",
          width: "20%",
          align: "left",
        },
      ];
    const theme = useSelector((state) => state.theme.theme);
    const themeColorsObject = theme === "light" ? themeColors.lightTheme : themeColors.darkTheme;
    // console.log(themeColorsObject.backgroundColor);

    return (
<>
<Paper
            style={{
                backgroundColor: themeColorsObject.megaCardBackgroundColor,
                color: themeColorsObject.textColor,
            }}
            elevation={0}
            className=" mt-3  w-100 d-flex overflow-hidden flex-column justify-content-start align-items-start "
        >
            <Box
                className="  border-bottom rounded-top-3 p-3"
                sx={{
                    borderBottom: 0,
                    padding: 0,
                    // backgroundColor: "#fff",
                    width: "100%",
                }}
            >
                <DashCharts
                    themeColorsObject={themeColorsObject}
                    title="subscription"
                />
            </Box>
 <Box display={'flex'} justifyContent={"center"} alignItems={"center"}>
      <Typography variant="h6" className="m-2">
        Start Date
      </Typography>
      <TextField
      type="date"
      name="start"
      size="small"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      className="m-2 p-1"
      InputLabelProps={{
        shrink: true,
      }}
    />
      <Typography variant="h6" className="m-2">
        End Date
      </Typography>
      <TextField
        type="date"
        name="end"
        size="small"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        className="m-2"
        InputLabelProps={{
          shrink: true,
        }}
      />
        <Button variant='contained' onClick={handleSearch}>Search</Button> {/* onClick handler for Search button */}

    </Box>

<TableData
tableHeadRowCell={tableHeadRowCell}
extractedDataArray={extractedDataArray}
cellConfig={cellConfig}
setMouseEnteredId={setMouseEnteredId}
setMouseEntered={setMouseEntered}
totalPages={totalPages}
handlePagination={handlePagination}
currentPage={currentPage}
isLoading={isLoading}
handleViewOpen={openModal}
/>

        </Paper>

</>
    )
}

export default ReportSubs