import React from "react";
import "./Loader.css"

const Loader = () => {
  return (
    <div className="iconn">
      
    <div className="bar bg-primary" style={{
      marginLeft:"-60px",
    }}></div>
    <div className="bar bg-primary" style={{
      marginLeft:"-20px",
    }}></div>
    <div className="bar bg-primary" style={{
      marginLeft:"20px",
    }}></div>
    <div className="bar bg-primary" style={{
      marginLeft:"60px",
    }}></div>
  </div>  
  );
};

export default Loader;
