export const handlePending = (state) => {
  state.isLoading = true;
};

export const handleFulfilled = (state, { payload }) => {
  state.isLoading = false;
  state.isError = false;
  state.isSuccess = true;
  state.message = payload?.message;
};

export const handleRejected = (state, action) => {
  console.log(action);
  state.isLoading = false;
  state.isError = true;
  state.isSuccess = false;
  state.message = action.payload?.data?.error?.message || action.payload?.data?.message || action.error?.messag;
};
