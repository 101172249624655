import { useEffect, useState } from "react";
import AdvanceFilter from "../../Components/AdvanceFilter/AdvanceFilter";
import Refresher from "../../Components/Refresher";
import SearchInput from "../../Components/SearchInput/SearchInput";
import TableData from "../../Components/TableData";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import UserName from "./UserName";
import { Chip } from "@mui/material";
import { formatDate } from "../Dashboard/Dashboard";
import { userClosedAccountThunkAPI } from "../../features/users/user.closedAccount.slice";
import { nextPage } from "../../features/users/user.slice";

const ClosedAccounts = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(userClosedAccountThunkAPI.getAll());
  }, [dispatch]);

  useEffect(() => {
    dispatch(nextPage(1));
    dispatch(userClosedAccountThunkAPI.getAll(searchTerm)); // Pass search term to getAll thunk
  }, [dispatch, searchTerm]);

  const navigate = useNavigate();

  const { totalPages, rows, currentPage, isLoading } = useSelector(
    (state) => state?.userClosedAccount
  );
  const [mouseEntered, setMouseEntered] = useState(false);
  const [mouseEnteredId, setMouseEnteredId] = useState("");
  const [filteredCellConfig, setFilteredCellConfigg] = useState([]);
  const [filteredTableHeadRowCell, setFilteredTableHeadRowCelll] = useState([]);

  const [checkboxValues, setCheckboxValues] = useState({
    // "SR NO": true,
    sub_type: true,
    name: true,
    "Email address": true,
    "Reason": true,
    "Improvement": false,
    "Created At": true,
  });
  const cellConfig = [
    // { dataKey: "sr_no", align: "left", width: 10 },
    // { dataKey: "sub_type", align: "left", width: 10 },
    { dataKey: "name", align: "left", width: 20 },
    { dataKey: "email_address", align: "left", width: 20 },
    { dataKey: "reason", align: "left", width: 20 },
    { dataKey: "improvement", align: "left", width: 20 },
    { dataKey: "createdAt", align: "left", width: 20 },
  ];
  const tableHeadRowCell = [
    // {
    //     title: "SR NO.",
    //     width: "10%",
    //     align: "left",
    // },
    // {
    //   title: "Subscription",
    //   width: "10%",
    //   align: "left",
    // },
    {
      title: "User",
      width: "20%",
      align: "left",
    },
    {
      title: "Email address",
      width: "20%",
      align: "left",
    },
    {
      title: "Reason",
      width: "20%",
      align: "left",
    },
    {
      title: "Improvement",
      width: "25%",
      align: "left",
    },
    {
      title: "Delete At",
      width: "20%",
      align: "left",
    },
  ];
  const extractedDataArray = rows?.map((row, index) => {
    const extractedData = {
      id: row?.id,
      // sr_no: (currentPage - 1) * 10 + index + 1,
      name: <UserName row={row} />,
      sub_type:
        row?.business &&
        row?.business[0]?.subscriptions &&
        row?.business[0]?.subscriptions[0]?.status ? (
          <Chip
            label={
              row?.business[0]?.subscriptions[0]?.status === "paid"
                ? "subscriber"
                : row?.business[0]?.subscriptions[0]?.status
            }
            color={
              row?.business[0]?.subscriptions[0]?.status === "paid"
                ? "success"
                : "error"
            }
            variant="outlined"
            size="small"
          />
        ) : (
          <Chip
            label={"free"}
            color="warning"
            variant="outlined"
            size="small"
          />
        ),
      email_address: row?.email,
      //   verfied_user: row?.verfied_user ? (
      //     <Chip
      //       label={"Verified"}
      //       color="success"
      //       variant="outlined"
      //       size="small"
      //     />
      //   ) : (
      //     <Chip
      //       label={"Not-Verified"}
      //       color="error"
      //       variant="outlined"
      //       size="small"
      //     />
      //   ),
      reason: row?.reason, // need values from backend
      improvement: row?.improvement, // need values from backend
      createdAt: formatDate(row?.createdAt),
    };
    return extractedData;
  });

  const handlePagination = (value) => {
    dispatch(nextPage(value));
    dispatch(userClosedAccountThunkAPI.getAll());
  };

  const handleViewOpen = (id) => () => {
    // navigate(`/user-details/${id}`);
  };
  return (
    <div className="ps-3 pe-3">
      <div className="row">
        <div className="col-3 d-flex align-items-center gap-1 mt-3">
          <SearchInput
            searchValue={searchTerm}
            searchFucntion={(e) => setSearchTerm(e.target.value)}
          />
          <Refresher
            isLoading={isLoading}
            handleRefresh={() => dispatch(userClosedAccountThunkAPI.getAll())}
          />
        </div>
        <div className="col-9 d-flex align-items-end justify-content-end mt-3">
          <AdvanceFilter
            bottom="-169px"
            left="-73px"
            cellConfig={cellConfig}
            tableHeadRowCell={tableHeadRowCell}
            setFilteredCellConfig={setFilteredCellConfigg}
            setFilteredTableHeadRowCell={setFilteredTableHeadRowCelll}
            checkboxValues={checkboxValues}
            setCheckboxValues={setCheckboxValues}
          />
        </div>
      </div>

      <TableData
        tableHeadRowCell={filteredTableHeadRowCell}
        extractedDataArray={extractedDataArray}
        cellConfig={filteredCellConfig}
        setMouseEnteredId={setMouseEnteredId}
        setMouseEntered={setMouseEntered}
        totalPages={totalPages}
        handlePagination={handlePagination}
        currentPage={currentPage}
        isLoading={isLoading}
        handleViewOpen={handleViewOpen}
      />
    </div>
  );
};

export default ClosedAccounts;
