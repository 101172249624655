import React from 'react'
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart } from "chart.js/auto";
import { Paper } from '@mui/material'
import { Box } from '@mui/system'

Chart.register(CategoryScale);

const data = {
    labels: ['0', 'Aug 10', 'Sept 10', 'Oct 10', 'Nov 10', 'Dec 10', 'Jan 10'],
    datasets: [
        {
            label: 'Curved Line Graph',
            data: [0, 10, 7, 13, 10, 18, 12],
            fill: true,
            borderColor: '#548CFF',
            backgroundColor: '#F3F7FF',
            tension: 0.3,
        },
    ],
};


const options = {
    scales: {
        x: {
            type: 'category',
        },
    },
    plugins: {
        legend: {
            display: false,
            position: '',
        },
    },
};

const LineCharts = ({title}) => {
  return (
    <Paper
    className='p-2'
    elevation={2}>
        <Box className="border-none rounded-top-3 " sx={{ border: 0, padding: 0, backgroundColor: "#fff", width: "100%" }}>
            <div style={{ height: 'auto', width: '100%' }}>
            <h2 className='mb-3' style={{fontSize:'15px',fontWeight:'500'}}>{title}</h2>
            <   Line className='w-100' data={data} options={options} />
            </div>
        </Box>
    </Paper>
  )
}

export default LineCharts
