import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PrimaryButton from '../../PrimaryButton';
import { Link } from 'react-router-dom';
import { BsArrowLeft } from 'react-icons/bs';
import AddIcon from '@mui/icons-material/Add';
import { postGdpr } from '../../../service/cms/service.gdpr';

const Quill = () => {
  const [theme, setTheme] = useState('snow');
  const [ editorHtml , setEditorHtml] = useState("")



  const handleUpdate = async () => {
    // console.log(editorHtml);

    const content = {
      content : editorHtml
    }

    const { status, data } = await postGdpr(content)
    // console.log(status, data)
  };

  const handleChange = (html) => {
    setEditorHtml(html);
  };



  

  return (
    <div className=' m-3 editdetails bg-white w-85 h-100 rounded-2 p-3'>
      <div className=' mb-4'>
        <Link to='/gdpr' className='text-black text-decoration-none'><BsArrowLeft fontSize="20px" style={{ marginRight: '10px' }} />Add Details</Link>
      </div>
      <ReactQuill
        theme={theme}
        onChange={handleChange}
        value={editorHtml}
        modules={Quill.modules}
        formats={Quill.formats}
        bounds={'.app'}
        // placeholder={props.placeholder}
      />
      <div className="d-flex mt-4 justify-content-center">
        <PrimaryButton
          onClick={handleUpdate}
          padding="0.4rem 3rem"
          title="Save"
          path="/gdpr"
        />
      </div>
    </div>
  );
};

Quill.modules = {
  toolbar: [
    [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
    ['link', 'image', 'video'],
    ['clean']
  ],
  clipboard: {
    matchVisual: false,
  }
};

Quill.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

Quill.propTypes = {
  placeholder: PropTypes.string,
};

export default Quill;
