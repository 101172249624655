import React, { useEffect, useState } from "react";
import "./Chats.css";
import { Avatar, Badge, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NewChatModal from "./NewChatModal";
import NewGroupChatModal from "./NewGroupChatModal";
import SearchInput from "../SearchInput/SearchInput";
import { useDispatch, useSelector } from "react-redux";
import SpeakerNotesOutlinedIcon from "@mui/icons-material/SpeakerNotesOutlined"
import { setChat } from "../../features/chat/chats.slice";
import { Socket } from "socket.io-client";

export const convertTimestampToTime = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const meridiem = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12;

  // Add leading zero to minutes if less than 10
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Formatted time string
  const formattedTime = `${hours}:${formattedMinutes} ${meridiem}`;

  return formattedTime;
};
const domain = process.env.REACT_APP_BACKEND_BASEURL
const filterById = (chats, id) => {
  return chats.find(chat => chat.chatId === id);
};
const ChatsLeft = () => {
  const dispatch = useDispatch()
  const chat = useSelector((state) => state?.chat);
  const [openNewGroupChat, setOpenNewGroupChat] = useState(false);
  const [openNewChat, setOpenNewChat] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isTyping, setIsTyping] = useState({
    id: null,
    typing: false,
  })

  const handleUserClick = (id) => () => {
    if (chat?.socket instanceof Socket) {
      chat?.socket.emit("joinChat", id)
    }
    dispatch(setChat(filterById(chat?.chats, id)))
  };

  useEffect(() => {
    if (chat?.socket instanceof Socket) {
      // Listen for typing events from other users
      chat?.socket.on("typing", (data) => {
        setIsTyping({ id: data, typing: true });
        setTimeout(() => {
          setIsTyping({ id: null, typing: false });
        }, 3000); // Adjust the duration as needed
      });
    }
  }, [chat?.socket])


  return (
    <>
      <div className="h-100  overflow-hidden pb-5">
        <div className="w-100 p-3 d-flex justify-content-between text-small">
          <Button
            onClick={() => setOpenNewChat(true)}
            variant="contained"
            style={{
              width: "48%",
              backgroundColor: "#548CFF",
              textTransform: "none",
            }}
          >
            <AddIcon /> Chat
          </Button>
          <Button
            onClick={() => setOpenNewGroupChat(true)}
            variant="contained"
            style={{
              width: "48%",
              backgroundColor: "#548CFF",
              textTransform: "none",
            }}
          >
            <AddIcon /> Group Chat
          </Button>
        </div>

        <div className="p-2">
          <SearchInput searchFucntion={(e) => setSearchTerm(e.target.value)} searchValue={searchTerm} />
        </div>

        <div className="mess pointer p-3 pb-5 h-75 overflow-auto">
          {chat.chats.length === 0 ? (
            <div className="h-50 w-100 d-flex gap-2 flex-column justify-content-center align-items-center">
              <SpeakerNotesOutlinedIcon className="fs-1 text-primary" />
              <span className="text-primary cad-fw-500">You have no chats</span>
            </div>
          ) : (
            chat.chats?.map(({ name, profile, chatId, last_message }, i) => (
              <div
                key={i}
                onClick={handleUserClick(chatId)}
                className={
                  chat.activeChat && chat.activeChat?.chatId === chatId
                    ? "chat-active message position-relative mb-3 mt-1 pt-3 pb-3 gap-2 p-2 rounded-2 d-flex  align-items-center"
                    : "message position-relative mb-3 mt-1 gap-2 pt-3 pb-3 p-2 rounded-2 d-flex  align-items-center"
                }
              >
                <div className="contact-profile">
                  <Avatar alt={name} src={`${domain}/${profile}`} />
                </div>

                <div className="d-flex flex-column pe-2">
                  <span style={{ fontSize: "14px", fontWeight: "500" }}>
                    {name}
                  </span>
                  <span
                    className="text-nowrap"
                    style={{
                      fontSize: "12px",
                      width: "240px",
                      overflow: "hidden",
                    }}
                  >
                    {/* {last_message[0]?.content} */}
                    {isTyping.id && isTyping.id === chatId && isTyping.typing
                      ? <Typography variant="caption" fontStyle={'italic'} display="block" gutterBottom>typing...</Typography>
                      : (Array.isArray(last_message) && last_message[0]?.content
                        ? <Typography variant="caption">{last_message[0]?.content}</Typography>
                        : <Typography variant="caption">{Array.isArray(last_message[0]?.attachments) && last_message[0]?.attachments[0]?.filename}</Typography>)
                    }
                  </span>
                </div>
                <div className="d-flex pe-1 h-100 position-absolute end-0 flex-column justify-content-around align-items-end">
                  <p
                    className="m-1"
                    style={{
                      fontSize: "10px",
                      fontWeight: "500",
                      position: "relative",
                    }}
                  >
                    {last_message && last_message[0] && convertTimestampToTime(last_message[0]?.createdAt)}
                  </p>

                  {/* <span
                    className="m-2"
                    style={{
                      position: "relative",
                      fontSize: "8px",
                      background: "#548CFF",
                      width: "18px",
                      height: "18px",
                      padding: "3px",
                      borderRadius: "10px",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    1
                  </span> */}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <NewChatModal
        open={openNewChat}
        onClose={() => setOpenNewChat(false)}
      />

      <NewGroupChatModal
        open={openNewGroupChat}
        onClose={() => setOpenNewGroupChat(false)}
      />
    </>
  );
};

export default ChatsLeft;
